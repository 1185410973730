import React from 'react'

const Testimony = () => {
    return (
        <div className="terms-of-use" style={{ backgroundColor: "white" }}>
            <div style={{ maxWidth: "900px", marginLeft: "auto", marginRight: "auto", paddingTop: "50px", paddingBottom: "50px", textAlign: 'justify' }} className="about-us-mobile-responsive">
                <h1 style={{ fontSize: "16px", fontWeight: "bold" }}>Testimony</h1>
                <br/>
                <div>
                    <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    My lord, your consulting firm Blackcoffer did an awesome job on this academic research project for me. You will be getting more academic research assignments from me and likely consulting research from my consulting firm. I cannot thank you enough.
                    </p>
                    <div style={{float:'right'}}>
                        <p style={{ fontSize: '12px' }}>
                            <i>
                            Professor of Finance and Economics
                            </i>
                        </p>
                        <p style={{textAlign:'right', fontSize: '12px'}}>
                            <i>
                            University of Texas
                            </i>
                        </p>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
                <div>
                    <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    Be sure this project was very positive for Blackcoffer and I (or maybe others in our university) really hope to contact you in the future for another project.
                    </p>
                    <div style={{float:'right'}}>
                        <p style={{ fontSize: '12px' }}>
                            <i>
                            Professor of Finance and Accounting
                            </i>
                        </p>
                        <p style={{textAlign:'right', fontSize: '12px'}}>
                            <i>
                            London Business School
                            </i>
                        </p>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
                <div>
                    <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    Thanks for the hard work you guys did for me. 98% accuracy is pretty good, especially now that the data has been validated by my colleague. That made me smile. Look forward to working with Blackcoffer team on many more exciting projects to come. Truly is exciting news!
                    </p>
                    <div style={{float:'right'}}>
                        <p style={{ fontSize: '12px' }}>
                            <i>
                            Faculty of Accounting
                            </i>
                        </p>
                        <p style={{textAlign:'right', fontSize: '12px'}}>
                            <i>
                            University of Arkansas
                            </i>
                        </p>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
                <div>
                    <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    Data looks outstanding. Thanks for making my day. You helped me to accelerate my research project especially when I was facing challenges to collect, process, mine and prepare an analytics ready data. It is a ton help for me and my colleague. Blackcoffer team is awesome.
                    </p>
                    <div style={{float:'right'}}>
                        <p style={{ fontSize: '12px' }}>
                            <i>
                            Professor of Accounting
                            </i>
                        </p>
                        <p style={{textAlign:'right', fontSize: '12px'}}>
                            <i>
                            Cornell University
                            </i>
                        </p>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
                <div>
                    <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    Thanks for your hard work, appreciated. It was a sincere pleasure to collaborate with you and your team, and as I expressed earlier I was incredibly surprised by how you mastered a difficult and not common topic. I will surely re-contact you if in need. Have a great continuation.
                    </p>
                    <div style={{float:'right'}}>
                        <p style={{ fontSize: '12px' }}>
                            <i>
                            Business Owner
                            </i>
                        </p>
                        <p style={{textAlign:'right', fontSize: '12px'}}>
                            <i>
                            Geneva, Switzerland
                            </i>
                        </p>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
                <div>
                    <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    Blackcoffer has provided excellent big data management expertise as a partner in groundbreaking research and development.  I look forward to continuing our work with great results and innovation.
                    </p>
                    <div style={{float:'right'}}>
                        <p style={{ fontSize: '12px' }}>
                            <i>
                            Political Leader & Business Owner
                            </i>
                        </p>
                        <p style={{textAlign:'right', fontSize: '12px'}}>
                            <i>
                            One of a leading think tank in the U.S.A.
                            </i>
                        </p>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
            </div>
        </div>
    )
}

export default Testimony