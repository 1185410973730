import React, { useEffect, useRef, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import "./Filter.css";
import axios from "axios";

const Dropdown = ({ multiple, placeholder, category, actual, setActual, options,setIsSearchCalled,setIsData }) => {
  const dropdownRef = useRef();
  const [dropdown, setDropdown] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const handleChange = (data) => {
    var new_list = actual[category];
    if (new_list.includes(data)) {
      new_list = new_list.filter((i) => i !== data);
    } else {
      new_list = multiple ? [...new_list, data] : [data];
    }

    setActual((prev) => ({ ...prev, [category]: new_list }));
    setSearch("");
    document.getElementById(`${category}123`).focus();

    setIsSearchCalled(false);
    setIsData(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdown(false);
      setSearch("");
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const parent = document.getElementById(category);
    const child = document.getElementById(`${category + "child"}`);
    if (parent && child) {
      child.style.top = `${parent.clientHeight + 4}px`;
    }
  }, [actual]);
  useEffect(() => {
    const fetchMatchingCities = async () => {
      if (search.trim().length >= 2) {
        try {
          const response = await axios.get(`/api/data/get-cities-data`, {
            params: { keyword: search },
          });
          if (Array.isArray(response.data)) {
            setFilteredOptions(response.data);
          } else {
            console.error('Unexpected response format:', response.data);
          }
        } catch (error) {
          console.error('Error fetching cities:', error);
        }
      } else {
        setFilteredOptions([]);
      }
    };

    fetchMatchingCities();
  }, [search]);
  return (
    <div ref={dropdownRef} className="d-flex gap-2 flex-column" style={{ position: "relative" }}>
      
      <div id={category} className="parent-dropdown2" onClick={() => setDropdown(true)}>
        {/* <div className="d-flex w-100 flex-wrap gap-2" style={{ padding: `${actual[category].length ? "4px" : "0px"}` }}> */}
        <div className="d-flex w-100 flex-wrap gap-2" style={{ paddingTop: `${actual[category].length ? "4px" : "0px"}`,paddingBottom: `${actual[category].length ? "4px" : "0px"}`,paddingLeft: `${actual[category].length ? "4px" : "0px"}` }}>

          {actual[category]?.map((items) => (
            <span onClick={() => handleChange(items)} className="dates my-auto" >
              {items} <RxCross2 className="my-auto"/>
            </span>
          ))}

          <input
            id={category + "123"}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              setDropdown(true);
            }}
            type="text"
            // style={{ fontSize: "14px", maxWidth: "150px", border: "none", caretColor: "#00a5ec" }}
            style={{ fontSize: "14px", maxWidth: category === "maxduration" ? "136px":"150px", border: "none", caretColor: "#00a5ec" }}
            placeholder={!actual[category]?.length ? placeholder : ""}
          />
        </div>
      </div>

      {category === "location" && (

        <div
          id={category + "child"}
          className="dropdown"
          style={{ display: `${dropdown && actual[category].length !== filteredOptions?.length ? "flex" : "none"}` }}
        >
          {/* {options
    .filter((i) => !actual[category]?.includes(i) && i?.toLowerCase()?.includes(search?.toLowerCase()))
    .map((option, index) => (
      <div onClick={() => handleChange(option)} key={index} style={{ cursor: "pointer" }}>
        <span>{option}</span>
      </div>
    ))} */}
          {filteredOptions
            ?.filter((i) => !actual[category]?.includes(i) && i?.toLowerCase()?.includes(search?.toLowerCase()))
            .map((option, index) => (
              <div onClick={() => handleChange(option)} key={index} style={{ cursor: "pointer" }}>
                <span>{option}</span>
              </div>
            ))}
        </div>
      )}

      {
        category === "maxduration" && (

          <div
            id={category + "child"}
            className="dropdown"
            style={{ display: `${dropdown && actual[category].length !== options?.length ? "flex" : "none"}` }}
          >
            {options
              .filter((i) => !actual[category]?.includes(i) && i?.toLowerCase()?.includes(search?.toLowerCase()))
              .map((option, index) => (
                <div onClick={() => handleChange(option)} key={index} style={{ cursor: "pointer" }}>
                  <span>{option}</span>
                </div>
              ))}
          </div>
        )
      }
      {
        category === "experience" && (

          <div
            id={category + "child"}
            className="dropdown"
            style={{ display: `${dropdown && actual[category].length !== options?.length ? "flex" : "none"}` }}
          >
            {options
              .filter((i) => !actual[category]?.includes(i) && i?.toLowerCase()?.includes(search?.toLowerCase()))
              .map((option, index) => (
                <div onClick={() => handleChange(option)} key={index} style={{ cursor: "pointer" }}>
                  <span>{option}</span>
                </div>
              ))}
          </div>
        )
      }
      {
        category === "freelancer" && (

          <div
            id={category + "child"}
            className="dropdown"
            style={{ display: `${dropdown && actual[category].length !== options?.length ? "flex" : "none"}` }}
          >
            {options
              .filter((i) => !actual[category]?.includes(i) && i?.toLowerCase()?.includes(search?.toLowerCase()))
              .map((option, index) => (
                <div onClick={() => handleChange(option)} key={index} style={{ cursor: "pointer" }}>
                  <span>{option}</span>
                </div>
              ))}
          </div>
        )
      }
      {
        category === "duration" && (

          <div
            id={category + "child"}
            className="dropdown"
            style={{ display: `${dropdown && actual[category].length !== options?.length ? "flex" : "none"}` }}
          >
            {options
              .filter((i) => !actual[category]?.includes(i) && i?.toLowerCase()?.includes(search?.toLowerCase()))
              .map((option, index) => (
                <div onClick={() => handleChange(option)} key={index} style={{ cursor: "pointer" }}>
                  <span>{option}</span>
                </div>
              ))}
          </div>
        )
      }
      {
        category === "durationType" && (

          <div
            id={category + "child"}
            className="dropdown"
            style={{ display: `${dropdown && actual[category].length !== options?.length ? "flex" : "none"}` }}
          >
            {options
              .filter((i) => !actual[category]?.includes(i) && i?.toLowerCase()?.includes(search?.toLowerCase()))
              .map((option, index) => (
                <div onClick={() => handleChange(option)} key={index} style={{ cursor: "pointer" }}>
                  <span>{option}</span>
                </div>
              ))}
          </div>
        )
      }
    </div>
  );
};

export default Dropdown;
