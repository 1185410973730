import React,{useEffect} from "react";
import "./terms.css";
import { GoDotFill } from "react-icons/go";


function TermsOfUse() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="terms-of-use" style={{backgroundColor: "white"}}>
        <div style={{maxWidth: "900px", marginLeft: "auto", marginRight: "auto", paddingTop: "50px", paddingBottom: "50px" ,textAlign:'justify'}} className="about-us-mobile-responsive">
      <h1  style={{fontSize: "32px"}}>Terms of Use</h1>
      <p style={{fontSize:"14px"}}>May 30, 2024</p>

      <section>
        <h3 style={{marginTop: "50px", marginBottom: "30px", fontSize: "22px"}}>1. INTRODUCTION</h3>
        <p style={{fontSize: '14px', lineHeight: "25px"}}>
        The Workcroft Site Terms of Use describe when and the conditions under which you are allowed to access or use the site. Please read these Site Terms of Use carefully before visiting our Site. If you do not agree to these Site Terms of Use, you may not visit, use, or access the Site or Site Services as a Site Visitor, User or otherwise and may not click to accept the Terms of Service when prompted on the Site.
        </p>
      </section>

      <section>
        <h3 style={{marginTop: "30px", marginBottom: "30px", fontSize: "22px"}}>2. LICENSES AND THIRD-PARTY CONTENT</h3>

        <h4 style={{marginTop: "20px", marginBottom: "20px", fontSize: "17px"}}>2.1 Workcroft’S PROVISION OF THE SITE AND LIMITED SITE LICENSE</h4>
        <p style={{fontSize: '14px', lineHeight: "25px"}}>
         <i> Section 2.1 discusses the rights we give to you to allow you to use the Site, as detailed below:</i><br/><br/>
         As we describe in this Section 2.1, Workcroft grants you a limited license to access the Site and Site Services. This license is subject to and conditioned on compliance with the Site Terms of Use and, to the extent applicable, the rest of the Terms of Service.
          <br/><br/>
          We try to keep our Site and the Site Services safe, secure, and functioning properly, but we cannot guarantee the continuous operation of or access to our Services. In fact, we might even stop providing the Site or Site Services completely or stop providing certain features without notice.
        </p>

        <h4 style={{marginTop: "20px", marginBottom: "20px", fontSize: "17px"}}>2.2 TERMINATION OF THE LIMITED SITE LICENSE</h4>
        <p style={{fontSize: '14px', lineHeight: "25px"}}>
          <i>Section 2.2 explains that we can stop allowing you to use the Site, as detailed below:</i><br/><br/>
          Workcroft may terminate any license it has granted to any Site Visitor or User to access the Site and Site Services by providing notice, and the termination of such license shall be effective immediately upon Workcroft providing such notice.
        </p>

        <h4 style={{marginTop: "20px", marginBottom: "20px", fontSize: "17px"}}>2.3 Workcroft’S INTELLECTUAL PROPERTY</h4>
        <p style={{fontSize: '14px', lineHeight: "25px"}}>
          <i>Section 2.3 explains that even though we let you use the Site, we still retain all our rights, as detailed below:</i> <br/><br/>
          Workcroft and our licensors retain all right, title, and interest in and to all Intellectual Property Rights related in and to the Site and the Site Services. The Workcroft logos and names are trademarks of Workcroft and are registered in certain jurisdictions. All other product names, company names, marks, logos, and symbols on the Site or Site Services may be the trademarks of their respective owners.
          <br/><br/>
          Nothing in the Terms of Service grants you a right to use any Workcroft Marks.
        </p>
      </section>

      <section>
        <h3 style={{marginTop: "30px", marginBottom: "30px", fontSize: "22px"}}>2.4 YOUR INTELLECTUAL PROPERTY</h3>

        <h4 style={{marginTop: "20px", marginBottom: "20px", fontSize: "17px"}}>2.4.1 YOUR OBLIGATIONS AND YOUR INDEMNIFICATION OF Workcroft</h4>
        <p style={{fontSize: '14px', lineHeight: "25px"}}>
         <i> Section 2.4.1 explains that you are solely responsible for your use of the Site and any content you post on the Site, and that if someone makes a claim against Workcroft because of your content that you will pay Workcroft for our fees and expenses, as detailed below:</i> <br/><br/>
         You acknowledge and agree that the poster of User Content, and not Workcroft, is responsible for any User Content including any harms caused to you, another User, or a third party by such User Content.
          <br/><br/>
          You will indemnify, defend, and hold harmless Workcroft, our Affiliates, and our respective directors, officers, employees, representatives, and agents (each an “<b>Indemnified Party</b>”) from any and all claims, damages, liabilities, costs, losses, and expenses (including, but not limited to, reasonable attorneys’ fees and all related costs and expenses) arising from or relating to any claim, suit, proceeding, demand, or action brought by you or a third party or other User against an Indemnified Party relating to or arising out of any User Content you post.
        </p>

        {/* Add other subsections as needed */}
      </section>

      {/* Add other sections as needed */}
      <section>
        {/* <h3 style={{marginTop: "30px", marginBottom: "30px", fontSize: "22px"}}>2.4 YOUR INTELLECTUAL PROPERTY</h3> */}

        <h4 style={{marginTop: "20px", marginBottom: "20px", fontSize: "17px"}}>2.4.2 YOUR RIGHTS AND LICENSE TO Workcroft AND OTHER SITE VISITORS</h4>
        <p style={{fontSize: '14px', lineHeight: "25px"}}>
         <i> Section 2.4.2 explains that by posting content on the Site, you give others certain limited rights to that content, as detailed below:</i><br/><br/>
         You retain all ownership rights in any User Content you post on Workcroft. To the extent permitted by applicable law, you also grant to Workcroft and our successors and Affiliates a royalty-free, sub-licensable, transferable, perpetual, irrevocable, non-exclusive, worldwide license to use, reproduce, modify, publish, list information regarding, edit, translate, distribute, publicly perform, publicly display, and make derivative works of all such User Content and your name, voice, and/or likeness as contained in your User Content, in whole or in part, and in any form, media, or technology, whether now known or hereafter developed, for use in connection with the Site and Workcroft’s, our successors’ and Affiliates’ businesses, including, without limitation, for promoting and redistributing part or all of the Site (and derivative works thereof) in any media formats and through any media channels. You also hereby grant each User and each Site Visitor a non-exclusive license to access your User Content through the Site and to use, reproduce, distribute, and display such User Content to the extent permitted through the normal functionality of the Site and subject to all applicable confidentiality and other provisions of the Terms of Service, our Privacy Policy, and applicable law.
        </p>

        <h4 style={{marginTop: "20px", marginBottom: "20px", fontSize: "17px"}}>2.4.3 YOUR COMMENTS AND IDEAS</h4>
        <p style={{fontSize: '14px', lineHeight: "25px"}}>
          <i>Section 2.4.3 explains when you can and what happens if you send your ideas to Workcroft, as detailed below:</i><br/><br/>
          You may submit comments or ideas about the Site and Site Services, including without limitation about how to improve the Site or Site Services (collectively, “<b>Ideas</b>”). By submitting any Ideas, you agree that: (a) your disclosure is voluntary, gratuitous, unsolicited, and without restriction and will not place Workcroft under any fiduciary or other obligation, (b) your Ideas do not contain the confidential or proprietary information of third parties, and (c) you grant us a perpetual and royalty-free license to use the Ideas without any additional compensation to you and to disclose the Ideas on a non-confidential basis or otherwise to anyone. You further acknowledge and agree that, by acceptance of your submission, Workcroft does not waive any rights to use similar or related ideas, including those known or developed by Workcroft or obtained from sources other than you.
        </p>
      </section>

      <section>
        <h3 style={{marginTop: "30px", marginBottom: "30px", fontSize: "22px"}}>2.5 THIRD-PARTY INTELLECTUAL PROPERTY</h3>
        <p style={{fontSize: '14px', lineHeight: "25px"}}>
        <i>  Section 2.5 explains that third parties, including other Users, are responsible for content posted or linked on the Site, as detailed below:</i><br/><br/>
        Any information or content expressed or made available by a third party or any other Site Visitor or User is that of the respective author(s) or distributor(s) and not of Workcroft. Workcroft neither endorses nor is responsible for the accuracy or reliability of any opinion, advice, information, or statement made on the Site by anyone other than Workcroft’s authorized agents acting in their official capacities.<br/><br/>
        The Site may contain links to third-party websites. The Site may also contain applications that allow you to access third-party websites via the Site. Such third-party websites or applications are owned and operated by the third parties and/or their licensors. The inclusion of any link or application on the Site does not imply that we endorse the linked site or application. You use the links and third-party websites at your own risk and agree that your use of an application or third-party website accessed via the Site is on an “as is” and “as available” basis without any warranty for any purpose.
        </p>
      </section>

      <section>
        <h3 style={{marginTop: "30px", marginBottom: "30px", fontSize: "22px"}}>2.6 COMPLAINTS ABOUT COPYRIGHTED CONTENT ON THE SITE</h3>
        <p style={{fontSize: '14px', lineHeight: "25px"}}>
          <i>Section 2.6 explains what to do if you think content on the Site infringes your rights, as detailed below:</i><br/><br/>
          Workcroft is committed to complying with India copyright and related laws and requires all Site Visitors and Users to comply with these laws. Accordingly, you may not use the Site to store any material or content, or disseminate any material or content, in any manner that constitutes an infringement of third party intellectual property rights, including rights granted by India copyright law.<br/><br/>
          If you are the owner of any copyrighted work and believe your rights under India copyright law have been infringed by any material on the Site, contact us at hello@workcroft.com
        </p>
      </section>

      <section>
        <h3 style={{marginTop: "30px", marginBottom: "30px", fontSize: "22px"}}>3. PERMITTED SITE USES</h3>
        <p style={{fontSize: '14px', lineHeight: "25px"}}>
         <i> Section 3 explains how you are allowed to use the Site, as detailed below:</i><br/><br/>
         Workcroft offers the Site and Site Services for your business purposes only and not for personal, household, or consumer use. Workcroft makes the Site and Site Services available for Users to find one another, enter into service relationships, make and receive payments through escrow, and receive and provide Freelancer Services. In addition, certain Visitor Site Services, such as the Workcroft Blog and Hiring Headquarters, provide, and may be used to obtain, general information and articles that we believe may be of interest to Site Visitors and Users. While we try to ensure that any information we post is both timely and accurate, errors may appear from time to time. We do not make any representations or warranties with respect to any information that is posted on the Site by us or anyone else. In no event should any content be relied on or construed as tax or legal advice or otherwise. You should independently verify the accuracy of any content.
        </p>
      </section>

      <section>
        <h3 style={{marginTop: "30px", marginBottom: "30px", fontSize: "22px"}}>4. PROHIBITED SITE USES</h3>
        <p style={{fontSize: '14px', lineHeight: "25px"}}>
        <i>  Section 4 explains uses of the Site that are not allowed, as detailed below:</i><br/><br/>
        You may not use, or encourage, promote, facilitate, instruct or induce others to use, the Site or Site Services for any activities that violate any law, statute, ordinance or regulation; for any other illegal or fraudulent purpose or any purpose that is harmful to others; or to transmit, store, display, distribute or otherwise make available content that is illegal, fraudulent or harmful to others.
        </p>

        <h4 style={{marginTop: "20px", marginBottom: "20px", fontSize: "17px"}}>4.1 EXAMPLES OF PROHIBITED USES OF THE SITE</h4>
        <p style={{fontSize: '14px', lineHeight: "25px"}}>
         <i> The following are examples of uses that are prohibited on the Site or when using the Site Services:</i> <br/><br/>
        </p>
         <ul style={{ listStyleType: 'circle', fontSize: "14px" }}>
            <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Seeking, offering, promoting, or endorsing and services, content, or activities that:
               <ul style={{ listStyleType: 'circle' }}>
                <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>are defamatory, illegal, profane, vulgar, threatening, unlawfully discriminatory, illegal, pornographic, obscene, or sexually explicit in nature;</li>
                <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>would violate the intellectual property rights, such as and including copyrights, of another person, entity, service, product, or website;</li>
                <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>would violate (a) Workcroft’s Terms of Service, (b) the terms of service of another website or any similar contractual obligations, or (c) the academic policies of any educational institution;</li>
                <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>regard the creation, publication, distribution of “fake news”, “hoax news” or similar false content purposefully intended to mislead readers for financial or other gain;</li>
                <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>regard or promote in any way any escort services, prostitution, or sexual acts; or</li>
                <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>are harassing toward another person based on the person's inclusion in a protected class as defined by applicable law;</li>
              </ul>
            </li>
            <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Fraudulent or misleading uses or content, including:
               <ul style={{ listStyleType: 'circle' }}>
                <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Fraudulently billing or attempting to fraudulently bill any Client, including by (i) falsifying or manipulating or attempting to falsify or manipulate the hours, keystrokes, or mouse clicks recorded in the Workcroft App, (ii) reporting, recording, or otherwise billing Clients for time that was not actually worked, or (iii) reporting, recording, or otherwise billing hours worked by another person as hours worked by you in a way that is misleading or false;</li>
                <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Misrepresenting your experience, skills, or information, including by representing another person’s profile, or parts of another person’s profile, as your own;</li>
                <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Using a profile photo that misrepresents your identity or represents you as someone else;</li>
                <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Impersonating any person or entity, including, but not limited to, an Workcroft representative, forum leader, or falsely stating or otherwise misrepresenting your affiliation with a person or entity;</li>
                <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Falsely stating or implying a relationship with another User, including an Agency continuing to use a Freelancer’s profile or information after the Freelancer no longer works with the Agency;</li>
                <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Falsely attributing statements to any Workcroft representative, forum leader, guide or host;</li>
                <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Falsely stating or implying a relationship with Workcroft or with another company with whom you do not have a relationship;</li>
                <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Allowing another person to use your account, which is misleading to other Users; or</li>
                <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Falsely stating that one Freelancer will perform the work on a job when another will in fact perform the work, including submitting a proposal on behalf of a Freelancer that is unable, unwilling, or unavailable to do the work;</li>
                {/* Add more items as needed */}
              </ul>
            </li>
            <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Expressing an unlawful preference in a job post or proposal or otherwise unlawfully discriminating on a protected basis;</li>
                <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Posting identifying information concerning another person;</li>
                <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Spamming other Users with proposals or invitations or posting the same job multiple times so that more than one version remains active at a given time;</li>
                <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Making or demanding bribes or other payments without the intention of providing services in exchange for the payment;</li>
                <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Requesting or demanding free services, including requesting Freelancers to submit work as part of the proposal process for very little or no money or posting contests in which Freelancers submit work with no or very little pay, and only the winning submission is paid the full amount;</li>
                <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Requesting a fee before allowing a User to submit a proposal;</li>
                <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Attempting to or actually manipulating or misusing the feedback system, including by:</li>
                     <ul style={{ listStyleType: 'circle' }}>
                        <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>withholding payment or Work Product or engaging in any other conduct for the purpose of obtaining positive feedback from another User;</li>
                        <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>attempting to coerce another User by threatening to give negative feedback;</li>
                        <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>expressing views unrelated to the work, such as political, religious, or social commentary, in the feedback system; or</li>
                        <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>offering services for the sole purpose of obtaining positive feedback of any kind;</li>
                    </ul>
                <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Duplicating or sharing accounts;</li>
                <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Selling, trading, or giving an account to another person without Workcroft’s consent;</li>
                <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Sharing or soliciting contact information, such as email, phone number, or Skype ID, in a profile or job post;</li>
                <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Directly or indirectly, advertising or promoting another website, product, or service or soliciting other Users for other websites, products, or services, including advertising on Workcroft to recruit Freelancers and/or Clients to join an Agency or another website or company;</li>
                <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Conduct or actions that could jeopardize the integrity of or circumvent the Site, Site Services or Workcroft’s proprietary information, including
                     <ul style={{ listStyleType: 'circle' }}>
                        <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Interfering or attempting to interfere with the proper operation of the Site or Site Services or any activities conducted on the Site;</li>
                        <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Bypassing any measures we may use to prevent or restrict access to the Site or any subparts of the Site, including, without limitation, features that prevent or restrict use or copying of any content or enforce limitations on use of the Site or the content therein;</li>
                        <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Attempting to interfere with or compromise the system integrity or security or decipher any transmissions to or from the servers running the Site;</li>
                        <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Using any robot, spider, scraper, or other automated means to access the Site for any purpose without our express written permission;</li>
                        <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Attempting to or interfering with or compromising the system integrity or security or deciphering any transmissions to or from the servers running the Site;</li>
                        <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Collecting or harvesting any personally identifiable information, including Account names, from the Site;</li>
                        <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Attempting to or imposing an unreasonable or disproportionately large load (as determined in Workcroft’s sole discretion) on the Site’s infrastructure; Introducing any invalid data, virus, worm, or other harmful or malicious software code, agent, hidden procedure, routine, or mechanism through or to the Site that is designed to or known to cause to cease functioning, disrupt, disable, harm, or otherwise impair in any manner, including aesthetic disruptions or distortions, the operation of (or to allow you or any other person to access or damage or corrupt data, storage media, programs, equipment, or communications or otherwise interfere with operations of or on) the Site or any software, firmware, hardware, computer system, or network of Workcroft or any third party;</li>
                        {/* <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Introducing any invalid data, virus, worm, or other harmful or malicious software code, agent, hidden procedure, routine, or mechanism through or to the Site that is designed to or known to cause to cease functioning, disrupt, disable, harm, or otherwise impair in any manner, including aesthetic disruptions or distortions, the operation of (or to allow you or any other person to access or damage or corrupt data, storage media, programs, equipment, or communications or otherwise interfere with operations of or on) the Site or any software, firmware, hardware, computer system, or network of Hirekingdom Jobs or any third party;</li> */}
                        <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Accessing or attempting to access the Site or Site Services by any means or technology other than the interface provided; or</li>
                        <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Framing or linking to the Site or Site Services except as permitted in writing by Workcroft</li>
                    </ul>
                </li>
                <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Attempting to or actually reverse engineering, modifying, adapting, translating, preparing derivative works from, decompiling, interfering with the operation of, or otherwise attempting to derive source code from any part of the Site or Site Services unless expressly permitted by applicable law or Workcroft; or</li>
                <li><GoDotFill className='mr-3' style={{fontSize: "14px"}}/>Accessing or using the Site or Site Services to build a similar service or application, identify or solicit Workcroft Users, or publish any performance or any benchmark test or analysis relating to the Site.</li>
                    </ul>

        <h4 style={{marginTop: "20px", marginBottom: "20px", fontSize: "17px"}}>4.2 ENFORCEMENT</h4>
        <p style={{fontSize: '14px', lineHeight: "25px"}}>
        We reserve the right, but do not assume the obligation, to investigate any potential violation of this Section 4 or any other potential violation of these Site Terms of Use and to remove, disable access to, or modify any content on the Site. Our failure to act with respect to a breach by you or others does not waive our right to act with respect to subsequent or similar breaches. We do not guarantee we will take action against all breaches of these Terms of Use or the User Agreement.
        </p>

        <h4 style={{marginTop: "20px", marginBottom: "20px", fontSize: "17px"}}>4.3 REPORTING AND CORRECTING VIOLATIONS</h4>
        <p style={{fontSize: '14px', lineHeight: "25px"}}>
        If you become aware of any violation of these Site Terms or Use, you must immediately report it to Customer Service. You agree to assist us with any investigation we undertake and to take any remedial steps we require in order to correct a violation of these Site Terms of Use.
        </p>
      </section>

      <section>
      <h3 style={{marginTop: "30px", marginBottom: "30px", fontSize: "22px"}}>5. DEFINITIONS</h3>
  <p style={{fontSize: '14px', lineHeight: "25px"}}>
   <i>Section 5 gives you some definitions of capitalized terms that appear in the Site Terms of Use but other capitalized terms are defined above or in the User Agreement, which you can tell because the term is put in quotation marks and bold font.</i> <br/><br/>
  </p>
  <br/><br/>
  <p>Capitalized terms not defined below have the meanings described in the Terms of Service.</p>
  <br/><br/>
  <p style={{fontSize: '14px', lineHeight: "25px"}}>
    <strong>Affiliate</strong> means any entity that, directly or indirectly, through one or more intermediaries, controls, is controlled by, or is under common control with Workcroft.
  </p>
  <br/>
  <br/>
  <p style={{fontSize: '14px', lineHeight: "25px"}}>
    <strong>“Client”</strong> means any authorized User utilizing the Site to seek and/or obtain Freelancer Services from another User.
  </p>
  <br/>
  <br/>
  <p style={{fontSize: '14px', lineHeight: "25px"}}>
    <strong>Freelancer</strong> means any authorized User utilizing the Site to advertise and/or provide Freelancer Services to Clients, including Freelancer Accounts that are Agency Accounts or, if applicable, Agency Members. A Freelancer is a customer of Workcroft with respect to use of the Site and Site services.
  </p>
  <br/>
  <br/>
  <p style={{fontSize: '14px', lineHeight: "25px"}}>
    <strong>“Freelancer Services”</strong> means any services provided by Freelancers.
  </p>
  <br/>
  <br/>
  <p style={{fontSize: '14px', lineHeight: "25px"}}>
    <strong>“Intellectual Property Rights”</strong> means all patent rights, copyright rights, mask work rights, moral rights, rights of publicity, trademark, trade dress and service mark rights, goodwill, trade secret rights and other intellectual property rights as may now exist or hereafter come into existence, and all applications therefore and registrations, renewals and extensions thereof, under the laws of any state, country, territory or other jurisdiction.
    </p>
    <br/>
    <br/>
    <p style={{fontSize: '14px', lineHeight: "25px"}}>
    <strong>“Public Site Services”</strong> means, collectively, all services, applications and products that are accessible by any Site Visitor who has not become an User through the Site, to the extent they are accessible by a Site Visitor who has not become a User.
  </p>
  <br/>
  <br/>
  <p style={{fontSize: '14px', lineHeight: "25px"}}>
    <strong>“Site”</strong> means, collectively, our website located at <a href="https://workcroft.com">www.workcroft.com</a>, all affiliated websites, including mobile websites and Workcroft Mobile Applications, owned and operated by us, our predecessors or successors in interest, or our Affiliates (defined in the User Agreement).
  </p>
  <br/>
  <br/>
  <p style={{fontSize: '14px', lineHeight: "25px"}}>
    <strong>“Site Services”</strong> means, collectively, all services (except the Freelancer Services defined herein), applications and products that are accessible through the Site, including the Public Site Services, and all Workcroft Mobile Applications.
  </p>
  <br/>
  <p style={{fontSize: '14px', lineHeight: "25px"}}>
    <strong>“Workcroft  Mobile Applications”</strong> means all mobile applications published by Workcroft or our Affiliates for access to or use of the Site or any Site Services.
  </p>
  <br/>
  <br/>
  <p style={{fontSize: '14px', lineHeight: "25px"}}>
    <strong>“User Content”</strong> means any comments, remarks, data, feedback, content, text, photographs, images, video, music, or other information that you or any Site Visitor or User post to any part of the Site or provide to Workcroft, including such information that is posted as a result of questions.
  </p>
  <br/>
  <br/>
  <p style={{fontSize: '14px', lineHeight: "25px"}}>
    <strong>“You”</strong> means any person who accesses our Site, including a Site Visitor or User and including any entity or agency on whose behalf any such person accesses or uses our Site.

  </p>
      </section>
      </div>
    </div>
  );
}

export default TermsOfUse;
