import React, { useRef } from "react";
import "./css/MessageStudent.scss";
import mentionstyle from ".//mention.js";
import io from "socket.io-client";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { MdOutlineArrowBack } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import { MdOutlineMarkEmailUnread, MdMailOutline } from "react-icons/md";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import sendAssignmentLogo from "../../assets/images/sendassignment.png";
import reportLogo from "../../assets/images/report.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RiChatOffLine } from "react-icons/ri";
import { index } from "d3";
import Dropdown from "./Dropdown";
import { read } from "xlsx";
import { PiChecks } from "react-icons/pi";
import { MdKeyboardBackspace } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import { LuSearch } from "react-icons/lu";
import ChatUserModel from "../Modal/ChatUserModel";
import { MentionsInput, Mention } from "react-mentions";
import { Tooltip } from "@mui/material";
import { IoLocation } from "react-icons/io5";

// const socket = io.connect("http://localhost:8000");
 const socket = io.connect("https://workcroft.com");
// const socket = io.connect('http://34.122.119.232:8000/');

function MessageStudent(props) {
  const [viewMessage, setViewMesage] = useState(false);

  const { state } = useLocation();

  const wrapLinks = (content) => {
    if (!content) return "";
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return content.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);

  const [CompanyphotoUrl, setCompanyphotourl] = useState("");
  const [CandidatephotoUrl, setCandidatephotoUrl] = useState("");
  const [CandidateName, setCandidateName] = useState("");
  const [CompanyCandidatename, setCompanyCandidatename] = useState("");
  const [allRooms, setAllRooms] = useState([]);
  const [chatMessage, setchatMessage] = useState("");
  const [history, setHistory] = useState([]);
  const { user } = useSelector((state) => state.user);
  const [companyName, setCompanyName] = useState("");
  const [roomId, setRoomId] = useState("");
  const [hasJoinedRoom, setHasJoinedRoom] = useState(false);
  const [chatActive, setChatActive] = useState(0);
  const [unread, setUnread] = useState(false);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [inputDataSendAssigment, setInputDataSendAssigment] = useState({
    description: "",
    date: "",
  });
  const [loader, setLoader] = useState(true);

  const [activeTab, setActiveTab] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.trim().toLowerCase());
  };

  const ReadMess = async () => {
    setUnread(false);
    setActiveTab("all");
    const id = user && user._id;
    const { data } = await axios.post(
      `/chats/all-conversations/${id}`,
      {
        unreadMessages: false,
      },
      {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      }
    );

    if (data && data.length > 0) {
      setAllRooms(data);
      joinRoom(
        data[0].roomId,
        data[0].participants[0],
        data[0].participants[1]
      );
    }
  };

  const unRead = async () => {
    setUnread(true);
    setActiveTab("unread");
    const id = user && user._id;
    const { data } = await axios.post(
      `/chats/all-conversations/${id}`,
      {
        unreadMessages: true,
      },
      {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      }
    );

    setAllRooms(data);
    if (data && data.length > 0) {
      joinRoom(
        data[0].roomId,
        data[0].participants[0],
        data[0].participants[1]
      );
    }
  };

  const getActiveButtonStyle = () => {
    if (activeTab === "all") {
      return {
        "--active-width": "119.417px",
        "--transform-x": "-1px",
      };
    } else {
      return {
        "--active-width": "103.0833px",
        "--transform-x": "112.417px",
      };
    }
  };

  useEffect(() => {
    socket.on("test_message", (data) => {});
    if (allRooms && allRooms.length > 0 && chatActive == 0) {
      setChatActive(allRooms[0].roomId);
    }
  });

  const handleInputSendAssigment = (e) => {
    setInputDataSendAssigment({
      ...inputDataSendAssigment,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmitSendAssigment = () => {
    // Put your code here...
    console.log(inputDataSendAssigment);
    setInputDataSendAssigment({
      ...inputDataSendAssigment,
      description: "",
      date: "",
    });
  };

  const [inputDataSendReport, setInputDataSendReport] = useState({
    reason: "Provided fake documents",
    description: "",
    otherReason: "",
  });

  const GetRoomData = async () => {
    // setLoader(true);
    try {
      const id = user && user._id;
      const { data } = await axios.post(
        `/chats/all-conversations/${id}`,
        {
          unreadMessages: unread,
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        }
      );

      setAllRooms(data);
      setLoader(false);
    } catch (err) {
      console.error("Error fetching data:" + err);
      setLoader(false);
    }
  };

  useEffect(() => {
    GetRoomData();
  }, [socket, history, user]);

  const [once, setOnce] = useState(true);
  const [temp, setTemp] = useState("");
  useEffect(() => {
    if (once) {
      const roomIds = allRooms && allRooms.map((data) => data.roomId);
      roomIds.forEach((roomIds) => {
        //joinRoom(roomIds.roomId,roomIds.participants[0].organisationName);
        socket.emit("join_room", roomIds.roomId);
        socket.on("room_data", (data) => {
          setTemp(data);
        });
      });
      setOnce(false);
    }
  }, [allRooms]);

  const [historyChange, setHistoryChange] = useState(false);
  useEffect(() => {
    if (allRooms && allRooms.length > 0 && !hasJoinedRoom) {
      if (state) {
        console.log(state);
        const room = allRooms.find(
          (item) =>
            item.jobId._id === state.jobId._id &&
            item.appliedJobId._id === state.appliedJobId._id
        );

        if (room) {
          joinRoom(room.roomId, room.participants[0], room.participants[1]);
        } else {
          console.log("Room not found");
        }
      } else {
        joinRoom(
          allRooms[0].roomId,
          allRooms[0].participants[0],
          allRooms[0].participants[1]
        );
      }
      setHasJoinedRoom(true); // Set the flag to true after joining room
    } else if (allRooms && allRooms.length > 0 && historyChange) {
      let room = allRooms.find((item) => item.roomId === chatActive);
      joinRoom(chatActive, room.participants[0], room.participants[1]);
      /* socket.emit('join_room', chatActive);
      socket.on('room_data', (data) => {
        setHistory(data);
      });*/

      setHistoryChange(false);
    }
  }, [allRooms, chatActive, hasJoinedRoom]);

  useEffect(() => {
    // An array of your room IDs
    const roomIds = allRooms && allRooms.map((data) => data.roomId);
    // Function to set up event listener for a specific room
    const setupRoomListener = (roomId2) => {
      socket.on(`receive_message_${roomId2}`, (data) => {
        console.log(`Received message for room ${roomId2}:`, data);
        GetRoomData();
        if (roomId2 == chatActive) {
          setHistoryChange(true);
        }
      });
    };

    // Set up event listeners for each room
    roomIds.forEach((roomIds) => {
      setupRoomListener(roomIds);
    });
    // Cleanup the event listeners on component unmount
    return () => {
      roomIds.forEach((roomIds) => {
        socket.off(`receive_message_${roomIds}`);
      });
    };
  });
  const [company, setcompany] = useState();
  const joinRoom = async (roomId1, company, cand) => {
    setCompanyName(company?.organisationName);
    setCompanyphotourl(
      company?.avatar?.url || company?.organisationAvatar?.url
    );
    setcompany(company);
    setCandidateName(cand?.name);
    setCandidatephotoUrl(cand?.resume?.PersonalDetails?.photo?.url);
    setCompanyCandidatename(company?.name);
    setRoomId(roomId1);
    socket.emit("join_room", roomId1);
    socket.on("room_data", (data) => {
      setHistory(data);
      setChatActive(roomId1);
      if (
        data.messages.length > 0 &&
        data.messages[data.messages.length - 1].status == "unread" &&
        data.messages[data.messages.length - 1].receiver == user._id
      ) {
        axios.post(
          "/chats/read-messages",
          { roomId1 },
          {
            headers: {
              "Content-Type": "application/json",
              token: localStorage.getItem("token"),
            },
          }
        );
      }
    });

    GetRoomData();
  };

  const sendMessage = async (data, jobId) => {
    if (chatMessage !== "") {
      const content = chatMessage;

      const participants = [user._id, data];
      const data1 = {
        roomId,
        message: content,
      };

      await axios.post(
        "/chats/send-messages",
        { jobId, participants, content },
        {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        }
      );
      setchatMessage("");
      let room = allRooms.find((item) => item.roomId === chatActive);
      joinRoom(chatActive, room.participants[0], room.participants[1]);
      GetRoomData();
      socket.emit("new_message", data1);
    }
  };

  const scroll = useRef();

  const scrolltoBottom = () => {
    scroll.current.scrollIntoView({
      behaviour: "smooth",
      block: "end",
    });
  };

  const scroll2 = useRef();

  const scrolltoTop = () => {
    scroll.current.scrollIntoView({
      behaviour: "smooth",
      block: "start",
    });
  };

  useEffect(() => {
    if (scroll.current) {
      scrolltoBottom();
    }
  }, [history]);

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isButtonClicked, setButtonClicked] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const dropdownRef = useRef();
  const dropdownRef1 = useRef();
  const [isDropdownOpen1, setDropdownOpen1] = useState(false);
  const [isButtonClicked1, setButtonClicked1] = useState(false);

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
    setButtonClicked(!isButtonClicked);
  };

  const handleDropdownToggle1 = () => {
    setDropdownOpen1(!isDropdownOpen1);
    setButtonClicked1(!isButtonClicked1);
  };

  const handleDropdownItemClick1 = (item) => {
    setInputDataSendReport({ ...inputDataSendReport, reason: item.name });
    setDropdownOpen1(false);
    setButtonClicked1(false);
    // Add your custom logic for item click here, if needed
    // For example, you can handle the selected item
  };

  const handleDropdownItemClick = (item) => {
    setInputDataSendReport({ ...inputDataSendReport, reason: item.name });
    setDropdownOpen(false);
    setButtonClicked(false);
    // Add your custom logic for item click here, if needed
    // For example, you can handle the selected item
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
      setButtonClicked(false);
    }
    if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
      setDropdownOpen1(false);
      setButtonClicked1(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const [lastTimestamp, setLastTimestamp] = useState(null);
  const [lastDisplayedDate, setLastDisplayedDate] = useState(null);

  useEffect(() => {
    if (history.messages && history.messages.length > 0) {
      const lastMessageTimestamp =
        history.messages[history.messages.length - 1].timestamp;
      if (lastTimestamp !== lastMessageTimestamp) {
        setLastTimestamp(lastMessageTimestamp);
      }
    }
  }, [history.messages, lastTimestamp]);

  useEffect(() => {
    if (lastDisplayedDate) {
      console.log("Last Displayed Date:", lastDisplayedDate); // You can remove this line later
    }
  }, [lastDisplayedDate]);

  function isToday(date) {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }

  const getTime = (data) => {
    const timestamp = new Date(data);
    const formattedTime = new Intl.DateTimeFormat("en-IN", {
      timeZone: "Asia/Kolkata",
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
    }).format(timestamp);
    return formattedTime;
  };

  /*  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        const myButton = document.querySelector('.btnsend');
        if (myButton) {
          myButton.click();
        } else {
          console.error('Button not found');
        }
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);*/

  const unreadMsg = allRooms.filter(
    (item) => item.status === "unread" && item.sender !== user._id
  ).length;

  useEffect(() => {
    socket.on("new_message", (data) => {});
  });

  const readMesCount = (data) => {
    const unreadCount = data.filter(
      (message) => message.status === "unread" && message.receiver === user._id
    ).length;
    return unreadCount;
  };

  // MULTILINE MESSAGE OPTION

  const textareaRef = useRef(null);
  const parentDivRef = useRef(null);
  const [textareaRows, setTextareaRows] = useState(1);
  // const maxRows = 6;
  const maxRows = 6;
  const defaultTextareaHeight = 42;

  // const handleKeyDown = (e) => {
  //   if ((e.altKey || e.shiftKey) && e.key === 'Enter') {
  //     e.preventDefault(); // Prevent default behavior of newline insertion
  //     setchatMessage(prevMessage => prevMessage + '\n');
  //   }
  // };
  const handleKeyDown = (e) => {
    if ((e.altKey || e.shiftKey) && e.key === "Enter") {
      e.preventDefault(); // Prevent default behavior of newline insertion
      setchatMessage((prevMessage) => prevMessage + "\n");
    } else if (e.key === "Enter") {
      e.preventDefault(); // Prevent default behavior of form submission
      sendMessage(
        history.participants[0] === user._id
          ? history.participants[1]
          : history.participants[0],
        history.jobId
      );
    }
  };

  const [showmodel, setshowmodel] = useState(false);
  const [cord, setcord] = useState({ top: 0, left: 0 });

  const handleChange = (e) => {
    setchatMessage(e.target.value);
    // const lastChar = chatMessage.slice(-1);
    // const beforeLastChar = chatMessage.slice(-2, -1);

    // if (
    //   lastChar === "@" &&
    //   (beforeLastChar === " " || chatMessage.length === 1)
    // ) {
    //   setshowmodel(true);
    //   // Get the cursor position in the text (selectionStart)
    //   const cursorPosition = textareaRef.current.selectionStart;

    //   // Get the bounding rectangle of the textarea (relative to the viewport)
    //   const textareaRect = textareaRef.current.getBoundingClientRect();

    //   // Create a selection range at the cursor position
    //   textareaRef.current.setSelectionRange(cursorPosition, cursorPosition);

    //   // Get the rectangle representing the caret's position
    //   const caretRect = textareaRef.current.getClientRects()[0]; // Get caret's position

    //   // Calculate the top and left relative to the textarea
    //   const top = caretRect.top - textareaRect.top - 30; // Offset to position the model above "@"
    //   const left = caretRect.left - textareaRect.left;
    //   console.log("cursor", caretRect, textareaRect, textareaRef);
    //   setcord({
    //     left: left,
    //     top: top,
    //   });
    // } else {
    //   setshowmodel(false);
    // }
  };
  const handleMentionClick = (user) => {
    const updatedMessage = chatMessage + user + " ";
    setchatMessage(updatedMessage);
    setshowmodel(false);
  };
  const getCaretCoordinates = (element, position) => {
    const div = document.createElement("div");
    const style = getComputedStyle(element);

    for (let prop of style) {
      div.style[prop] = style[prop];
    }

    div.style.position = "absolute";
    div.style.visibility = "hidden";
    div.style.whiteSpace = "pre-wrap";

    const text = element.value.substr(0, position);
    div.textContent = text;

    document.body.appendChild(div);
    const span = document.createElement("span");
    span.textContent = element.value.substr(position) || ".";
    div.appendChild(span);

    const { offsetLeft: left, offsetTop: top } = span;
    document.body.removeChild(div);

    return { left, top };
  };

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      const newRows = Math.min(textarea.value.split("\n").length, maxRows);
      setTextareaRows(newRows);
      textarea.style.height = `${newRows * 20}px`;
      textarea.style.height = `${Math.max(
        textareaRows * 20,
        defaultTextareaHeight
      )}px`;
      const newHeight = chatMessage
        ? Math.max(textareaRows * 20, defaultTextareaHeight)
        : defaultTextareaHeight;
      textarea.style.height = `${newHeight}px`;
    }
  }, [chatMessage]);

  useEffect(() => {
    const textarea = textareaRef.current;
    const parentDiv = parentDivRef.current;
    if (textarea && parentDiv) {
      const textValue = textarea.value && "";
      const newRows = Math.min(textValue.split("\n").length, maxRows);
      setTextareaRows(newRows);
      // const newHeight = Math.max(newRows * 20, defaultTextareaHeight);
      const newHeight = Math.max(newRows * 30, defaultTextareaHeight);
      textarea.style.height = `${newHeight}px`;
      parentDiv.style.height = `${Math.min(newHeight, 240)}px`;
    }
  }, [chatMessage, textareaRows]);
  // useEffect(() => {
  //   const textarea = textareaRef.current;
  //   const parentDiv = parentDivRef.current;

  //   if (textarea) {
  //     const textValue = textarea.value || ""; // Default to empty string if no value
  //     const newRows = Math.min(textValue.split("\n").length, maxRows); // Determine rows
  //     setTextareaRows(newRows);

  //     // Dynamically set the height of the textarea
  //     const newHeight = Math.max(newRows * 30, defaultTextareaHeight);
  //     textarea.style.height = `${newHeight}px`;

  //     if (parentDiv) { // Check if parentDiv is defined
  //       parentDiv.style.height = `${Math.min(newHeight, 240)}px`; // Limit height of parentDiv
  //     }

  //     // Scroll textarea to bottom when typing or adding new lines
  //     if (textarea.scrollHeight > textarea.clientHeight) {
  //       textarea.scrollTop = textarea.scrollHeight - textarea.clientHeight;
  //     }
  //   }
  // }, [chatMessage, textareaRows]);

  useEffect(() => {
    // Scroll textarea to bottom when chatMessage changes
    if (textareaRef.current) {
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }
  }, [chatMessage]);

  // console.log("textareaRef",textareaRef)
  return (
    <>
      <div className="ChatPcView">
        <div
          className="message-student  pb-0 "
          style={{
            backgroundColor: "white",
            borderRadius: "5px",
            // maxWidth: "1200px",
            // margin: "0 3rem",
          }}
        >
          {/* <h1 className="mb-3 ml-4" style={{ fontSize: '30px' }}>
          <MdOutlineArrowBack
            className="mr-4"
            style={{ fontSize: '28px', cursor: 'pointer', marginBottom: '5px' }}
            onClick={() => navigate('/applications')}
          />
          Chat
        </h1> */}
          <div className="chat-box1 shadow">
            {loader ? (
              <>
                <div className="recent-chats" style={{ width: "100%" }}>
                  <div className="chats text-center" style={{ width: "100%" }}>
                    <div
                      class="spinner-border "
                      role="status"
                      style={{
                        width: "27px",
                        height: "27px",
                        marginTop: "20px",
                        color: "#5db2f7",
                      }}
                    ></div>
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "400",
                        color: "gray",
                      }}
                      className=" text-center"
                    >
                      Loading...
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <>
                {allRooms && allRooms.length > 0 ? (
                  <>
                    <div className="recent-chats">
                      {/* Read Unread switch  */}
                      {/*   <div
                      id="conversation_list_header"
                      style={{
                        padding: '25px',
                        border: '1px solid #f0eeee',
                        borderTopLeftRadius: '5px',
                        // display: 'flex',
                        // justifyContent: 'center',
                      }}
                      className="student"
                    >
                      <div
                        className="summary_toggle"
                        style={getActiveButtonStyle()}
                      >
                        <button
                          type="button"
                          className={`switch ${
                            activeTab === 'all' ? 'active' : ''
                          }`}
                          onClick={ReadMess}
                        >
                          All messages
                        </button>
                        <button
                          type="button"
                          className={`switch ${
                            activeTab === 'unread' ? 'active' : ''
                          }`}
                          onClick={unRead}
                        >
                          Unread ({unreadMsg})
                        </button>
                      </div>
                    </div>*/}
                      <div
                        className=""
                        style={{
                          padding: "10px 0 0 0",
                          boxShadow: "0 2px 6px rgba(0, 0, 0, 0.12)",
                          display: "flex",
                          borderTopLeftRadius: "6px",
                          // borderBottomLeftRadius: "6px", // This is commented out
                          flexDirection: "column",
                          alignItems: "center",
                          borderRight: "1px solid #f0eeee",
                          borderBottom: "1px solid rgb(224, 224, 224)",
                        }}
                      >
                        <div className="" style={{ width: "100%" }}>
                          <div className="" style={{ margin: "0px auto" }}>
                            <div
                              className=""
                              style={{
                                fontSize: "xx-large",
                                fontWeight: "500",
                                margin: "1rem 2rem",
                              }}
                            >
                              Messages
                            </div>

                            <div
                              className="sliderSearch"
                              style={{
                                display: "flex",
                                border: "1px solid #ddd",
                                marginTop: "20px",
                                width: "90%",
                                margin: "0 auto 16px",
                                borderRadius: "3px",
                                padding: "7px 11px",
                                alignItems: "center",
                                position: "relative",
                                cursor: "text",
                                backgroundColor: "#fff",
                              }}
                            >
                              <LuSearch
                                style={{
                                  fontSize: "24px",
                                  color: "#484848",
                                  fontWeight: "400",
                                }}
                              />
                              <input
                                type="text"
                                placeholder="Search "
                                value={search}
                                onChange={(e) => {
                                  handleSearch(e);
                                  setSearch(e.target.value);
                                }}
                                style={{
                                  fontSize: "14px",
                                  lineHeight: "1.14285714",
                                  fontWeight: "400",
                                  border: "0",
                                  outline: "0",
                                  flex: "1",
                                  padding: "0 8px",
                                  height: "24px",
                                  color: "#484848",
                                }}
                              />

                              {search && (
                                <RxCross1
                                  height={24}
                                  width={24}
                                  fill="#484848"
                                  fontSize={20}
                                  cursor={"pointer"}
                                  onClick={() => {
                                    setSearch("");
                                    handleSearch({ target: { value: "" } });
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="mb-4"></div>
                      </div>

                      <div className="chats" ref={scroll2}>
                        {allRooms
                          .filter((item) =>
                            item.participants[0]?.name
                              ?.toLowerCase()
                              .includes(search.toLowerCase())
                          )
                          .map((item, index) => (
                            <div key={index} className="wholeBox">
                              <div
                                onClick={() =>
                                  joinRoom(
                                    item.roomId,
                                    item.participants[0],
                                    item.participants[1]
                                  )
                                }
                                key={index}
                                href="#"
                                className={
                                  chatActive == item.roomId
                                    ? "chat-active"
                                    : "chat"
                                }
                              >
                                <div style={{}}>
                                  <div style={{ display: "flex" }}>
                                    {item.participants[0]?.avatar?.url ||
                                    item.participants[0]?.organisationAvatar
                                      ?.url ? (
                                      <div className="">
                                        <img
                                          src={
                                            item.participants[0]?.avatar?.url ||
                                            item.participants[0]
                                              ?.organisationAvatar?.url
                                          }
                                          alt="Profile"
                                          style={{
                                            width: "50px",
                                            height: "50px",
                                            objectFit: "cover",
                                            borderRadius: "50%",
                                            // border: "2px solid gray",
                                            marginRight: "1rem",
                                          }}
                                          // className="phototodisplayindesktop"
                                          // style={{
                                          //   boxShadow: "0 0 5px rgba(0,0,0,0.2)",
                                          //   border: "4px solid white",
                                          // }}
                                        />
                                      </div>
                                    ) : (
                                      <div className="">
                                        <div
                                          style={{
                                            width: "50px",
                                            height: "50px",
                                            objectFit: "cover",
                                            borderRadius: "50%",
                                            border: "2px solid gray",
                                            marginRight: "1rem",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            fontSize: "large",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {item.participants[0].name.charAt(0)}
                                        </div>
                                      </div>
                                    )}
                                    <div className="" style={{ width: "100%" }}>
                                      <div
                                        className=""
                                        style={{ display: "flex" }}
                                      >
                                        <span
                                          style={{
                                            fontWeight: "600",
                                            fontSize: "16px",
                                            lineHeight: "1.25",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            color: "#333",
                                          }}
                                        >
                                          {item.participants[0].name}
                                        </span>

                                        <div
                                          style={{
                                            paddingLeft: "24px",
                                            marginLeft: "auto",
                                            display: "flex",
                                            flexShrink: "0",
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: "12px",
                                              lineHeight: "1.33333333",
                                              fontWeight: "500",
                                              textAlign: "right",
                                              color: "#8a8a8a",
                                            }}
                                          >
                                            {item.messages &&
                                            item.messages.length > 0 ? (
                                              <>
                                                {item.messages[
                                                  item.messages.length - 1
                                                ].timestamp.slice(8, 10) +
                                                  "/" +
                                                  item.messages[
                                                    item.messages.length - 1
                                                  ].timestamp.slice(5, 7) +
                                                  "/" +
                                                  item.messages[
                                                    item.messages.length - 1
                                                  ].timestamp.slice(0, 4)}
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </span>
                                        </div>
                                      </div>

                                      <span
                                        style={{
                                          fontSize: "14px",
                                          lineHeight: "1.57142857",
                                          fontWeight: "400",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          color: "#8A8A8A",
                                          padding: "2px 0 0",
                                        }}
                                        // className="message"
                                      >
                                        {item.jobId && item.jobId.profile
                                          ? item.jobId.profile.length > 37
                                            ? `${item.jobId.profile.substring(
                                                0,
                                                37
                                              )}...`
                                            : item.jobId.profile
                                          : ""}
                                      </span>

                                      <span
                                        // className="message"
                                        style={{
                                          fontSize: "14px",
                                          lineHeight: "1.57142857",

                                          color: "#666",
                                          display: "flex",
                                        }}
                                      >
                                        {item.messages &&
                                        item.messages.length > 0 ? (
                                          <>
                                            {item.messages[
                                              item.messages.length - 1
                                            ].status === "unread" &&
                                            item.messages[
                                              item.messages.length - 1
                                            ].receiver === user._id ? (
                                              <>
                                                <p
                                                  style={{ fontWeight: "400" }}
                                                >
                                                  {item.messages[
                                                    item.messages.length - 1
                                                  ]?.sender === user._id
                                                    ? "You: "
                                                    : item?.participants[0]
                                                        ?.name +
                                                      ": "}
                                                  {(() => {
                                                    const senderName =
                                                      item.messages[
                                                        item.messages.length - 1
                                                      ]?.sender === user._id
                                                        ? "You: "
                                                        : item?.participants[0]
                                                            ?.name +
                                                          ": ";
                                                    const messageContent =
                                                      item.messages[
                                                        item.messages.length - 1
                                                      ]?.content.replace(
                                                        /@\[(.*?)\]\(.*?\)/g,
                                                        "$1"
                                                      ) || "";

                                                    const totalLength =
                                                      senderName.length +
                                                      messageContent.length;

                                                    if (totalLength > 38) {
                                                      // Calculate how many characters to show from the message
                                                      const availableChars =
                                                        38 - senderName.length;
                                                      return (
                                                        messageContent.slice(
                                                          0,
                                                          availableChars
                                                        ) + "..."
                                                      );
                                                    }
                                                    return messageContent.slice(
                                                      0,
                                                      42
                                                    );
                                                  })()}
                                                </p>
                                                {readMesCount(item.messages) ===
                                                0 ? null : (
                                                  <div
                                                    style={{
                                                      marginLeft: "auto",
                                                      borderRadius: "50px",
                                                      backgroundColor:
                                                        "#0C5CB7",
                                                      color: "white",
                                                      height: "17px",
                                                      width: "17px",
                                                      fontSize: "10px",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {readMesCount(
                                                      item.messages
                                                    )}
                                                  </div>
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                <p
                                                  style={{ fontWeight: "400" }}
                                                >
                                                  {item.messages[
                                                    item.messages.length - 1
                                                  ]?.sender === user._id
                                                    ? "You: "
                                                    : item?.participants[0]
                                                        ?.name +
                                                      ": "}
                                                  {(() => {
                                                    const senderName =
                                                      item.messages[
                                                        item.messages.length - 1
                                                      ]?.sender === user._id
                                                        ? "You: "
                                                        : item?.participants[0]
                                                            ?.name +
                                                          ": ";
                                                    const messageContent =
                                                      item.messages[
                                                        item.messages.length - 1
                                                      ]?.content.replace(
                                                        /@\[(.*?)\]\(.*?\)/g,
                                                        "$1"
                                                      ) || "";

                                                    const totalLength =
                                                      senderName.length +
                                                      messageContent.length;

                                                    if (totalLength > 38) {
                                                      const availableChars =
                                                        38 - senderName.length;
                                                      return (
                                                        messageContent.slice(
                                                          0,
                                                          availableChars
                                                        ) + "..."
                                                      );
                                                    }
                                                    return messageContent.slice(
                                                      0,
                                                      42
                                                    );
                                                  })()}
                                                </p>
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          <p style={{ fontWeight: "400" }}>
                                            ...
                                          </p>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <button
                                  className="btn status"
                                  style={{
                                    background: "#eafcff",
                                    color: "#333",
                                    fontSize: "12px",
                                    lineHeight: "1.33333333",
                                    fontWeight: "500",
                                    borderRadius: "4px",
                                    padding: "5px 8px",
                                    marginRight: "8px",
                                  }}
                                >
                                  {item.appliedJobId ? (
                                    <>
                                      {item.appliedJobId.status === "Assignment"
                                        ? "Assigment Received"
                                        : item.appliedJobId.status ===
                                          "Application_Sent"
                                        ? "Application Sent"
                                        : item.appliedJobId.status ===
                                          "NotIntrested"
                                        ? "Not Interested"
                                        : item.appliedJobId.status}
                                    </>
                                  ) : (
                                    <>General</>
                                  )}
                                </button>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>

                    {CompanyCandidatename !== "" && (
                      <div
                        className="chatroom"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          // justifyContent: "space-between",
                          cursor: "default",
                        }}
                      >
                        <div className="sender-area">
                          <div
                            className="profile "
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                              borderTopRightRadius: "5px",
                              cursor: "default",
                            }}
                          >
                            <div>
                              <div
                                className=""
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {CompanyphotoUrl ? (
                                  <img
                                    src={CompanyphotoUrl}
                                    alt="Profile"
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      objectFit: "cover",
                                      borderRadius: "50%",
                                      // border: "2px solid gray",
                                      marginRight: "1rem",
                                    }}
                                    // className="phototodisplayindesktop"
                                    // style={{
                                    //   boxShadow: "0 0 5px rgba(0,0,0,0.2)",
                                    //   border: "4px solid white",
                                    // }}
                                  />
                                ) : (
                                  <div className="">
                                    <div
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        objectFit: "cover",
                                        borderRadius: "50%",
                                        border: "2px solid gray",
                                        marginRight: "1rem",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontSize: "large",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {CompanyCandidatename.charAt(0)}
                                    </div>
                                  </div>
                                )}
                                <div >
                                  <strong
                                    style={{
                                      fontWeight: "600",
                                      fontSize: "16px",
                                      lineHeight: "1.25",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      cursor: "default",
                                    }}
                                  >
                                    {CompanyCandidatename}
                                  </strong>

                                  <span
                                    style={{
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      lineHeight: "1.14285714",
                                      color: "#484848",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      display: "block",
                                      // marginTop: "7px",
                                      cursor: "default",
                                    }}
                                  >
                                    {/* {allRooms.find(
                                  (item) =>
                                    item.roomId === chatActive
                                ).jobId.profile} {' '}
                                 | Chatting
                                with -  {allRooms.find(
                                  (item) =>
                                    item.roomId === chatActive
                                ).participants[0].organisationName} */}
                                    {allRooms.find(
                                      (item) => item.roomId === chatActive
                                    ) &&
                                    allRooms.find(
                                      (item) => item.roomId === chatActive
                                    ).jobId &&
                                    allRooms.find(
                                      (item) => item.roomId === chatActive
                                    ).jobId.profile
                                      ? allRooms.find(
                                          (item) => item.roomId === chatActive
                                        ).jobId.profile
                                      : ""}{" "}
                                    {companyName && (
                                      <>| Chatting with - {companyName}</>
                                    )}
                                    {/* {allRooms.find(
                                      (item) => item.roomId === chatActive
                                    ) &&
                                    allRooms.find(
                                      (item) => item.roomId === chatActive
                                    ).participants &&
                                    allRooms.find(
                                      (item) => item.roomId === chatActive
                                    ).participants[0]
                                      ? allRooms.find(
                                          (item) => item.roomId === chatActive
                                        ).participants[0].organisationName
                                      : ""} */}
                                  </span>
                                  {company?.city && (
                                    <span
                                      style={{
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        lineHeight: "1.14285714",
                                        color: "#484848",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        display: "flex",
                                        alignItems: "flex-start",
                                        cursor: "default",
                                      }}
                                    >
                                      <IoLocation />
                                      {company?.city}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            {allRooms?.find(
                              (item) => item.roomId === chatActive
                            ).appliedJobId ? (
                              <>
                                <div style={{ cursor: "pointer" }}>
                                  <Dropdown
                                    props={allRooms?.find(
                                      (item) => item.roomId === chatActive
                                    )}
                                  />
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div className="message-section my-0 py-0">
                          <div
                            className="conversations "
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              // justifyContent: "flex-end",
                            }}
                          >
                            {history.messages && history.messages.length > 0 ? (
                              <div ref={scroll}>
                                {history.messages.map((items, index) => {
                                  const currentDate = new Date(items.timestamp);

                                  // Check if the date has changed
                                  const showTimestamp =
                                    !lastDisplayedDate ||
                                    lastDisplayedDate.getFullYear() !==
                                      currentDate.getFullYear() ||
                                    lastDisplayedDate.getMonth() !==
                                      currentDate.getMonth() ||
                                    lastDisplayedDate.getDate() !==
                                      currentDate.getDate();

                                  // Calculate time difference between current and previous messages (in minutes)
                                  const timeDifferenceInMinutes =
                                    index > 0
                                      ? (new Date(items.timestamp) -
                                          new Date(
                                            history?.messages[
                                              index - 1
                                            ]?.timestamp
                                          )) /
                                        (1000 * 60)
                                      : Number.MAX_VALUE; // Use large number for the first message

                                  // Show name and image if either different sender or time difference is greater than 2 minutes
                                  const showNameAndImage =
                                    index === 0 ||
                                    history?.messages[index - 1].sender !==
                                      items.sender ||
                                    timeDifferenceInMinutes > 2;

                                  return (
                                    <div key={index}>
                                      {/* Display timestamp only if the date has changed */}
                                      {index > 0 ? (
                                        new Date(
                                          history.messages[index - 1].timestamp
                                        ).toLocaleDateString() !==
                                          new Date(
                                            items.timestamp
                                          ).toLocaleDateString() && (
                                          <div
                                            className="timestamp text-center"
                                            style={{
                                              fontSize: "14px",
                                              padding: "10px 0",
                                              textAlign: "center",
                                              clear: "both",
                                              display: "block",
                                            }}
                                          >
                                            {isToday(
                                              new Date(items.timestamp)
                                            ) ? (
                                              <p
                                                style={{
                                                  fontSize: "14px",
                                                  padding: "10px 0",
                                                  textAlign: "center",
                                                  clear: "both",
                                                }}
                                              >
                                                Today
                                              </p>
                                            ) : (
                                              <p
                                                style={{
                                                  fontSize: "14px",
                                                  padding: "10px 0",
                                                  textAlign: "center",
                                                  clear: "both",
                                                }}
                                              >
                                                {new Date(
                                                  items.timestamp
                                                ).toLocaleDateString("en-GB", {
                                                  day: "2-digit",
                                                  month: "long",
                                                })}
                                              </p>
                                            )}
                                          </div>
                                        )
                                      ) : (
                                        <div
                                          className="timestamp text-center"
                                          style={{
                                            fontSize: "14px",
                                            padding: "10px 0",
                                            textAlign: "center",
                                            clear: "both",
                                            display: "block",
                                          }}
                                        >
                                          {isToday(
                                            new Date(items.timestamp)
                                          ) ? (
                                            "Today"
                                          ) : (
                                            <div
                                              style={{
                                                fontSize: "14px",
                                                padding: "10px 0",
                                                textAlign: "center",
                                                clear: "both",
                                              }}
                                            >
                                              {new Date(
                                                items.timestamp
                                              ).toLocaleDateString("en-GB", {
                                                day: "2-digit",
                                                month: "long",
                                              })}
                                            </div>
                                          )}
                                        </div>
                                      )}

                                      {/* <p
                                      className={
                                        items.sender === user._id
                                          ? 'text-end mr-5'
                                          : 'text-start ml-5'
                                      }
                                      style={{
                                        fontSize: '12px',
                                        color: 'gray',
                                        marginBottom: '-20px',
                                      }}
                                    >
                                      {getTime(items.timestamp)}
                                    </p> */}
                                      <div
                                        style={{
                                          display: "flex",
                                          // alignItems: "center",
                                          marginTop: showNameAndImage
                                            ? "1rem"
                                            : "0rem",
                                        }}
                                      >
                                        {showNameAndImage ? (
                                          (
                                            items.sender === user._id
                                              ? CandidatephotoUrl
                                              : CompanyphotoUrl
                                          ) ? (
                                            <img
                                              src={
                                                items.sender === user._id
                                                  ? CandidatephotoUrl
                                                  : CompanyphotoUrl
                                              }
                                              alt="Profile"
                                              style={{
                                                width: "50px",
                                                height: "50px",
                                                objectFit: "cover",
                                                borderRadius: "50%",
                                                // border: "2px solid gray",
                                                marginRight: "1rem",
                                              }}
                                            />
                                          ) : (
                                            <div className="">
                                            <div
                                              style={{
                                                width: "50px",
                                                height: "50px",
                                                objectFit: "cover",
                                                borderRadius: "50%",
                                                border: "2px solid gray",
                                                marginRight: "1rem",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                fontSize: "large",
                                                fontWeight: "600",
                                              }}
                                            >
                                              {(items.sender === user._id
                                                ? CandidateName
                                                : CompanyCandidatename
                                              ).charAt(0)}
                                            </div>
                                            </div>
                                          )
                                        ) : null}
                                        <div
                                          style={
                                            {
                                              // display: "flex",
                                              // flexDirection: "row-reverse",
                                            }
                                          }
                                        >
                                          {showNameAndImage && (
                                            <div
                                              className=""
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <div
                                                className=""
                                                style={{
                                                  fontWeight: "500",
                                                  padding: "5px",
                                                }}
                                              >
                                                {items.sender === user._id
                                                  ? CandidateName
                                                  : CompanyCandidatename}
                                              </div>
                                              &nbsp; &nbsp; &nbsp;
                                              {items.sender === user._id ? (
                                                <div
                                                  style={{
                                                    color: "#888",
                                                    fontSize: "0.8em",
                                                  }}
                                                >
                                                  {getTime(
                                                    items.timestamp
                                                  ).toUpperCase()}
                                                </div>
                                              ) : (
                                                <div
                                                  style={{
                                                    color: "#888",
                                                    fontSize: "0.8em",
                                                  }}
                                                >
                                                  {getTime(
                                                    items.timestamp
                                                  ).toUpperCase()}
                                                </div>
                                              )}
                                            </div>
                                          )}
                                          <div
                                            className={` ${
                                              items.sender === user._id
                                                ? "right"
                                                : "left"
                                            }`}
                                            style={{ maxWidth: "100%" }}
                                          >
                                            <div
                                              style={{
                                                padding: "0px 5px",
                                                overflowWrap: "break-word",
                                                wordWrap: "break-word",
                                                wordBreak: "break-word",
                                                hyphens: "auto",

                                                fontSize: "14px",
                                                lineHeight: "1.5",
                                                marginLeft: showNameAndImage
                                                  ? "0"
                                                  : "6rem",
                                              }}
                                            >
                                              <span
                                                dangerouslySetInnerHTML={{
                                                  __html: wrapLinks(
                                                    (items.content || "")
                                                      .trim()
                                                      .replace(/\n/g, "<br />")
                                                      .replace(
                                                        /@\[(.*?)\]\(.*?\)/g,
                                                        "$1"
                                                      )
                                                  ),
                                                }}
                                              ></span>
                                              {/* <div style={{ textAlign: "end" }}>
                                            {items.sender === user._id ? (
                                              <PiChecks
                                                style={{
                                                  marginRight: "-10px",
                                                  marginBottom: "-10px",
                                                  fontSize: "16px",
                                                }}
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </div> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>

                          <div
                            ref={parentDivRef}
                            className="send-message-box"
                            style={{ maxHeight: "250px" }}
                          >
                            <div
                              className="inputgroupbox"
                              style={{
                                width: "100%",
                                flex: "9",
                                position: "relative",
                                borderRadius: "3px",
                                display: "flex",
                                justifyItems: "space-between",
                                alignItems: "center",
                                paddingRight: "10px",
                              }}
                            >
                              {/* <MentionsInput
                                inputRef={textareaRef}
                                value={chatMessage}
                                onChange={handleChange}
                                onKeyDown={handleKeyDown}
                                placeholder="Write a message...."
                                style={mentionstyle}
                                // className="custom-mentions-input"
                              >
                                <Mention
                                  trigger="@"
                                  data={allRooms
                                    .find((e) => e?._id === history?._id)
                                    ?.participants.map((participant) => ({
                                      id: participant?._id, // Assuming each participant has an _id
                                      display: participant?.name, // Display name of the participant
                                    }))}
                                  // className="custom-mention"
                                  style={{ backgroundColor: "#daf4fa" }}
                                />
                              </MentionsInput> */}
                              <textarea
                                ref={textareaRef}
                                value={chatMessage}
                                onChange={handleChange}
                                onKeyDown={handleKeyDown}
                                placeholder="Write a message...."
                                style={{
                                  fontSize: "15px",
                                  height: "42px",
                                  padding: "8px 31px 8px 11px",
                                  width: "100%",
                                  outline: "none",
                                  borderRadius: "5px",
                                  border: "solid 1px #d6d6d6",
                                  resize: "none", // Prevent resizing
                                  overflowY: "auto", // Add scrollbar when needed
                                }}
                              />
                              {/* <ChatUserModel
                                showmodel={showmodel}
                                mentionList={allRooms.find(
                                  (e) => e._id === history?._id
                                )}
                                mentionPosition={cord}
                                handleMentionClick={handleMentionClick}
                              /> */}
                            </div>

                            <button
                              type="button"
                              onClick={() =>
                                sendMessage(
                                  history.participants[0] == user._id
                                    ? history.participants[1]
                                    : history.participants[0],
                                  history.jobId
                                )
                              }
                              className="btn btnsend"
                              style={{
                                backgroundColor:
                                  chatMessage.length > 0 ? "#5db2f7" : "#bbb",
                              }}
                            >
                              Send
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="recent-chats" style={{ width: "35%" }}>
                      {/* <div
                      id="conversation_list_header"
                      style={{
                        padding: '25px',
                        border: '1px solid #f0eeee',
                        borderTopLeftRadius: '5px',
                        // display: 'flex',
                        // justifyContent: 'center',
                      }}
                      className="student"
                    >
                      <div
                        className="summary_toggle"
                        style={getActiveButtonStyle()}
                      >
                        <button
                          type="button"
                          className={`switch ${
                            activeTab === 'all' ? 'active' : ''
                          }`}
                          onClick={ReadMess}
                        >
                          All messages
                        </button>
                        <button
                          type="button"
                          className={`switch ${
                            activeTab === 'unread' ? 'active' : ''
                          }`}
                          onClick={unRead}
                        >
                          Unread ({unreadMsg})
                        </button>
                      </div>
                    </div>*/}

                      <div
                        className="chats text-center"
                        style={{ width: "100%" }}
                      >
                        <RiChatOffLine
                          className="mx-auto mt-5"
                          style={{ fontSize: "50px", color: "#5db2f7" }}
                        />
                        <p
                          style={{
                            fontSize: "20px",
                            fontWeight: "400",
                            color: "gray",
                          }}
                          className="mt-2 text-center"
                        >
                          No chat available!!
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {/************************MOBILE VIEW*****************************/}

      <div className="ChatMobView">
        <div
          className="message-student  pb-0"
          style={{
            backgroundColor: "#F8F8F8",
            borderRadius: "5px",
            padding: "40px 10px",
            maxWidth: "1200px",
            margin: "0 auto",
          }}
        >
          <div className="chat-box1" style={{ overflow: "scroll" }}>
            {loader ? (
              <>
                <div className="recent-chats" style={{ width: "100%" }}>
                  <div className="chats text-center" style={{ width: "100%" }}>
                    <div
                      class="spinner-border "
                      role="status"
                      style={{
                        width: "27px",
                        height: "27px",
                        marginTop: "20px",
                        color: "#5db2f7",
                      }}
                    ></div>
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "400",
                        color: "gray",
                      }}
                      className=" text-center"
                    >
                      Loading...
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <>
                {viewMessage ? (
                  <>
                    {CompanyCandidatename !== "" && (
                      <div
                        className="chatroom"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          // justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "white",
                            marginBottom: "-10px",
                            color: "#0077e4",
                            cursor: "pointer",
                          }}
                          onClick={() => setViewMesage(false)}
                        >
                          <p
                            className="mt-1  ml-3"
                            style={{ fontSize: "14px" }}
                          >
                            {" "}
                            <MdKeyboardBackspace
                              className="ml-1 mb-1"
                              style={{ fontSize: "15px" }}
                            />{" "}
                            Back
                          </p>
                        </div>
                        <div className="sender-area">
                          <div
                            className="profile "
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                              borderTopRightRadius: "5px",
                            }}
                          >
                            <div className="">
                              <div
                                className=""
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {CompanyphotoUrl ? (
                                  <img
                                    src={CompanyphotoUrl}
                                    alt="Profile"
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      objectFit: "cover",
                                      borderRadius: "50%",
                                      // border: "2px solid gray",
                                      marginRight: "1rem",
                                    }}
                                    // className="phototodisplayindesktop"
                                    // style={{
                                    //   boxShadow: "0 0 5px rgba(0,0,0,0.2)",
                                    //   border: "4px solid white",
                                    // }}
                                  />
                                ) : (
                                  <div className="">
                                    <div
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        objectFit: "cover",
                                        borderRadius: "50%",
                                        border: "2px solid gray",
                                        marginRight: "1rem",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontSize: "large",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {CompanyCandidatename.charAt(0)}
                                    </div>
                                  </div>
                                )}
                                <div>
                                  <strong
                                    style={{
                                      fontWeight: "600",
                                      fontSize: "16px",
                                      lineHeight: "1.25",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {CompanyCandidatename}
                                  </strong>

                                  <Tooltip
                                    title={`${
                                      allRooms.find(
                                        (item) => item.roomId === chatActive
                                      )?.jobId?.profile || ""
                                    }${
                                      companyName
                                        ? ` | Chatting with - ${companyName}`
                                        : ""
                                    }`}
                                    arrow
                                  >
                                    <span
                                      style={{
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        lineHeight: "1.14285714",
                                        color: "#484848",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        display: "block",
                                        marginTop: "7px",
                                      }}
                                    >
                                      {(() => {
                                        const profile =
                                          allRooms.find(
                                            (item) => item.roomId === chatActive
                                          )?.jobId?.profile || "";
                                        const companyNameDisplay = companyName
                                          ? ` | Chatting with - ${companyName}`
                                          : "";
                                        const maxChars = 28;

                                        // Combine profile and companyName
                                        const combined = `${profile}${companyNameDisplay}`;

                                        // Truncate the combined string to fit within 28 characters
                                        return combined.length > maxChars
                                          ? combined.slice(0, maxChars - 3) +
                                              "..."
                                          : combined;
                                      })()}
                                    </span>
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                            {allRooms.find((item) => item.roomId === chatActive)
                              .appliedJobId ? (
                              <>
                                <div style={{}}>
                                  <Dropdown
                                    props={allRooms.find(
                                      (item) => item.roomId === chatActive
                                    )}
                                  />
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div className="message-section my-0 py-0">
                          <div className="conversations py-0 my-0">
                            {history.messages && history.messages.length > 0 ? (
                              <div ref={scroll}>
                                {history.messages.map((items, index) => {
                                  const currentDate = new Date(items.timestamp);

                                  // Check if the date has changed
                                  const showTimestamp =
                                    !lastDisplayedDate ||
                                    lastDisplayedDate.getFullYear() !==
                                      currentDate.getFullYear() ||
                                    lastDisplayedDate.getMonth() !==
                                      currentDate.getMonth() ||
                                    lastDisplayedDate.getDate() !==
                                      currentDate.getDate();

                                  // Calculate time difference between current and previous messages (in minutes)
                                  const timeDifferenceInMinutes =
                                    index > 0
                                      ? (new Date(items.timestamp) -
                                          new Date(
                                            history?.messages[
                                              index - 1
                                            ]?.timestamp
                                          )) /
                                        (1000 * 60)
                                      : Number.MAX_VALUE; // Use large number for the first message

                                  // Show name and image if either different sender or time difference is greater than 2 minutes
                                  const showNameAndImage =
                                    index === 0 ||
                                    history?.messages[index - 1].sender !==
                                      items.sender ||
                                    timeDifferenceInMinutes > 2;

                                  return (
                                    <div key={index}>
                                      {/* Display timestamp only if the date has changed */}
                                      {index > 0 ? (
                                        new Date(
                                          history.messages[index - 1].timestamp
                                        ).toLocaleDateString() !==
                                          new Date(
                                            items.timestamp
                                          ).toLocaleDateString() && (
                                          <div
                                            className="timestamp text-center"
                                            style={{
                                              fontSize: "14px",
                                              padding: "10px 0",
                                              textAlign: "center",
                                              clear: "both",
                                              display: "block",
                                            }}
                                          >
                                            {isToday(
                                              new Date(items.timestamp)
                                            ) ? (
                                              <p
                                                style={{
                                                  fontSize: "14px",
                                                  padding: "10px 0",
                                                  textAlign: "center",
                                                  clear: "both",
                                                }}
                                              >
                                                Today
                                              </p>
                                            ) : (
                                              <p
                                                style={{
                                                  fontSize: "14px",
                                                  padding: "10px 0",
                                                  textAlign: "center",
                                                  clear: "both",
                                                }}
                                              >
                                                {new Date(
                                                  items.timestamp
                                                ).toLocaleDateString("en-GB", {
                                                  day: "2-digit",
                                                  month: "long",
                                                })}
                                              </p>
                                            )}
                                          </div>
                                        )
                                      ) : (
                                        <div
                                          className="timestamp text-center"
                                          style={{
                                            fontSize: "14px",
                                            padding: "10px 0",
                                            textAlign: "center",
                                            clear: "both",
                                            display: "block",
                                          }}
                                        >
                                          {isToday(
                                            new Date(items.timestamp)
                                          ) ? (
                                            "Today"
                                          ) : (
                                            <div
                                              style={{
                                                fontSize: "14px",
                                                padding: "10px 0",
                                                textAlign: "center",
                                                clear: "both",
                                              }}
                                            >
                                              {new Date(
                                                items.timestamp
                                              ).toLocaleDateString("en-GB", {
                                                day: "2-digit",
                                                month: "long",
                                              })}
                                            </div>
                                          )}
                                        </div>
                                      )}

                                      {/* <p
                                      className={
                                        items.sender === user._id
                                          ? 'text-end mr-5'
                                          : 'text-start ml-5'
                                      }
                                      style={{
                                        fontSize: '12px',
                                        color: 'gray',
                                        marginBottom: '-20px',
                                      }}
                                    >
                                      {getTime(items.timestamp)}
                                    </p> */}
                                      <div
                                        style={{
                                          display: "flex",
                                          // alignItems: "center",
                                          marginTop: showNameAndImage
                                            ? "1rem"
                                            : "0rem",
                                        }}
                                      >
                                        {showNameAndImage ? (
                                          (
                                            items.sender === user._id
                                              ? CandidatephotoUrl
                                              : CompanyphotoUrl
                                          ) ? (
                                            <img
                                              src={
                                                items.sender === user._id
                                                  ? CandidatephotoUrl
                                                  : CompanyphotoUrl
                                              }
                                              alt="Profile"
                                              style={{
                                                width: "50px",
                                                height: "50px",
                                                objectFit: "cover",
                                                borderRadius: "50%",
                                                // border: "2px solid gray",
                                                marginRight: "1rem",
                                              }}
                                            />
                                          ) : (
                                            <div className="">
                                            <div
                                              style={{
                                                width: "50px",
                                                height: "50px",
                                                objectFit: "cover",
                                                borderRadius: "50%",
                                                border: "2px solid gray",
                                                marginRight: "1rem",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                fontSize: "large",
                                                fontWeight: "600",
                                              }}
                                            >
                                              {(items.sender === user._id
                                                ? CandidateName
                                                : CompanyCandidatename
                                              ).charAt(0)}
                                            </div>
                                            </div>
                                          )
                                        ) : null}
                                        <div
                                          style={
                                            {
                                              // display: "flex",
                                              // flexDirection: "row-reverse",
                                            }
                                          }
                                        >
                                          {showNameAndImage && (
                                            <div
                                              className=""
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <div
                                                className=""
                                                style={{
                                                  fontWeight: "500",
                                                  padding: "5px",
                                                }}
                                              >
                                                {items.sender === user._id
                                                  ? CandidateName
                                                  : CompanyCandidatename}
                                              </div>
                                              &nbsp; &nbsp; &nbsp;
                                              {items.sender === user._id ? (
                                                <div
                                                  style={{
                                                    color: "#888",
                                                    fontSize: "0.8em",
                                                  }}
                                                >
                                                  {getTime(
                                                    items.timestamp
                                                  ).toUpperCase()}
                                                </div>
                                              ) : (
                                                <div
                                                  style={{
                                                    color: "#888",
                                                    fontSize: "0.8em",
                                                  }}
                                                >
                                                  {getTime(
                                                    items.timestamp
                                                  ).toUpperCase()}
                                                </div>
                                              )}
                                            </div>
                                          )}
                                          <div
                                            className={` ${
                                              items.sender === user._id
                                                ? "right"
                                                : "left"
                                            }`}
                                            style={{ maxWidth: "100%" }}
                                          >
                                            <div
                                              style={{
                                                padding: "0px 5px",
                                                overflowWrap: "break-word",
                                                wordWrap: "break-word",
                                                wordBreak: "break-word",
                                                hyphens: "auto",

                                                fontSize: "14px",
                                                lineHeight: "1.5",
                                                marginLeft: showNameAndImage
                                                  ? "0"
                                                  : "6rem",
                                              }}
                                            >
                                              <span
                                                dangerouslySetInnerHTML={{
                                                  __html: wrapLinks(
                                                    (items.content || "")
                                                      .trim()
                                                      .replace(/\n/g, "<br />")
                                                      .replace(
                                                        /@\[(.*?)\]\(.*?\)/g,
                                                        "$1"
                                                      )
                                                  ),
                                                }}
                                              ></span>
                                              {/* <div style={{ textAlign: "end" }}>
                                            {items.sender === user._id ? (
                                              <PiChecks
                                                style={{
                                                  marginRight: "-10px",
                                                  marginBottom: "-10px",
                                                  fontSize: "16px",
                                                }}
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </div> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>

                          <div
                            // ref={parentDivRef}
                            className="send-message-box"
                            style={{ maxHeight: "250px" }}
                          >
                            <div
                              ref={parentDivRef}
                              className="inputgroupbox"
                              style={{
                                width: "100%",
                                position: "relative",
                                borderRadius: "3px",
                                overflowY: "auto", // Add scrolling to the input group
                                maxHeight: "200px", // Set a max height
                                paddingRight: "10px",
                              }}
                            >
                              {/* <MentionsInput
                                inputRef={textareaRef}
                                value={chatMessage}
                                onChange={handleChange}
                                onKeyDown={handleKeyDown}
                                placeholder="Write a message...."
                                style={mentionstyle}
                                // className="custom-mentions-input"
                              >
                                <Mention
                                  trigger="@"
                                  data={allRooms
                                    .find((e) => e?._id === history?._id)
                                    ?.participants.map((participant) => ({
                                      id: participant?._id, // Assuming each participant has an _id
                                      display: participant?.name, // Display name of the participant
                                    }))}
                                  // className="custom-mention"
                                  style={{ backgroundColor: "#daf4fa" }}
                                />
                              </MentionsInput> */}
                              <textarea
                                ref={textareaRef}
                                value={chatMessage}
                                onChange={handleChange}
                                onKeyDown={handleKeyDown}
                                placeholder="Write a message...."
                                style={{
                                  fontSize: "15px",
                                  height: "42px",
                                  padding: "8px 31px 8px 11px",
                                  width: "100%",
                                  outline: "none",
                                  borderRadius: "5px",
                                  border: "solid 1px #d6d6d6",
                                  resize: "none", // Prevent resizing
                                  overflowY: "auto", // Add scrollbar when needed
                                }}
                              />
                            </div>

                            <button
                              type="button"
                              onClick={() =>
                                sendMessage(
                                  history.participants[0] == user._id
                                    ? history.participants[1]
                                    : history.participants[0],
                                  history.jobId
                                )
                              }
                              className="btn btnsend"
                              style={{
                                backgroundColor:
                                  chatMessage.length > 0 ? "#5db2f7" : "#bbb",
                              }}
                            >
                              Send
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <div
                        className=""
                        style={{
                          padding: "10px 0 0 0",
                          boxShadow: "0 2px 6px rgba(0, 0, 0, 0.12)",
                          display: "flex",
                          borderTopLeftRadius: "6px",
                          // borderBottomLeftRadius: "6px", // This is commented out
                          flexDirection: "column",
                          alignItems: "center",
                          borderRight: "1px solid #f0eeee",
                          borderBottom: "1px solid rgb(224, 224, 224)",
                        }}
                      >
                        <div className="" style={{ width: "100%" }}>
                          <div className="" style={{ margin: "0px auto" }}>
                            <div
                              className=""
                              style={{
                                fontSize: "xx-large",
                                fontWeight: "500",
                                margin: "1rem 2rem",
                              }}
                            >
                              Messages
                            </div>

                            <div
                              className="sliderSearch"
                              style={{
                                display: "flex",
                                border: "1px solid #ddd",
                                marginTop: "20px",
                                width: "90%",
                                margin: "0 auto 16px",
                                borderRadius: "3px",
                                padding: "7px 11px",
                                alignItems: "center",
                                position: "relative",
                                cursor: "text",
                                backgroundColor: "#fff",
                              }}
                            >
                              <LuSearch
                                style={{
                                  fontSize: "24px",
                                  color: "#484848",
                                  fontWeight: "400",
                                }}
                              />
                              <input
                                type="text"
                                placeholder="Search "
                                value={search}
                                onChange={(e) => {
                                  handleSearch(e);
                                  setSearch(e.target.value);
                                }}
                                style={{
                                  fontSize: "14px",
                                  lineHeight: "1.14285714",
                                  fontWeight: "400",
                                  border: "0",
                                  outline: "0",
                                  flex: "1",
                                  padding: "0 8px",
                                  height: "24px",
                                  color: "#484848",
                                }}
                              />

                              {search && (
                                <RxCross1
                                  height={24}
                                  width={24}
                                  fill="#484848"
                                  fontSize={20}
                                  cursor={"pointer"}
                                  onClick={() => {
                                    setSearch("");
                                    handleSearch({ target: { value: "" } });
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="mb-4"></div>
                      </div>
                      {allRooms && allRooms.length > 0 ? (
                        <>
                          <div className="recent-chats">
                            <div className="chats" ref={scroll2}>
                              {allRooms
                                .filter((item) =>
                                  item.participants[0]?.name
                                    ?.toLowerCase()
                                    .includes(search.toLowerCase())
                                )
                                .map((item, index) => (
                                  <div key={index} className="wholeBox">
                                    <div
                                      onClick={() => {
                                        joinRoom(
                                          item.roomId,
                                          item.participants[0],
                                          item.participants[1]
                                        );
                                        setViewMesage(true);
                                      }}
                                      key={index}
                                      href="#"
                                      className="chat"
                                    >
                                      <div style={{}}>
                                        <div style={{ display: "flex" }}>
                                          {item.participants[0]?.avatar?.url ||
                                          item.participants[0]
                                            ?.organisationAvatar?.url ? (
                                            <div className="">
                                              <img
                                                src={
                                                  item.participants[0]?.avatar
                                                    ?.url ||
                                                  item.participants[0]
                                                    ?.organisationAvatar?.url
                                                }
                                                alt="Profile"
                                                style={{
                                                  width: "50px",
                                                  height: "50px",
                                                  objectFit: "cover",
                                                  borderRadius: "50%",
                                                  // border: "2px solid gray",
                                                  marginRight: "1rem",
                                                }}
                                                // className="phototodisplayindesktop"
                                                // style={{
                                                //   boxShadow: "0 0 5px rgba(0,0,0,0.2)",
                                                //   border: "4px solid white",
                                                // }}
                                              />
                                            </div>
                                          ) : (
                                            <div className="">
                                              <div
                                                style={{
                                                  width: "50px",
                                                  height: "50px",
                                                  objectFit: "cover",
                                                  borderRadius: "50%",
                                                  border: "2px solid gray",
                                                  marginRight: "1rem",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                  fontSize: "large",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                {item.participants[0].name.charAt(
                                                  0
                                                )}
                                              </div>
                                            </div>
                                          )}
                                          <div
                                            className=""
                                            style={{ width: "100%" }}
                                          >
                                            <div
                                              className=""
                                              style={{ display: "flex" }}
                                            >
                                              <span
                                                style={{
                                                  fontWeight: "600",
                                                  fontSize: "16px",
                                                  lineHeight: "1.25",
                                                  textOverflow: "ellipsis",
                                                  whiteSpace: "nowrap",
                                                  overflow: "hidden",
                                                  color: "#333",
                                                }}
                                              >
                                                {item.participants[0].name}
                                              </span>

                                              <div
                                                style={{
                                                  paddingLeft: "24px",
                                                  marginLeft: "auto",
                                                  display: "flex",
                                                  flexShrink: "0",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: "12px",
                                                    lineHeight: "1.33333333",
                                                    fontWeight: "500",
                                                    textAlign: "right",
                                                    color: "#8a8a8a",
                                                  }}
                                                >
                                                  {item.messages &&
                                                  item.messages.length > 0 ? (
                                                    <>
                                                      {item.messages[
                                                        item.messages.length - 1
                                                      ].timestamp.slice(8, 10) +
                                                        "/" +
                                                        item.messages[
                                                          item.messages.length -
                                                            1
                                                        ].timestamp.slice(
                                                          5,
                                                          7
                                                        ) +
                                                        "/" +
                                                        item.messages[
                                                          item.messages.length -
                                                            1
                                                        ].timestamp.slice(0, 4)}
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </span>
                                              </div>
                                            </div>

                                            <span
                                              style={{
                                                fontSize: "14px",
                                                lineHeight: "1.57142857",
                                                fontWeight: "400",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                color: "#8A8A8A",
                                                padding: "2px 0 0",
                                              }}
                                              // className="message"
                                            >
                                              {item.jobId && item.jobId.profile
                                                ? item.jobId.profile.length > 27
                                                  ? `${item.jobId.profile.substring(
                                                      0,
                                                      27
                                                    )}...`
                                                  : item.jobId.profile
                                                : ""}
                                            </span>

                                            <span
                                              // className="message"
                                              style={{
                                                fontSize: "14px",
                                                lineHeight: "1.57142857",

                                                color: "#666",
                                                display: "flex",
                                              }}
                                            >
                                              {item.messages &&
                                              item.messages.length > 0 ? (
                                                <>
                                                  {item.messages[
                                                    item.messages.length - 1
                                                  ].status === "unread" &&
                                                  item.messages[
                                                    item.messages.length - 1
                                                  ].receiver === user._id ? (
                                                    <>
                                                      <p
                                                        style={{
                                                          fontWeight: "400",
                                                        }}
                                                      >
                                                        {item.messages[
                                                          item.messages.length -
                                                            1
                                                        ]?.sender === user._id
                                                          ? "You: "
                                                          : item
                                                              ?.participants[0]
                                                              ?.name +
                                                            ": "}
                                                        {(() => {
                                                          const senderName =
                                                            item.messages[
                                                              item.messages
                                                                .length - 1
                                                            ]?.sender ===
                                                            user._id
                                                              ? "You: "
                                                              : item
                                                                  ?.participants[0]
                                                                  ?.name +
                                                                ": ";
                                                          const messageContent =
                                                            item.messages[
                                                              item.messages
                                                                .length - 1
                                                            ]?.content.replace(
                                                              /@\[(.*?)\]\(.*?\)/g,
                                                              "$1"
                                                            ) || "";

                                                          const totalLength =
                                                            senderName.length +
                                                            messageContent.length;

                                                          if (
                                                            totalLength > 27
                                                          ) {
                                                            // Calculate how many characters to show from the message
                                                            const availableChars =
                                                              27 -
                                                              senderName.length;
                                                            return (
                                                              messageContent.slice(
                                                                0,
                                                                availableChars
                                                              ) + "..."
                                                            );
                                                          }
                                                          return messageContent.slice(
                                                            0,
                                                            30
                                                          );
                                                        })()}
                                                      </p>
                                                      {readMesCount(
                                                        item.messages
                                                      ) === 0 ? null : (
                                                        <div
                                                          style={{
                                                            marginLeft: "auto",
                                                            borderRadius:
                                                              "50px",
                                                            backgroundColor:
                                                              "#0C5CB7",
                                                            color: "white",
                                                            height: "17px",
                                                            width: "17px",
                                                            fontSize: "10px",
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          {readMesCount(
                                                            item.messages
                                                          )}
                                                        </div>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      <p
                                                        style={{
                                                          fontWeight: "400",
                                                        }}
                                                      >
                                                        {item.messages[
                                                          item.messages.length -
                                                            1
                                                        ]?.sender === user._id
                                                          ? "You: "
                                                          : item
                                                              ?.participants[0]
                                                              ?.name +
                                                            ": "}
                                                        {(() => {
                                                          const senderName =
                                                            item.messages[
                                                              item.messages
                                                                .length - 1
                                                            ]?.sender ===
                                                            user._id
                                                              ? "You: "
                                                              : item
                                                                  ?.participants[0]
                                                                  ?.name +
                                                                ": ";
                                                          const messageContent =
                                                            item.messages[
                                                              item.messages
                                                                .length - 1
                                                            ]?.content.replace(
                                                              /@\[(.*?)\]\(.*?\)/g,
                                                              "$1"
                                                            ) || "";

                                                          const totalLength =
                                                            senderName.length +
                                                            messageContent.length;

                                                          if (
                                                            totalLength > 28
                                                          ) {
                                                            const availableChars =
                                                              28 -
                                                              senderName.length;
                                                            return (
                                                              messageContent.slice(
                                                                0,
                                                                availableChars
                                                              ) + "..."
                                                            );
                                                          }
                                                          return messageContent.slice(
                                                            0,
                                                            30
                                                          );
                                                        })()}
                                                      </p>
                                                    </>
                                                  )}
                                                </>
                                              ) : (
                                                <p
                                                  style={{ fontWeight: "400" }}
                                                >
                                                  ...
                                                </p>
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      </div>

                                      {/* <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontWeight: "600",
                                        fontSize: "16px",
                                        lineHeight: "1.25",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        color: "#333",
                                      }}
                                    >
                                      {item.participants[0].organisationName}
                                    </span>

                                    <div
                                      style={{
                                        paddingLeft: "24px",
                                        marginLeft: "auto",
                                        display: "flex",
                                        flexShrink: "0",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          lineHeight: "1.33333333",
                                          fontWeight: "500",
                                          textAlign: "right",
                                          color: "#8a8a8a",
                                        }}
                                      >
                                        {item.messages &&
                                        item.messages.length > 0 ? (
                                          <>
                                            {item.messages[
                                              item.messages.length - 1
                                            ].timestamp.slice(8, 10) +
                                              "/" +
                                              item.messages[
                                                item.messages.length - 1
                                              ].timestamp.slice(5, 7) +
                                              "/" +
                                              item.messages[
                                                item.messages.length - 1
                                              ].timestamp.slice(0, 4)}
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </span>
                                    </div>
                                  </div>

                                  <span
                                    style={{
                                      fontSize: "14px",
                                      lineHeight: "1.57142857",
                                      fontWeight: "400",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      color: "#8A8A8A",
                                      padding: "2px 0 0",
                                    }}
                                    // className="message"
                                  >
                                    {item.jobId && item.jobId.profile
                                      ? item.jobId.profile
                                      : ""}
                                  </span>

                                  <span
                                    // className="message"
                                    style={{
                                      fontSize: "14px",
                                      lineHeight: "1.57142857",

                                      color: "#666",
                                      display: "flex",
                                    }}
                                  >
                                    {item.messages &&
                                    item.messages.length > 0 ? (
                                      <>
                                        {item.messages[item.messages.length - 1]
                                          .status == "unread" &&
                                        item.messages[item.messages.length - 1]
                                          .receiver == user._id ? (
                                          <>
                                            <p style={{ fontWeight: "400" }}>
                                              {" "}
                                              {item.messages[
                                                item.messages.length - 1
                                              ]?.content?.slice(0, 42) || ""}
                                            </p>
                                            {readMesCount(item.messages) ==
                                            0 ? (
                                              <></>
                                            ) : (
                                              <>
                                                <div
                                                  style={{
                                                    marginLeft: "auto",
                                                    borderRadius: "50px",
                                                    backgroundColor: "#0C5CB7",
                                                    color: "white",
                                                    height: "17px",
                                                    width: "17px",
                                                    fontSize: "10px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {readMesCount(item.messages)}
                                                </div>
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            <p style={{ fontWeight: "400" }}>
                                              {" "}
                                              {item.messages[
                                                item.messages.length - 1
                                              ]?.content?.slice(0, 42) || ""}
                                            </p>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <p style={{ fontWeight: "400" }}>...</p>
                                      </>
                                    )}
                                  </span> */}

                                      <button
                                        className="btn status"
                                        style={{
                                          background: "#eafcff",
                                          color: "#333",
                                          fontSize: "12px",
                                          lineHeight: "1.33333333",
                                          fontWeight: "500",
                                          borderRadius: "4px",
                                          padding: "5px 8px",
                                          marginRight: "8px",
                                        }}
                                      >
                                        {item.appliedJobId ? (
                                          <>
                                            {item.appliedJobId.status ===
                                            "Assignment"
                                              ? "Assigment Received"
                                              : item.appliedJobId.status ===
                                                "Application_Sent"
                                              ? "Application Sent"
                                              : item.appliedJobId.status ===
                                                "NotIntrested"
                                              ? "Not Interested"
                                              : item.appliedJobId.status}
                                          </>
                                        ) : (
                                          <>Genral</>
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>

                          {/*end*/}
                        </>
                      ) : (
                        <>
                          <div
                            className="recent-chats"
                            style={{ width: "35%" }}
                          >
                            <div
                              className="chats text-center"
                              style={{ width: "100%" }}
                            >
                              <RiChatOffLine
                                className="mx-auto mt-5"
                                style={{ fontSize: "50px", color: "#5db2f7" }}
                              />
                              <p
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "400",
                                  color: "gray",
                                }}
                                className="mt-2 text-center"
                              >
                                No chat available!!
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default MessageStudent;
