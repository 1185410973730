import React, { useState, useRef, useEffect } from "react";
import "./Jobs.scss";
import "./PreviewJob.scss";
import filterLogo from "../../assets/images/Group.png";
import circle from "../../assets/images/circle.png";
import money from "../../assets/images/money.png";
import calendar from "../../assets/images/calendar.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../Layout/Spinner";
import { useLocation } from "react-router-dom";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "../../actions/userActions";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { MdOutlineCheckCircle } from "react-icons/md";
import { State } from "country-state-city";
import PropagateLoader from "react-spinners/PropagateLoader";
import home from "../../assets/images/home.png";
import game from "../../assets/images/game.png";
import arrowup from "../../assets/images/Arrowup.jpg";
import { SlLocationPin } from "react-icons/sl";
import { BiBriefcaseAlt } from "react-icons/bi";
import { RxCounterClockwiseClock, RxCross1, RxCross2 } from "react-icons/rx";
import { FiUsers } from "react-icons/fi";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Dropdown from "./Dropdown";
import "./Filter.css";
import { RiArrowDownSFill, RiArrowDownSLine } from "react-icons/ri";
import { marks4 } from "../EmployerNew/utils/constants";
import { input } from "@material-tailwind/react";
import citiesData from "../Employer/citiesData.json";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import Popup from "reactjs-popup";
import { determineBgColor, determineColor, timeAgo } from "../../utils/helper";
import ProjectCategorySelector from "../EmployerNew/ProjectCategorySelector";
import ProjectSpecializationSelector from "../EmployerNew/ProjectSpecializationSelector";
function Internships() {
  const { filterType } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [selectedSkill, setSelectedSkill] = useState("");
  const [allskills, setAllSkills] = useState([]);

  const [value, setValue] = useState([1, 100]); // set initial value to 50
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const userType = localStorage.getItem("userType");
  const [job, setJob] = useState();
  const [specializationData, setSpecializationData] = useState([]);
  const [searchTerm2, setSearchTerm2] = useState("");
  const [isTyping, setIsTyping] = useState(false);

  const initial = {
    opportunityType: "Fixed Cost",
    profile: filterType && filterType.startsWith("profile_") ? [filterType.split("_")[1]] : [],
    location:
      filterType && filterType.startsWith("location_") ? [filterType.split("_")[1]] : [],
    jobType: filterType === "workFromHome" ? true : false,
    partTime: false,
    fullTime: false,
    minHourlyBudget: 0,
    startFrom: "",
    duration: [],
    keyword: "",
    freelancer: [],
  };
  const [filter, setFilter] = useState(initial);
  const handleInputChange2 = (newValue) => {
    setSearchTerm2(newValue);
    setIsTyping(newValue.length > 0);
  };
  const handleSkillChange = (selectedOptions) => {
    setSelectedSkill(selectedOptions);
    // setJob({
    //   ...job,
    //   skill: selectedOptions.map((option) => option.value),
    // });
  };

  useEffect(() => {
    async function fetchAllSkills() {
      if (searchTerm2.trim()) {
        try {
          const response = await axios.get(`/api/data/skills`, {
            params: { keyword: searchTerm2 },
          });
          if (Array.isArray(response.data)) {
            setAllSkills(
              response.data.map((skill) => ({ label: skill, value: skill }))
            );
          } else {
            console.error("Unexpected response format:", response.data);
          }
        } catch (error) {
          console.error("Error fetching skills:", error);
        }
      } else {
        setAllSkills([]);
      }
    }
    fetchAllSkills();
  }, [searchTerm2]);
  const numberOfFreelancerNeededData = [
    {
      value: 0,
      label: "More than 4",
    },
    {
      value: 1,
      label: "Upto 4",
    },
  ];
  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 6,
      label: "6",
    },
    {
      value: 8,
      label: "8",
    },
    {
      value: 10,
      label: "10",
    },
    {
      value: 12,
      label: "12",
    },
    {
      value: 14,
      label: "14",
    },
    {
      value: 16,
      label: "16",
    },
    {
      value: 18,
      label: "18",
    },
    {
      value: 20,
      label: "20+",
    },
  ];

  const durationData = [
    {
      value: 0,
      label: "1 Month",
    },
    {
      value: 1,
      label: "2 Month",
    },
    {
      value: 2,
      label: "3 Month",
    },
    {
      value: 3,
      label: "4 Month",
    },
    {
      value: 4,
      label: "5 Month",
    },
  ];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "none",
      borderRadius: "3px",
      boxShadow: "none",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      display: "none",
      borderLeft: "none",
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      paddingRight: 0, // Adjust padding to remove space between text and dropdown icon
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ECFFFF" : "white", // Change hover color here
      color: state.isFocused ? "#3498DB" : "black", // Change hover color here
      "&:hover": {
        backgroundColor: "#ECFFFF", // Change hover color here
        color: "#3498DB",
      },
    }),
  };
  const handleFilter = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      // If the event target is a checkbox, handle its state
      const updatedJobType = [...filter.jobType];
      if (checked) {
        updatedJobType.push(name);
      } else {
        const index = updatedJobType.indexOf(name);
        if (index > -1) {
          updatedJobType.splice(index, 1);
        }
      }

      setFilter((prevFilter) => ({
        ...prevFilter,
        jobType: updatedJobType,
      }));
    } else {
      // For other input types, handle normally
      setFilter({
        ...filter,
        [name]: value,
      });
    }
  };

  /******************************Filter Option End**********************************/

  // SEARCH OPTION :

  const [search, setSearch] = useState({
    title: state && state.title ? state.title : "",
    location: state && state.location ? state.location : "",
  });

  const handleChange = (e) => {
    setSearch({
      ...search,
      [e.target.name]: e.target.value,
    });
  };
  const [totalItem, setTotalItem] = useState(0);

  const handleSearch = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post("/api/user/findInternships", {
        page: currentPage, // Add current page
        pageSize: 25, // Display 25 jobs per page
        filter: filter,
      });

      //console.log(data, "this is find internship");

      const activeJobs = data.jobs.filter((job) => job.active === true);

      setLoading(false);
      setJobData(activeJobs);
      setTotalPages(data.totalPages);
      setTotalItem(data.totalItems);
    } catch (error) {
      setLoading(false);
      // Handle errors more gracefully
      console.error("Error while fetching jobs:", error);
      // Show error message to the user
      toast.error("Failed to fetch jobs. Please try again later.", {
        style: {
          padding: "18px",
        },
      });
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  /*************************Search Option End********************************/

  const handleSliderChange = (event, newValue) => {
    // Update the rangeSalary filter based on slider values
    setFilter({
      ...filter,
      rangeSalary: newValue,
    });
  };

  const dropdownMin = (value) => {
    // Ensure that the minimum value is not greater than the current maximum value
    const newMinValue = Math.min(value, filter.rangeSalary[1]);

    // Update the rangeSalary filter based on slider values
    setFilter({
      ...filter,
      rangeSalary: [newMinValue, filter.rangeSalary[1]],
    });
  };

  const dropdownMax = (value) => {
    // Ensure that the maximum value is not less than the current minimum value
    const newMaxValue = Math.max(value, filter.rangeSalary[0]);

    // Update the rangeSalary filter based on slider values
    setFilter({
      ...filter,
      rangeSalary: [filter.rangeSalary[0], newMaxValue],
    });
  };

  const stepValues = Array.from({ length: 11 }, (_, i) => i * 10 * 1000);

  const formatNumberInK = (value) => {
    if (value >= 1000) {
      return `${(value / 1000).toFixed(1)}k`;
    }
    return value.toString();
  };

  const generateStepLabels = () => {
    return stepValues.map((value) => ({
      value,
      label: "",
    }));
  };

  const filtersBox = useRef(null);

  const toggleFiltersBox = () => {
    filtersBox.current.classList.toggle("open");
  };

  const { user } = useSelector((state) => state.user);

  const [jobData, setJobData] = useState("");
  const [orgData, setOrgData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filled, setFilled] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [isData, setIsData] = useState(false);

  const [profiles, setProfiles] = useState([]);
  useEffect(() => {
    async function getAllProfiles() {
      const response = await axios.get("/api/data/profiles");
      const data = await response.data;
      if (data) setProfiles(data);
    }

    getAllProfiles();
    dispatch(loadUser());
  }, []);

  const [connectCredit, setConnectCredit] = useState(-1);
  const [showModal, setShowModal] = useState(false);
  const getConnectCreditInfo = async () => {
    try {
      const response = await axios.get(
        `/api/employer/getConnectCreditInfomrationofEmployer/${user?._id}`
      );
      setConnectCredit(response.data.connectCredit);
    } catch (error) {
      console.error("Error fetching connectCredit:", error);
    }
  };
  useEffect(() => {
    if (user && user.role === "User") {
      getConnectCreditInfo();
    }
  });
  useEffect(() => {
    getResumeData();
  }, []);
  console.log(connectCredit, "connect credit");
  const handleBuyConnects = () => {
    navigate("/candidatebuyconnects");
    setShowModal(false);
  };

  // useEffect(() => {
  //     handleSearch();
  //     window.scrollTo(0, 0);
  // }, [filter.profile, filter.location, filter.jobType, filter, currentPage]);
  useEffect(() => {
    setCurrentPage(1);
  }, [filter.profile, filter.location, filter.jobType, filter]);

  // Effect to handle search when currentPage or filters change
  useEffect(() => {
    handleSearch();
    window.scrollTo(0, 0);
  }, [currentPage, filter]);

  // OTHER FUNCTION:

  //  const openPreviewPageInNewTab = (state) => {
  //   const data = state.data;
  //   window.open(`/preview/${data.profile.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${data.opportunityType}-at-${data.employer.organisationName.replace(/ /g, "-")}_${data._id}`.toLowerCase());
  // };
  const openPreviewPageInNewTab = (state) => {
    const data = state.data;
    const url = `/preview/${data.profile.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${data.opportunityType
      }-at-${data.employer.organisationName.replace(/ /g, "-")}_${data._id}`.toLowerCase();
    navigate(url);
    // return url;
  };
  const [profiledetail, setprofiledetail] = useState(null);
  const getResumeData = async () => {
    const { data } = await axios.get("/api/user/getResume", {
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      },
    });
    setprofiledetail(data.resume);
  };

  const handleApplyNow = (state) => {
    const data = state.data;
    // console.log(data, "cover later adata");
    let url = "";
    const { phone, city, resume } = user;
    if (
      !phone ||
      !city ||
      !resume ||
      !profiledetail ||
      profiledetail.Education.length === 0 ||
      (profiledetail.Job.length === 0 && profiledetail.Internship.length === 0) ||
      // profiledetail.Training.length === 0 ||
      // profiledetail.Academics.length === 0 ||
      profiledetail.Skills.length === 0 ||
      profiledetail.Portfolio.length === 0 ||
      profiledetail.DocDetails.length === 0
    ) {
      return "/profile";
      // navigate("/profile");
    } else if ((phone || city) && !resume) {
      // navigate("/profile");
      return "/profile";
    } else {
      // navigate("/coverLetterPage", { state: data });
      const jobId = `${data.profile.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${data.opportunityType
        }-at-${data.employer.organisationName.replace(/ /g, "-")}_${data._id}`.toLowerCase();
      const encodedJobId = encodeURIComponent(jobId);
      // navigate(`/coverletterpage/${encodedJobId}`, { state: data });
      if (data.opportunityType === "Job" && connectCredit < 8) {
        setShowModal(true);
      } else if (data.opportunityType === "Fixed Cost" && connectCredit < 4) {
        setShowModal(true);
      } else {
        url = `/coverletterpage/${jobId} `;
        //navigate(`/coverletterpage/${encodedJobId} `,{state:data})
      }
    }

    // let url = `/coverletterpage/987werhwjhrjweghewr`;
    return url;
  };

  const formatNumberWithCommas = (number) => {
    // Convert number to string
    let strNumber = number.toString();

    // Split the string into parts (crores, lakhs, thousands, and hundreds)
    let crores = strNumber.slice(0, -7);
    let lakhs = strNumber.slice(-7, -5);
    let thousands = strNumber.slice(-5, -3);
    let hundreds = strNumber.slice(-3);

    // Join the parts with commas
    return (
      crores + (crores ? "," : "") + lakhs + (lakhs ? "," : "") + thousands + "," + hundreds
    );
  };

  const [heading, setHeading] = useState(false);

  const handleTitle = () => {
    setHeading(!heading);
  };
  const calculateDaysDifference = (startDate) => {
    const start = new Date(startDate);
    const end = new Date();

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = end - start;

    // Convert the difference to days
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    // Set the state with the calculated difference
    return differenceInDays;
  };

  const handleSaveJob = async (jobId) => {
    try {
      const { data } = await axios.get(`/api/user/saveJob/${jobId}`, {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      if (data.success) {
        setFilled(!filled);
        window.location.reload();
      }
    } catch (error) { }
  };

  const handleRemoveJob = async (jobId) => {
    try {
      const { data } = await axios.get(`/api/user/removeJob/${jobId}`, {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });

      if (data.success) {
        setFilled((prevFilled) => !prevFilled);
        window.location.reload();
      }
    } catch (error) { }
  };

  const [viewMore, setViewMore] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const handleCheckBox = (data) => {
    if (filter?.jobType.includes(data)) {
      setFilter((prev) => ({
        ...prev,
        jobType: prev.jobType.filter((i) => i !== data),
      }));
    } else {
      setFilter({ ...filter, jobType: [...filter.jobType, data] });
    }
  };

  const calendarRef = useRef();
  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setOpenCalendar(false);
    }
  };

  const [isSearchCalled, setIsSearchCalled] = useState(false);
  const handleKeywordCall = (e) => {
    e.preventDefault();
    setFilter({ ...filter, keyword: keyword });
    setIsSearchCalled(false);
    setIsData(false);
  };
  const handleSearch2 = async () => {
    setLoading(true);
    setIsSearchCalled(searchValue.trim() !== "");
    // setCurrentPage(1);
    try {
      const response = await axios.post("/api/user/findFilteredInternship", {
        search: {
          profile: searchValue,
        },
        // page: currentPage, // include currentPage in the request
        page: 1, // include currentPage in the request
        pageSize: 25,
      });
      console.log(response.data, " job response data");
      setLoading(false);
      setJobData(response.data?.data);
      setTotalPages(response.data.totalPages);
      setIsData(searchValue.trim() !== "" && response.data?.data.length > 0);
    } catch (error) {
      setLoading(false);
      console.error("Error while fetching job data:", error);
      // Handle errors
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    if (!value.trim()) {
      setIsData(false); // Reset isData to false when searchValue is empty
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch2();
    }
  };
  // useEffect(() => {
  //   const delayDebounceFn = setTimeout(() => {
  //     handleSearch2();
  //   }, 300); // Adjust the delay as needed

  //   return () => clearTimeout(delayDebounceFn);
  // }, [searchValue]);
  useEffect(() => {
    if (searchValue.trim() !== "") {
      const delayDebounceFn = setTimeout(() => {
        handleSearch2();
      }, 300);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchValue]);

  // console.log(jobData, "this is jobs Data");

  return (
    <>
      <div className="container-fluid matched-jobs ">
        <div className="jobbox container ">
          <div className="row RowMargin " style={{ maxWidth: "1050px" }}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                color: "#494949",
                fontSize: "15px",
                marginLeft: "10px",
              }}
            >
              <p
                style={{ marginRight: "4px", cursor: "pointer" }}
                onClick={() => navigate("/")}
              >
                Home
              </p>
              <IoIosArrowForward style={{ marginRight: "4px", marginTop: "3px" }} />
              <p style={{ marginRight: "4px", cursor: "pointer" }}>Fixed Cost</p>
            </div>
            <div
              className=" col-lg-4 col-md-4 col-sm-12 mx-auto"
              style={{ marginTop: "84px", maxWidth: "350px" }}
            >
              <div
                className="filterbox  py-0 adjustfilterboxheight2"
                style={{ height: "83vh", boxShadow: "none" }}
              >
                <div className="filter-container">
                  <div className="title">
                    <img
                      src={filterLogo}
                      alt="Account"
                      style={{ width: "25px" }}
                    />{" "}
                    Filters
                  </div>

                  <div className="body">
                    <div className="filter-category">
                      <div
                      // className="title"
                      // style={{ fontSize: "18px", marginBottom: "35px" }}
                      >
                        Keyword Search
                      </div>

                      <div className="body">
                        {/* Keyword */}
                        <form
                          onSubmit={handleKeywordCall}
                          style={{ display: "flex" }}
                        >
                          <input
                            minLength={3}
                            id="keyword"
                            type="text"
                            placeholder="e.g. Design, Mumbai, Infosys"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                            style={{
                              fontSize: "16px",
                              width: "100%",
                              height: "100%",
                              border: "1px solid #ddd",
                              borderRight: "none",
                              outline: "none",
                              paddingTop: "10px",
                              paddingBottom: "10px",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                              borderBottomLeftRadius: "3px",
                              borderTopLeftRadius: "3px",
                              borderTopRightRadius: "0px",
                              borderBottomRightRadius: "0px",
                            }}
                          />
                          <button
                            type="submit"
                            style={{
                              height: "100%",
                              color: "white",
                              backgroundColor: "#00a5ec",
                              border: "1px solid #00a5ec",
                              paddingTop: "8px",
                              paddingBottom: "7px",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                              borderTopRightRadius: "3px",
                              borderBottomRightRadius: "3px",
                              transition: "all 0.3s",
                            }}
                          >
                            <FaMagnifyingGlass />
                          </button>
                        </form>
                      </div>
                    </div>
                    <div className="filter-category">
                      <div>Project Category</div>
                      <div style={{ width: "100%" }}>
                        <ProjectCategorySelector
                          setSelectedOption={(category) => {
                            setJob((prevJob) => ({
                              ...prevJob,
                              ["projectCategory"]: category?.value,
                            }));
                          }}
                          setSpecializationData={setSpecializationData}
                          resetSpecialization={() => {
                            setJob((prevJob) => ({
                              ...prevJob,
                              ["projectSpecialization"]: "",
                            }));
                          }}
                          selectedData={
                            job?.projectCategory && {
                              label: job?.projectCategory,
                              value: job?.projectCategory,
                            }
                          }
                        />
                      </div>
                    </div>
                    <div className="filter-category">
                      <div>Project Specialization</div>
                      <div style={{ width: "100%" }}>
                        <ProjectSpecializationSelector
                          setSelectedOption={(sp) => {
                            setJob((prevJob) => ({
                              ...prevJob,
                              ["projectSpecialization"]: sp?.value,
                            }));
                          }}
                          optionData={specializationData}
                          selectedData={
                            job?.projectSpecialization && {
                              label: job?.projectSpecialization,
                              value: job?.projectSpecialization,
                            }
                          }
                        />
                      </div>
                    </div>
                    {/* Profile */}
                    <div className="filter-category">
                      <div htmlFor="profile" className="sub-title">
                        Skills{" "}
                        {/* {job.opportunityType != "Job" && (
                        <span style={{ fontSize: "12px", color: "#bbb" }}>
                          (Optional)
                        </span>
                      )} */}
                      </div>
                      <div
                        className=""
                        style={{
                          border: "1px solid #ddd",
                          borderRadius: "3px",
                          padding: "3px 0px",
                        }}
                        onMouseEnter={(e) =>
                        (e.currentTarget.style.border =
                          "1px solid blue")
                        }
                        onMouseLeave={(e) =>
                        (e.currentTarget.style.border =
                          "1px solid #ddd")
                        }
                      >
                        <Select
                          placeholder="e.g. Python"
                          className="select-tag"
                          isMulti
                          value={selectedSkill}
                          onChange={handleSkillChange}
                          options={allskills}
                          onInputChange={handleInputChange2}
                          styles={customStyles}
                          // noOptionsMessage={noOptionsMessage2}
                          menuIsOpen={isTyping}
                        />
                      </div>
                    </div>

                    {/* Profile */}
                    <div className="filter-category">
                      <div>Project type</div>
                      {/* <Dropdown
                        multiple={true}
                        placeholder="e.g. Marketing"
                        category="profile"
                        actual={filter}
                        setActual={setFilter}
                        options={profiles}
                      /> */}
                      <input
                        type="text"
                        placeholder="i.e. Web Developer"
                        value={searchValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown} // Call handleSearch on Enter key press
                      />
                    </div>

                    {/* type */}
                    {/* <div className="checkbox-container">
                      <div className="category d-flex align-items-center gap-3">
                        <input type="checkbox" checked={filter.jobType} onChange={() => setFilter({ ...filter, jobType:!filter.jobType })} />
                        <span>Work from home</span>
                      </div>
                      <div className="category d-flex align-items-center gap-3">
                        <input type="checkbox" checked={filter.partTime} onChange={() => setFilter({ ...filter, partTime: !filter.partTime })} />
                        <span>Part-time</span>
                      </div>
                    <div className="category d-flex align-items-center gap-3">
                        <input type="checkbox" checked={filter.fullTime} onChange={() => setFilter({ ...filter, fullTime: !filter.fullTime })} />
                        <span>Full-time</span>
                      </div>
                    </div> */}
                    <div className="checkbox-container">
                      <div className="category d-flex align-items-center gap-3">
                        <input
                          type="checkbox"
                          checked={filter.jobType}
                          onChange={() => {
                            setFilter({
                              ...filter,
                              jobType: !filter.jobType,
                            });
                            setIsSearchCalled(false);
                            setIsData(false);
                          }}
                        />
                        <span>Work from home</span>
                      </div>
                      <div className="category d-flex align-items-center gap-3">
                        <input
                          type="checkbox"
                          checked={filter.partTime}
                          onChange={() => {
                            setFilter({
                              ...filter,
                              partTime: !filter.partTime,
                            });
                            setIsSearchCalled(false);
                            setIsData(false);
                          }}
                        />
                        <span>Part-time</span>
                      </div>
                      <div className="category d-flex align-items-center gap-3">
                        <input
                          type="checkbox"
                          checked={filter.fullTime}
                          onChange={() => {
                            setFilter({
                              ...filter,
                              fullTime: !filter.fullTime,
                            });
                            setIsSearchCalled(false);
                            setIsData(false);
                          }}
                        />
                        <span>Full-time</span>
                      </div>
                    </div>

                    <div className="filter-category">
                      <label htmlFor="profile" className="sub-title">
                        Number of freelancer needed
                      </label>
                      <Dropdown
                        placeholder="Number of freelancer"
                        multiple={false}
                        category="freelancer"
                        actual={filter}
                        setActual={setFilter}
                        options={numberOfFreelancerNeededData?.map(
                          (i) => i.label
                        )}
                        setIsSearchCalled={setIsSearchCalled}
                        setIsData={setIsData}
                      />
                    </div>
                    <div className="filter-category">
                      <label htmlFor="profile" className="sub-title">
                        Project Duration
                      </label>
                      <Dropdown
                        placeholder="Project Duration"
                        multiple={false}
                        category="duration"
                        actual={filter}
                        setActual={setFilter}
                        options={durationData?.map((i) => i.label)}
                        setIsSearchCalled={setIsSearchCalled}
                        setIsData={setIsData}
                      />
                    </div>
                    {/* Location */}
                    <div className="filter-category">
                      <div>Location</div>
                      <Dropdown
                        multiple={true}
                        placeholder="e.g. Delhi"
                        category="location"
                        actual={filter}
                        setActual={setFilter}
                        setIsSearchCalled={setIsSearchCalled}
                        setIsData={setIsData}
                      // options={citiesData["India"]}
                      />
                    </div>
                    {/* Minimum stipend */}
                    <div
                      className="filter-category"
                      style={{ marginTop: "-9px" }}
                    >
                      <div>
                        Minimum hourly budget / Minimum fixed cost budget
                      </div>
                      <Slider
                        aria-label="Hourly budget"
                        value={filter.minHourlyBudget}
                        getAriaValueText={(value) => value}
                        step={2}
                        marks={marks}
                        min={0}
                        max={20}
                        // onChange={(e, value) => setFilter({ ...filter, salary: value })}
                        onChange={(e, value) => {
                          setFilter({ ...filter, salary: value });
                          setIsSearchCalled(false);
                          setIsData(false);
                        }}
                      />
                    </div>

                    {/* <span onClick={() => setFilter(initial)} className="ml-auto instruction"> */}
                    <span
                      onClick={() => {
                        setFilter(initial);
                        setIsSearchCalled(false);
                        setIsData(false);
                      }}
                      className="ml-auto instruction"
                    >
                      Clear all
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    justifyItems: "center",
                    marginTop: "-15px",
                  }}
                ></div>
              </div>
            </div>

            <div className="col-lg-8 col-md-8 col-sm-12">
              <div className="fixed-header">
                <h3
                  style={{ cursor: "pointer" }}
                  onClick={handleTitle}
                  className="title my-5"
                >
                  {/* {loading? "":totalItem} {filter.jobType?" Work From Home": ""} {filter.profile? filter.profile: ""} Internship {filter.location && filter.location.length > 0 ? " in "+ filter.location: ""} {heading ? <IoIosArrowUp /> : <IoIosArrowDown />}{" "} */}
                  {/* {loading? "":jobData && jobData.length} {filter.jobType?" Work From Home": ""} {isData ? jobData[0].profile : (filter.profile ? filter.profile : "")} Internship {filter.location && filter.location.length > 0 ? " in "+ filter.location: ""} {heading ? <IoIosArrowUp /> : <IoIosArrowDown />}{" "} */}
                  {loading ? "" : isSearchCalled ? jobData?.length : totalItem}{" "}
                  {filter.jobType ? " Work From Home" : ""}{" "}
                  {isData
                    ? jobData[0]?.profile
                    : filter.profile
                      ? filter?.profile
                      : ""}{" "}
                  Fixed Cost Projects{" "}
                  {filter.location && filter.location.length > 0
                    ? " in " + filter.location
                    : ""}{" "}
                  {heading ? <IoIosArrowUp /> : <IoIosArrowDown />}{" "}
                </h3>
                {heading ? (
                  <>
                    <p
                      style={{
                        marginTop: "-20px",
                        fontSize: "13px",
                        color: "#494949",
                        textAlign: "center",
                      }}
                    >
                      Recent Fixed Cost projects and openings across the globe
                    </p>
                  </>
                ) : (
                  <></>
                )}
              </div>

              {loading ? (
                <>
                  <div
                    className="my-auto"
                    style={{ textAlign: "center", marginLeft: "-10px" }}
                  >
                    <p
                      style={{
                        color: "#4B92C8",
                        fontWeight: "500",
                        marginTop: "170px",
                        fontSize: "20px",
                        marginLeft: "17px",
                        marginBottom: "5px",
                      }}
                    >
                      Loading..
                    </p>
                    <PropagateLoader
                      color={"#4B92C8"}
                      loading={true}
                      size={15}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                </>
              ) : (
                <>
                  {jobData && jobData.length != 0 ? (
                    <>
                      <div className="content-below-header">
                        <div className="alljobdetails pb-2">
                          {jobData.map((data, index) => {
                            return (
                              <>
                                <div
                                  key={index}
                                  className="cards my-4"
                                >
                                  <div className="card py-4 px-5">
                                    <div className="cardHeader">
                                      {/*NEW CODE START HERE*/}
                                      <div
                                        className="mt-3  wfm123"
                                        style={{
                                          width: "124px",
                                          borderRadius:
                                            "5px",
                                          fontSize:
                                            "13px",
                                          textAlign:
                                            "center",
                                          fontWeight:
                                            "400",
                                          padding: "3px",
                                          color: "black",
                                          border: "solid 1px #D1D1D1",
                                        }}
                                      >
                                        <img
                                          src={arrowup}
                                          alt=""
                                          style={{
                                            marginRight:
                                              "5px",
                                            height: "17px",
                                            marginTop:
                                              "-4px",
                                          }}
                                        />
                                        Actively hiring
                                      </div>
                                      <div className="row">
                                        <div className="col-md-9 col-8">
                                          <a
                                            style={{
                                              textDecoration:
                                                "none",
                                              color: "black",
                                            }}
                                            href={`/preview/${data.profile
                                              .replace(
                                                /[/?$%@&]/g,
                                                "-"
                                              )
                                              .replace(
                                                / /g,
                                                "-"
                                              )}-${data.opportunityType
                                              }-at-${data.employer.organisationName.replace(
                                                / /g,
                                                "-"
                                              )}_${data._id
                                              }`.toLowerCase()}
                                          >
                                            <h3
                                              style={{
                                                fontSize:
                                                  "19px",
                                                textAlign:
                                                  "start",
                                                cursor: "pointer",
                                              }}
                                              className="title pt-3"
                                            >
                                              {
                                                data.profile
                                              }
                                            </h3>
                                          </a>
                                          {/* <h4
                                            onClick={() =>
                                              navigate("/company-detail", {
                                                state: data.employer,
                                              })
                                            }
                                            style={{
                                              fontSize: "15px",
                                              color: "gray",
                                              cursor: "pointer",
                                            }}
                                            className="companyname pt-3"
                                          >
                                            {data.employer.organisationName}
                                          </h4> */}
                                        </div>
                                        {/* <div className="col-3 text-end ">
                                          {data.employer.organisationAvatar && (
                                            <img
                                              src={
                                                data.employer.organisationAvatar
                                                  .url
                                              }
                                              alt=""
                                              className="OrgLogoAvatar"
                                            />
                                          )}
                                        </div> */}
                                      </div>

                                      <div
                                        className="content mb-5"
                                      // style={{ marginTop: "20px" }}
                                      >
                                        {/* <span>
                                          {data.jobType === "Remote" ? (
                                            <>
                                              <img
                                                src={home}
                                                alt="Engineering"
                                                style={{
                                                  width: "14px",
                                                  marginTop: "-2px",
                                                }}
                                              />
                                              <span
                                                className="wfm1 ml-2"
                                                style={{
                                                  fontSize: "14px",
                                                  marginRight: "20px",
                                                  marginTop: "-2px",
                                                }}
                                              >
                                                Work From Home
                                              </span>
                                            </>
                                          ) : (
                                            <>
                                              {data.city.map((data) => (
                                                <>
                                                  <SlLocationPin
                                                    style={{
                                                      color: "gray",
                                                      fontSize: "15px",
                                                      marginBottom: "3px",
                                                      marginTop: "-2px",
                                                    }}
                                                  />
                                                  <span
                                                    style={{
                                                      fontSize: "15px",
                                                      marginRight: "20px",
                                                      marginTop: "-2px",
                                                    }}
                                                    className="wfm1 ml-2"
                                                  >
                                                    {data}
                                                  </span>
                                                </>
                                              ))}
                                            </>
                                          )}
                                        </span> */}
                                        <div
                                          className=" pt-4 pb-5 "
                                          style={{
                                            display:
                                              "flex",
                                            flexWrap:
                                              "wrap",
                                            gap: "20px 70px",
                                          }}
                                        >
                                          <div className="statusBoxes">
                                            <p>
                                              <span>
                                                <img
                                                  src={
                                                    circle
                                                  }
                                                  alt=""
                                                  style={{
                                                    width: "16px",
                                                  }}
                                                />
                                              </span>
                                              <span
                                                className="mx-2 heeading"
                                                style={{
                                                  fontWeight:
                                                    "500",
                                                }}
                                              >
                                                START
                                                DATE
                                              </span>
                                            </p>
                                            <h3
                                              className="wfm123"
                                              style={{
                                                fontSize:
                                                  "14px",
                                              }}
                                            >
                                              {data.startDate ==
                                                "Immediately (within next 30 days)" ? (
                                                <>
                                                  Immediately
                                                </>
                                              ) : (
                                                <>
                                                  {
                                                    data.startDate
                                                  }
                                                </>
                                              )}
                                            </h3>
                                          </div>
                                          <div className="statusBoxes">
                                            <p>
                                              <span>
                                                <img
                                                  src={
                                                    calendar
                                                  }
                                                  alt=""
                                                  style={{
                                                    width: "16px",
                                                  }}
                                                />
                                              </span>
                                              <span
                                                className="mx-2 heeading"
                                                style={{
                                                  fontWeight:
                                                    "500",
                                                }}
                                              >
                                                DURATION
                                              </span>
                                            </p>
                                            <h3
                                              className="wfm123"
                                              style={{
                                                fontSize:
                                                  "14px",
                                              }}
                                            >
                                              {
                                                data.duration
                                              }{" "}
                                              {data?.durationType ||
                                                "months"}
                                            </h3>
                                          </div>

                                          <div className="statusBoxes">
                                            <p>
                                              <span>
                                                <img
                                                  src={
                                                    money
                                                  }
                                                  alt=""
                                                  style={{
                                                    width: "16px",
                                                  }}
                                                />
                                              </span>
                                              <span
                                                className="mx-2 heeading"
                                                style={{
                                                  fontWeight:
                                                    "500",
                                                }}
                                              >
                                                BUDGET
                                              </span>
                                            </p>
                                            {data.salaryProfile ===
                                              "Unpaid" ? (
                                              <>
                                                <h3
                                                  className="wfm123"
                                                  style={{
                                                    fontSize:
                                                      "14px",
                                                  }}
                                                >
                                                  Unpaid
                                                </h3>
                                              </>
                                            ) : (
                                              <>
                                                {data?.salaryProfile ===
                                                  "Unpaid" ? (
                                                  <>
                                                    <h3 className="head">
                                                      Unpaid
                                                    </h3>
                                                  </>
                                                ) : (
                                                  <>
                                                    {data?.salaryProfile ===
                                                      "Performance based" ? (
                                                      <>
                                                        <h3
                                                          className="wfm123"
                                                          style={{
                                                            fontSize:
                                                              "14px",
                                                          }}
                                                        >
                                                          $
                                                          {
                                                            data?.salary
                                                          }{" "}
                                                          Fixed
                                                          Cost
                                                          +
                                                          $
                                                          {
                                                            data?.incentive
                                                          }{" "}
                                                          per{" "}
                                                          {
                                                            data?.incentiveType
                                                          }
                                                        </h3>
                                                      </>
                                                    ) : (
                                                      <>
                                                        {data?.salaryProfile ===
                                                          "Negotiable" ? (
                                                          <h3
                                                            className="wfm123"
                                                            style={{
                                                              fontSize:
                                                                "14px",
                                                            }}
                                                          >
                                                            $
                                                            {
                                                              data?.salary
                                                            }{" "}
                                                            -
                                                            $
                                                            {
                                                              data?.salaryMax
                                                            }{" "}
                                                            Fixed
                                                            Cost
                                                          </h3>
                                                        ) : (
                                                          <h3
                                                            className="wfm123"
                                                            style={{
                                                              fontSize:
                                                                "14px",
                                                            }}
                                                          >
                                                            $
                                                            {
                                                              data?.salary
                                                            }{" "}
                                                            Fixed
                                                            Cost
                                                          </h3>
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                              // <>
                                              //   <h3
                                              //     className="wfm123"
                                              //     style={{ fontSize: "14px" }}
                                              //   >
                                              //     ${data.salary}
                                              //   </h3>
                                              // </>
                                            )}
                                          </div>

                                          <div className="pr-1 pb-4">
                                            <span>
                                              {/* <span>
                                                {calculateDaysDifference(
                                                  data.datePosted
                                                ).toFixed(0) < 6 ? (
                                                  <>
                                                    {calculateDaysDifference(
                                                      data.datePosted
                                                    ).toFixed(0) == 0 ? (
                                                      <span
                                                        className=" post1"
                                                        style={{
                                                          backgroundColor:
                                                            "#E1FFE1",
                                                          color: "green",
                                                        }}
                                                      >
                                                        <RxCounterClockwiseClock
                                                          style={{
                                                            fontSize: "14px",
                                                            marginTop: "-2px",
                                                          }}
                                                        />{" "}
                                                        Posted{" "}
                                                        {timeAgo(
                                                          data.datePosted
                                                        )}
                                                      </span>
                                                    ) : (
                                                      <span
                                                        className=" post1"
                                                        style={{
                                                          backgroundColor:
                                                            "#E1FFE1",
                                                          color: "green",
                                                        }}
                                                      >
                                                        <RxCounterClockwiseClock
                                                          style={{
                                                            fontSize: "14px",
                                                            marginTop: "-2px",
                                                          }}
                                                        />{" "}
                                                        Posted{" "}
                                                        {calculateDaysDifference(
                                                          data.datePosted
                                                        ).toFixed(0)}{" "}
                                                        days ago
                                                      </span>
                                                    )}
                                                  </>
                                                ) : (
                                                  <>
                                                    {calculateDaysDifference(
                                                      data.datePosted
                                                    ).toFixed(0) > 5 &&
                                                    calculateDaysDifference(
                                                      data.datePosted
                                                    ).toFixed(0) < 11 ? (
                                                      <>
                                                        <span
                                                          className=" post1 "
                                                          style={{
                                                            backgroundColor:
                                                              "#E2F8FF",
                                                            color: "#0D8CB8",
                                                          }}
                                                        >
                                                          <RxCounterClockwiseClock
                                                            style={{
                                                              fontSize: "14px",
                                                              marginTop: "-2px",
                                                            }}
                                                          />{" "}
                                                          Posted{" "}
                                                          {calculateDaysDifference(
                                                            data.datePosted
                                                          ).toFixed(0)}{" "}
                                                          days ago
                                                        </span>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <span className=" post1 ">
                                                          <RxCounterClockwiseClock
                                                            style={{
                                                              fontSize: "14px",
                                                              marginTop: "-2px",
                                                            }}
                                                          />{" "}
                                                          Posted{" "}
                                                          {calculateDaysDifference(
                                                            data.datePosted
                                                          ).toFixed(0)}{" "}
                                                          days ago
                                                        </span>
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </span>{" "} */}
                                              <span
                                                className=" post1"
                                                style={{
                                                  backgroundColor:
                                                    determineBgColor(
                                                      data.datePosted
                                                    ),
                                                  color: determineColor(
                                                    data.datePosted
                                                  ),
                                                }}
                                              >
                                                <RxCounterClockwiseClock
                                                  style={{
                                                    fontSize:
                                                      "14px",
                                                    marginTop:
                                                      "-2px",
                                                  }}
                                                />{" "}
                                                Posted{" "}
                                                {timeAgo(
                                                  data.datePosted
                                                )}
                                              </span>
                                              <span
                                                className=" post1"
                                                style={{
                                                  marginLeft:
                                                    "10px",
                                                }}
                                              >
                                                {
                                                  data.opportunityType
                                                }
                                              </span>
                                              {data.isPartTimeAllowed ==
                                                "Yes" ? (
                                                <span
                                                  className=" post1"
                                                  style={{
                                                    marginLeft:
                                                      "10px",
                                                  }}
                                                >
                                                  Part
                                                  time
                                                </span>
                                              ) : (
                                                <>

                                                </>
                                              )}
                                            </span>

                                            <span></span>
                                          </div>
                                        </div>
                                        {/*NEW CODE END HERE*/}
                                      </div>
                                      <hr
                                        className=""
                                        style={{
                                          height: "0.1px",
                                          color: "#C4C4C4",
                                          marginTop:
                                            "-60px",
                                        }}
                                      ></hr>
                                      <div className="cardbutton ">
                                        <div
                                          className="ml-auto text-end"
                                          style={{
                                            display:
                                              "flex",
                                            flexWrap:
                                              "wrap",
                                          }}
                                        >
                                          {/* <button  onClick={() => openPreviewPageInNewTab({ data })} className=" btnview1 ml-auto">
                                              {" "}
                                              View Details
                                            </button> */}
                                          <button
                                            onClick={() =>
                                              openPreviewPageInNewTab(
                                                {
                                                  data,
                                                }
                                              )
                                            }
                                            rel="noreferrer"
                                            target="_blank"
                                            className=" btnview1 ml-auto"
                                            style={{
                                              textDecoration:
                                                "none", // to remove underline
                                              display:
                                                "flex",
                                              alignItems:
                                                "center",
                                            }}
                                          >
                                            {" "}
                                            View Details
                                          </button>
                                          {user ? (
                                            userType !==
                                            "Employer" &&
                                            userType !==
                                            null &&
                                            (data.userApplied.includes(
                                              user
                                                ? user._id
                                                : ""
                                            ) ? (
                                              <>
                                                <p
                                                  style={{
                                                    color: "green",
                                                    fontWeight:
                                                      "400",
                                                    marginBottom:
                                                      "-2px",
                                                    marginLeft:
                                                      "10px",
                                                    marginRight:
                                                      "10px",
                                                  }}
                                                >
                                                  <MdOutlineCheckCircle
                                                    style={{
                                                      marginRight:
                                                        "3px",
                                                      fontSize:
                                                        "18px",
                                                      marginTop:
                                                        "-2px",
                                                    }}
                                                  />
                                                  Applied
                                                </p>
                                              </>
                                            ) : (
                                              <>
                                                <a
                                                  href="#"
                                                  rel="noreferrer"
                                                  target="_blank"
                                                  className="btn btnapply"
                                                  style={{
                                                    width: "100px",
                                                    marginLeft:
                                                      "20px",
                                                  }}
                                                  onClick={(
                                                    e
                                                  ) => {
                                                    e.preventDefault(); // Prevent the default action
                                                    const url =
                                                      handleApplyNow(
                                                        {
                                                          data,
                                                        }
                                                      );
                                                    if (
                                                      url
                                                    ) {
                                                      window.open(
                                                        url,
                                                        "_blank"
                                                      );
                                                    }
                                                  }}
                                                >
                                                  Apply
                                                  Now
                                                </a>
                                              </>
                                            ))
                                          ) : (
                                            <>
                                              <div
                                                style={{
                                                  textAlign:
                                                    "center",
                                                }}
                                              >
                                                {/* <button onClick={() => navigate("/login")} className="btn btnapply" style={{ marginLeft: "0px" }}> */}
                                                <button
                                                  onClick={() =>
                                                    navigate(
                                                      "/login",
                                                      {
                                                        state: {
                                                          from: location.pathname,
                                                        },
                                                      }
                                                    )
                                                  }
                                                  className="btn btnapply"
                                                  style={{
                                                    marginLeft:
                                                      "0px",
                                                  }}
                                                >
                                                  Apply
                                                  Now
                                                </button>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                      {totalPages > 1 && (
                        <div
                          className="pagination"
                          style={{ marginTop: "-20px" }}
                        >
                          <p
                            onClick={() =>
                              handlePageChange(currentPage - 1)
                            }
                            disabled={currentPage === 1}
                          >
                            <IoIosArrowBack
                              className={
                                currentPage === 1
                                  ? "paginationBtndisabled"
                                  : "paginationBtn"
                              }
                              style={{ marginTop: "6px" }}
                            />
                          </p>
                          <span style={{ fontSize: "16px" }}>
                            {currentPage} / {totalPages}
                          </span>
                          <p
                            onClick={() =>
                              handlePageChange(currentPage + 1)
                            }
                            disabled={currentPage === totalPages}
                          >
                            <IoIosArrowForward
                              className={
                                currentPage === totalPages
                                  ? "paginationBtndisabled"
                                  : "paginationBtn"
                              }
                              style={{ marginTop: "6px" }}
                            />
                          </p>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="text-center mt-5">
                        <i
                          class="fa fa-window-close-o mx-auto"
                          aria-hidden="true"
                          style={{ fontSize: "200px" }}
                        ></i>
                        <h1>Projects not Found</h1>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Popup open={showModal} closeOnDocumentClick onClose={() => setShowModal(false)}>
        <div className="overlay" style={{ background: "rgba(0, 0, 0, 0.4)" }}>
          <div className="popup-containernew">
            <div className="close-button12345" onClick={() => setShowModal(false)}>
              <RxCross1 />
            </div>
            <div className="success-message mx-3">
              Your Connect credit limit has been reached.
            </div>
            <div className="success-message1 mx-3">
              To continue posting, kindly purchase more Connects.
            </div>
            <div
              className="d-flex flex-column flex-md-row gap-5"
              style={{ marginTop: "10px", marginBottom: "20px" }}
            >
              <button
                type="button"
                className="submit-btn12345"
                onClick={handleBuyConnects}
              >
                Buy Connects
              </button>
            </div>
          </div>
        </div>
      </Popup>
      <ToastContainer position="top-center" autoClose={10000} />
    </>
  );
}

export default Internships;
