import React, { useEffect, useRef, useState } from 'react';
import { SlOptionsVertical } from 'react-icons/sl';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Dropdown = ({ props }) => {
  const dropdownRef = useRef(null);
  // const openPreviewPageInNewTab = (data) => {
  //   window.open(`/preview/${data.profile.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${data.opportunityType}-at-${data.organisationName.replace(/ /g, "-")}_${data._id}`.toLowerCase());
  // };
  const openPreviewPageInNewTab = (data) => {
    if (!data || !data.profile || !data._id) {
      return null;
    }
    const url = `/preview/${data.profile.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${data.opportunityType}-at-${data.organisationName.replace(/ /g, "-")}_${data._id}`.toLowerCase()
    return url;
  };

  const openReviewPageInNewTab = (data) => {
    window.open(`/review/${data.appliedJobId._id}`);
  };

  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const toggle = () => {
    setIsHovered(!isHovered);
  };



  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const getData = async () => {
    setLoading(true);
    try {
      const res = await fetch('/api/user/getAllJobs');
      const data = await res.json();
      const job = data?.jobs?.find((job) => job._id === props.jobId._id);
      if (job) {
        setData([job]); // Set data to an array containing the single job
      } else {
        setData([]); // Set data to an empty array if no matching job is found
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [props]);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsHovered(false);
    }
  };
  useEffect(() => {
    if (isHovered) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isHovered]);

  const CustomGrayToast = ({ message }) => (
    <div style={{ color: '#6B6B6B' }}>
      {message}
    </div>
  );
  const handleLinkClick = (event, data) => {
    const url = openPreviewPageInNewTab(data);
    if (!url) {
      event.preventDefault();
      toast.error(<CustomGrayToast message="Job is Closed" />);
    }
  };

  return (
    <>
    <div
      className="dropdown"
      ref={dropdownRef}
      style={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: '50%',
        padding: '4px',
        background: `${isHovered ? '#EEE' : 'transparent'}`,
        fontSize: '14px',
      }}
    >
      <div
        style={{
          textAlign: 'center',
          color: '#484848',
          borderRadius: '50%',
          // width: '15px',
          // fontSize: '15px',
          padding: '4px 5px',
          display: 'inline-block',
        }}
        onClick={toggle}
      >
        <SlOptionsVertical height={18} width={18} />
      </div>

      {isHovered && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            right: '0',
            zIndex: 1,
            backgroundColor: '#fff',
            boxShadow: '0 2px 6px rgba(0,0,0,.12)',
            borderRadius: '6px',
            padding: '12px 0',
            minWidth: '160px',
            marginTop: '10px',
          }}
        >
          <ul>
            {/* <li>
              <a style={{ margin: '15px 0' }} href="/">
                Mark as Unread
              </a>
            </li> */}
            <li>
              {/* <a
                style={{ margin: '15px 0' }}
                onClick={() => openPreviewPageInNewTab(data[0])}

              >
                View job details
              </a> */}
              <a
                  style={{ margin: '15px 0', color: '#008BDC' }}
                  href={openPreviewPageInNewTab(data[0])}
                  rel="noreferrer"
                  target="_blank"
                  onClick={(e) => handleLinkClick(e, data[0])}
                  onContextMenu={(e) => handleLinkClick(e, data[0])} // Handle right-click
                >
                View job details
              </a>
            </li>
            <li>
              <a
                style={{ margin: '15px 0' }}
                onClick={() =>
                  openReviewPageInNewTab(props)
                }
              >
                Review Applications
              </a>
            </li>
            {/* <li>
              <a style={{ margin: '15px 0' }} href="/">
                Report a complaint
              </a>
            </li> */}
          </ul>
        </div>
      )}
    </div>
    <ToastContainer
      position="top-center"
      autoClose={10000}
      />
    </>
  );
};

export default Dropdown;
