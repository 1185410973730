import React, { useEffect, useState, useRef } from "react";
import "./css/postInternship.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-country-state-city/dist/react-country-state-city.css";
import Select from "react-select";
import Popup from "reactjs-popup";
import { RxCross1 } from "react-icons/rx";
import SkillsSelector from "../SkillsSelector";
import ProjectCategorySelector from "./ProjectCategorySelector";
import ProjectSpecializationSelector from "./ProjectSpecializationSelector";

const PostInternship = () => {
  const { user } = useSelector((state) => state.user);
  const textareaRef = useRef(null);
  const dispatch = useDispatch();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [previousId, setPreviousId] = useState(null);
  const [countryCode, setCountryCode] = useState("91");
  const [projectTitleError, setProjectTitleError] = useState(false);
  // const [showModal,setShowModal] = useState(false);
  // const [connectCredit, setConnectCredit] = useState(-1);
  // useEffect hook to add event listener when component mounts
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (unsavedChanges) {
        event.preventDefault();
        event.returnValue = ""; // For some browsers
        return ""; // For others
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [unsavedChanges]);

  // useEffect(() => {
  //   dispatch(loadUser());
  // }, []);

  const month = (data) => {
    const num = [
      "0",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
      "ten",
    ];
    if (!data) {
      return 0;
    } else {
      return num[data];
    }
  };

  useEffect(() => {
    if (user && (!user.organisationName || !user.organisationDescription)) {
      toast.error("Organization details are not added");
      navigate("/myProfile/company");
    }
  }, [user]);

  const params = useParams();
  useEffect(() => {
    // Check if params.id has changed to '0'
    if (params.id === "0" && previousId !== "0" && previousId !== null) {
      // Reload the page or rerender
      // window.location.reload(true);
      window.location.href = window.location.href;
    }
    // Update previousId
    setPreviousId(params.id);
  }, [params.id, previousId]);
  const fetchData = async (_id) => {
    const response = await axios.post(
      "/api/employer/getJob",
      { _id },
      {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      }
    );

    const data = await response.data;
    setJob({
      email: data.email,
      opportunityType: data.opportunityType,
      jobType: data.jobType,
      profile: data.profile,
      skill: data?.skill,
      projectCategory: data?.projectCategory,
      projectSpecialization: data?.projectSpecialization,
      numberOfOpeining: data.numberOfOpeining,
      city: data.city,
      country: "India",
      isPartTimeAllowed: data.isPartTimeAllowed,
      duration: data.duration,
      durationType: data.durationType,
      salaryProfile: data.salaryProfile,
      salary: data.salary,
      salaryMax: data.salaryMax,
      perks: data.perks,
      jobDescription: data.jobDescription,
      dayOfWeek: data.dayOfWeek,
      startDate: data.startDate,
      preference: data?.preference,
      stipendAmountType: data.stipendAmountType,
      incentive: data?.incentive,
      incentiveType: data?.incentiveType,
      question: {
        q1: data?.question?.q1,
        q2: data?.question?.q2,
      },
      hrContact: data?.hrContact,
      minExperience: data?.minExperience,
      maxExperience: data?.maxExperience,
    });
    if (data?.question?.q1) {
      setShowQuestion([true, false]);
    }

    if (data?.question?.q2) {
      setShowQuestion([true, true]);
    }

    if (data?.skill?.length > 0) {
      setSelectedSkill(
        data?.skill.map((item) => ({ label: item, value: item }))
      );
    }

    if (data?.city?.length > 0) {
      setSelectedCity(data?.city.map((item) => ({ label: item, value: item })));
    }
  };

  useEffect(() => {
    const id = params.id;
    if (id != "0") {
      fetchData(id);
    }
  }, [params]);

  const initialForm = {
    email: "",
    opportunityType: "Hourly",
    jobType: "Remote",
    profile: "",
    skill: [],
    projectCategory: "",
    projectSpecialization: "",
    numberOfOpeining: "",
    city: [],
    country: "India",
    isPartTimeAllowed: "No",
    duration: "",
    durationType: "month",
    salaryProfile: "Fixed",
    salary: "",
    salaryMax: "",
    perks: [],
    jobDescription: "",
    dayOfWeek: "",
    startDate: "Immediately (within next 30 days)",
    internshipFrom: "",
    internshipTo: "",
    preference: "",
    stipendAmountType: "month",
    incentive: "",
    incentiveType: "",
    question: {
      q1: "",
      q2: "",
    },
    hrContact: user?.phone,
    minExperience: "",
    maxExperience: "",
  };

  const [job, setJob] = useState(initialForm);
  //console.log(user, "user data");

  useEffect(() => {
    // Serialize both objects to JSON for comparison
    const initialFormJson = JSON.stringify(initialForm);
    const jobJson = JSON.stringify(job);

    // Check if the current form state differs from the initial form state
    if (initialFormJson !== jobJson) {
      setUnsavedChanges(true);
    } else {
      setUnsavedChanges(false);
    }
  }, [job]);

  const [prev, setPrev] = useState({
    orgLogo: "",
  });

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [users, setUser] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
  });

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showQuestion, setShowQuestion] = useState([false, false]);

  const closeDilog = () => {
    setShowSuccessPopup(false);
    navigate("/employerApplication/Hourly");
  };
  // const handleBuyConnects=()=>{
  //   navigate('/employerbuyconnects')
  //   setShowModal(false)
  // }

  const handlepostnewButton = () => {
    window.location = "/newpost/0";
  };

  const handleviepostedjobButton = (data) => {
    navigate(
      `/preview/${data.profile.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${
        data.opportunityType
      }-at-${data.organisationName.replace(/ /g, "-")}_${
        data._id
      }`.toLowerCase()
    );
  };

  const selectedCountry = "India";
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedSkill, setSelectedSkill] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [isTyping1, setIsTyping1] = useState(false);
  const [projectCategory, setProjectCategory] = useState("");
  const [projectSpecialization, setProjectSpecialization] = useState("");
  const [specializationData, setSpecializationData] = useState([]);

  const handleCityChange = (selectedOptions) => {
    setSelectedCity(selectedOptions);
    setJob({
      ...job,
      city: selectedOptions.map((option) => option.value),
    });
  };
  const handleInputChange = (newValue) => {
    setSearchTerm(newValue);
    setIsTyping1(newValue.length > 0);
  };
  const handleSkillChange = (selectedOptions) => {
    setSelectedSkill(selectedOptions);
    setJob({
      ...job,
      skill: selectedOptions.map((option) => option.value),
    });
  };

  const handlePerksChange = (value) => {
    if (!job.perks.includes(value)) {
      setJob((prev) => ({ ...prev, perks: [...prev.perks, value] }));
    } else {
      setJob((prev) => ({
        ...prev,
        perks: prev.perks.filter((i) => i !== value),
      }));
    }
  };

  useEffect(() => {
    setUser(user && user);
    setJob((prev) => ({ ...prev, hrContact: user?.phone }));
    setPrev({
      ...prev,
      orgLogo:
        (user && user.organisationAvatar != undefined) || ""
          ? user.organisationAvatar.url
          : "",
    });
  }, [user]);

  const handleJobChange = (e) => {
    if (e.target.name === "Hourly") {
      setShowQuestion([false, false]);
      setSelectedCity("");
      setSelectedSkill("");
      setJob({
        ...initialForm,
        email: user.email,
        [e.target.name]: e.target.value,
      });
    } else {
      setJob({
        ...job,
        email: user.email,
        [e.target.name]: e.target.value,
      });

      if (
        e.target.name == "internshipFrom" &&
        job.internshipTo !== "" &&
        new Date(e.target.value) > new Date(job.internshipTo)
      ) {
        setJob({
          ...job,
          email: user.email,
          internshipFrom: e.target.value,
          internshipTo: e.target.value,
        });
      }
    }
  };

  const addProfile = async (value) => {
    await axios
      .post("/api/data/update-profiles", { value })
      .catch((err) => console.log(err));
  };
  // let showModalFlag = false;
  const [justCreated, setJustCreated] = useState({});

  //   const getConnectCreditInfo = async () => {
  //     try {
  //         const response = await axios.get(`/api/employer/getConnectCreditInfomrationofEmployer/${user?._id}`);
  //         setConnectCredit(response.data.connectCredit);
  //     } catch (error) {
  //         console.error('Error fetching connectCredit:', error);
  //     }
  // };
  //   useEffect(() => {

  //     if (user && user.role === 'Employer') {
  //         getConnectCreditInfo();
  //     }
  // });
  // console.log(connectCredit,"connect credit")
  const handlePostProject = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (job.jobDescription.length > 5000) {
      setLoading(false);
      textareaRef.current.focus();
      return;
    } else if (!job.opportunityType) {
      setLoading(false);
      toast.error("Opportunity type is required!!");
    } else if (job.opportunityType == "Hourly" && job.skill.length == 0) {
      setLoading(false);
      toast.error("skill is required!!");
    } else if (!job.jobType) {
      setLoading(false);
      toast.error(`${job.opportunityType} type is required!!`);
    } else if (job.jobType != "Remote" && job.city.length == 0) {
      setLoading(false);
      toast.error("City is required!!");
    } else if (job.jobType == "Hybrid" && !job.dayOfWeek) {
      setLoading(false);
      toast.error("Please select no. of in office days in a week");
    } else if (job.opportunityType == "Hourly" && !job.salary) {
      setLoading(false);
      toast.error("Salary is required!!");
    } else if (
      job.opportunityType == "Hourly" &&
      Number(job.salaryMax) < Number(job.salary)
    ) {
      setLoading(false);
      toast.error("Maximum salary should be greater than or equal to salary");
    } else if (!job?.profile) {
      setLoading(false);
      toast.error("Project Title is required ");
    } else if (job?.profile?.length < 30) {
      setLoading(false);
      toast.error("Project title must be above 30 characters");
    } else if (projectTitleError) {
      setLoading(false);
      toast.error("Project title must be below 100 ");
    } else if (!job?.projectCategory) {
      setLoading(false);
      toast.error("Please Select Project Category");
    } else if (!job?.projectSpecialization) {
      setLoading(false);
      toast.error("Please Chooose Project Specialization");
    } else if (!job?.duration) {
      setLoading(false);
      toast.error("Project duration required!!");
    } else if (
      job.opportunityType !== "Hourly" &&
      job?.salaryProfile === "Performance based" &&
      !job?.incentiveType
    ) {
      setLoading(false);
      toast.error("Select incentive type!!");
    } else {
      try {
        //console.log(job, "job data");
        const { data } = await axios.post("/api/employer/postJob", job, {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        });

        if (data.success) {
          setUnsavedChanges(false);
          addProfile(data.job.profile);
          setLoading(false);
          setJustCreated(data.job[0]);
          setShowSuccessPopup(true);
          // getConnectCreditInfo()
        } else {
          // toast.error(data.message);
          let errorMessage;
          let toastStyle = {};

          if (job.opportunityType === "Fixed Cost") {
            errorMessage = "There is already a similar internship post live.";
            if (window.innerWidth >= 768) {
              toastStyle = { width: "400px" };
            }
          } else if (job.opportunityType === "Hourly") {
            errorMessage = "There is already a similar job post live.";
            if (window.innerWidth >= 768) {
              toastStyle = { width: "350px" };
            }
          } else {
            errorMessage = data.message;
          }
          setLoading(false);
          // toast.error(errorMessage,{
          //   style: toastStyle
          // });
          if (errorMessage) {
            toast.error(errorMessage, { style: toastStyle });
          }
          if (
            data.message == "Please update organisation details to post job."
          ) {
            navigate("/myProfile/company");
          }
          // if (showModalFlag) {
          //   setShowModal(true);
          // }
        }
      } catch (error) {
        setLoading(false);
        setShowErrorPopup(true);
      }
    }
  };

  const days = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
  ];

  const durationList = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
  ];

  const [durationTypeList, setDurationTypeList] = useState([
    { value: "month", label: "month" },
    { value: "week", label: "week" },
  ]);

  const minexperience = [
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
  ];

  const maxexperience = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
  ];

  const handleAddQuestion = () => {
    if (!showQuestion[0] && !showQuestion[1]) {
      setShowQuestion([true, false]);
    } else if (showQuestion[0] && !showQuestion[1]) {
      setShowQuestion([true, true]);
    }
  };

  const handleRemoveQuestion = (id) => {
    setJob((prev) => ({ ...prev, question: initialForm.question }));
    if (showQuestion[0] && showQuestion[1]) {
      setShowQuestion([true, false]);
    } else {
      setShowQuestion([false, false]);
    }
  };

  const [allskills, setAllSkills] = useState([]);
  const [allprofiles, setAllProfiles] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [searchTerm2, setSearchTerm2] = useState("");
  useEffect(() => {
    // async function fetchAllSkills() {
    //   const response = await axios.get(`/api/data/skills`);
    //   const data = await response.data;
    //   setAllSkills(data.map((i) => ({ label: i, value: i })));
    // }

    async function fetchAllProfiles() {
      const response = await axios.get(`/api/data/profiles`);
      const data = await response.data;
      setAllProfiles(data);
    }

    // fetchAllSkills();
    fetchAllProfiles();
  }, []);
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    const fetchMatchingCities = async () => {
      if (searchTerm.trim().length >= 2) {
        try {
          const response = await axios.get(`/api/data/get-cities-data`, {
            params: { keyword: searchTerm },
          });
          if (Array.isArray(response.data)) {
            setCityOptions(
              response.data.map((city) => ({ label: city, value: city }))
            );
          } else {
            console.error("Unexpected response format:", response.data);
          }
        } catch (error) {
          console.error("Error fetching cities:", error);
        }
      } else {
        setCityOptions([]);
      }
    };

    fetchMatchingCities();
  }, [searchTerm]);
  const noOptionsMessage = () => {
    return searchTerm.trim().length < 2 ? "Type a City Name" : "No Options";
  };

  useEffect(() => {
    async function fetchAllSkills() {
      if (searchTerm2.trim()) {
        try {
          const response = await axios.get(`/api/data/skills`, {
            params: { keyword: searchTerm2 },
          });
          if (Array.isArray(response.data)) {
            setAllSkills(
              response.data.map((skill) => ({ label: skill, value: skill }))
            );
          } else {
            console.error("Unexpected response format:", response.data);
          }
        } catch (error) {
          console.error("Error fetching skills:", error);
        }
      } else {
        setAllSkills([]);
      }
    }
    fetchAllSkills();
  }, [searchTerm2]);

  const handleInputChange2 = (newValue) => {
    setSearchTerm2(newValue);
    setIsTyping(newValue.length > 0);
  };
  const currentDate = new Date();

  // Get date 30 days from now
  const next30Days = new Date();
  next30Days.setDate(next30Days.getDate() + 30);

  // Function to format the date as "dayth Month'yy"
  const formatDate = (date) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString().substr(-2);
    return `${day}${getDaySuffix(day)} ${month}'${year}`;
  };

  // Function to get the suffix for the day
  const getDaySuffix = (day) => {
    if (day >= 11 && day <= 13) {
      return "th";
    }
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Format current date and date 30 days from now
  const formattedCurrentDate = formatDate(currentDate);
  const formattedNext30Days = formatDate(next30Days);

  const formatDate2 = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear().toString().substr(-2);
    return `${day}${getDaySuffix2(day)} ${month}'${year}`;
  };

  const getDaySuffix2 = (day) => {
    if (day >= 11 && day <= 13) {
      return "th";
    }
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };
  //console.log(job.opportunityType, "oppor");
  // useEffect(() => {
  //   if (connectCredit !== -1 && job.opportunityType === "Internship") {
  //     if (!showSuccessPopup && connectCredit < 4) {
  //       setShowModal(true);
  //     } else {
  //       setShowModal(false);
  //     }
  //   } else if (connectCredit !== -1 && job.opportunityType === "Job") {
  //     if (!showSuccessPopup && connectCredit < 8) {
  //       setShowModal(true);
  //     } else {
  //       setShowModal(false);
  //     }
  //   }
  // }, [job.opportunityType, user?.connectCredit,connectCredit, user]);
  const customStyles = {
    dropdownIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
  };
  const noOptionsMessage2 = () => {
    return searchTerm2.trim().length < 2 ? "Type Skill" : "No Options";
  };
  return (
    <>
      <div
        style={{
          background: "white",
          paddingTop: "20px",
          paddingBottom: "40px",
        }}
      >
        <div className="container">
          <h1 className="text-center mx-auto" style={{ margin: "40px 0px" }}>
            {/* Post jobs/internships */}
            {job.profile && job.opportunityType === "Hourly"
              ? "View/Edit Hourly "
              : job.profile && job.opportunityType === "Fixed Cost"
              ? "View/Edit Fixed Cost"
              : "Post Project"}
          </h1>

          <div className="section">
            <form onSubmit={handlePostProject} className="col">
              {/* <div className="banner">
            We're thrilled to announce that you can now hire candidates with up to 5 years of experience! This offering is exclusive 
            to a selected group of employers in our Beta version.
            </div> */}
              {params.id === "0" && (
                <div className="sub-section">
                  <div className="title">Type of Opportunity</div>
                  <div className="container fields">
                    <div className="d-flex gap-5 align-items-center">
                      <div className="d-flex gap-2 align-items-center  justify-content-center">
                        <input
                          type="radio"
                          className="check-input"
                          name="opportunityType"
                          value="Hourly"
                          onChange={handleJobChange}
                          checked={job.opportunityType === "Hourly"}
                        />
                        <div className="check-labels" htmlFor="opportunityType">
                          Hourly (Popular and Recommended)
                        </div>
                      </div>
                      <div className="d-flex gap-2 align-items-center justify-content-center">
                        <input
                          type="radio"
                          className="check-input"
                          name="opportunityType"
                          value="Fixed Cost"
                          onChange={handleJobChange}
                          checked={job.opportunityType === "Fixed Cost"}
                        />
                        <div className="check-labels" htmlFor="opportunityType">
                          Fixed Cost
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="sub-section">
                <div className="title">
                  {/* {job.opportunityType} */}
                  Project details
                </div>
                <div className="container fields">
                  {/* Profile */}
                  <div className="details">
                    <label htmlFor="profile" className="sub-title">
                      {/* {job.opportunityType} Role */}
                      Project title
                    </label>
                    <input
                      placeholder="Enter project title"
                      // className="parent-dropdown2"
                      className="input-text"

                      value={job?.profile}
                      style={{
                        borderColor: projectTitleError && "red",
                      }}
                      onChange={(e) => {
                        if (e.target.value?.length > 100) {
                          return setProjectTitleError(true);
                        }
                        setProjectTitleError(false);
                        setJob({
                          ...job,
                          profile: e.target.value,
                        });
                      }}
                    />
                    {/* <Dropdown
                      type={job.opportunityType === "Job"}
                      placeholder="e.g. Web development"
                      category="profile"
                      actual={job}
                      setActual={setJob}
                      options={allprofiles}
                    /> */}
                  </div>
                  <div>
                    <label htmlFor="profile" className="sub-title">
                      Project Category
                    </label>
                    <ProjectCategorySelector
                      setSelectedOption={(category) => {
                        setJob((prevJob) => ({
                          ...prevJob,
                          ["projectCategory"]: category?.value,
                        }));
                      }}
                      setSpecializationData={setSpecializationData}
                      resetSpecialization={() => {
                        setJob((prevJob) => ({
                          ...prevJob,
                          ["projectSpecialization"]: "",
                        }));
                      }}
                      selectedData={
                        job?.projectCategory && {
                          label: job?.projectCategory,
                          value: job?.projectCategory,
                        }
                      }
                    />
                  </div>
                  <div>
                    <label htmlFor="profile" className="sub-title">
                      Project Specialization
                    </label>
                    <ProjectSpecializationSelector
                      setSelectedOption={(sp) => {
                        setJob((prevJob) => ({
                          ...prevJob,
                          ["projectSpecialization"]: sp?.value,
                        }));
                      }}
                      optionData={specializationData}
                      selectedData={
                        job?.projectSpecialization && {
                          label: job?.projectSpecialization,
                          value: job?.projectSpecialization,
                        }
                      }
                    />
                  </div>

                  {/* Required Experience */}
                  {/* {job.opportunityType == "Hourly" && (
                    <div className="details">
                      <div className="sub-title">Experience Required</div>
                      <div className="d-flex align-items-center gap-3 w-full">
                        <Select
                          className="w-100"
                          value={
                            job.minExperience != ""
                              ? {
                                  label: job.minExperience,
                                  value: job.minExperience,
                                }
                              : { label: "Min", value: job.minExperience }
                          }
                          onChange={(exp) => {
                            setJob({
                              ...job,
                              minExperience: exp.value,
                            });
                          }}
                          options={minexperience}
                        />
                        <span>to</span>
                        <Select
                          className="w-100"
                          value={
                            job.maxExperience != ""
                              ? {
                                  label: job.maxExperience,
                                  value: job.maxExperience,
                                }
                              : { label: "Max", value: job.maxExperience }
                          }
                          onChange={(exp) => {
                            setJob({
                              ...job,
                              maxExperience: exp.value,
                            });
                          }}
                          options={maxexperience}
                        />
                        <span>year(s)</span>
                      </div>
                    </div>
                  )} */}

                  {/* Skills */}
                  <div className="details">
                    <label htmlFor="profile" className="sub-title">
                      Skills required{" "}
                      {/* {job.opportunityType != "Job" && (
                        <span style={{ fontSize: "12px", color: "#bbb" }}>
                          (Optional)
                        </span>
                      )} */}
                    </label>
                    <Select
                      placeholder="e.g. Python"
                      className="select-tag"
                      isMulti
                      value={selectedSkill}
                      onChange={handleSkillChange}
                      options={allskills}
                      onInputChange={handleInputChange2}
                      styles={customStyles}
                      // noOptionsMessage={noOptionsMessage2}
                      menuIsOpen={isTyping}
                    />
                  </div>

                  {/* Type */}
                  <div className="details">
                    <div className="sub-title mb-3">
                      {/* {job.opportunityType} type */}
                      Project type
                    </div>
                    {/* <div className="d-flex gap-5 align-items-center"> */}
                    <div className="oppormobileresponsive">
                      <div className="d-flex gap-2 align-items-center justify-content-center">
                        <input
                          type="radio"
                          className="check-input"
                          name="jobType"
                          value="Remote"
                          onChange={handleJobChange}
                          checked={job.jobType === "Remote"}
                        />
                        <div className="check-labels" htmlFor="jobType">
                          Remote-based
                        </div>
                      </div>
                      <div className="d-flex gap-2 align-items-center  justify-content-center">
                        <input
                          type="radio"
                          className="check-input"
                          name="jobType"
                          value="Office"
                          onChange={handleJobChange}
                          checked={job.jobType === "Office"}
                        />
                        <div className="check-labels" htmlFor="jobType">
                          In-office
                        </div>
                      </div>

                      <div className="d-flex gap-2 align-items-center justify-content-center">
                        <input
                          type="radio"
                          className="check-input"
                          name="jobType"
                          value="Hybrid"
                          onChange={handleJobChange}
                          checked={job.jobType === "Hybrid"}
                        />
                        <div className="check-labels" htmlFor="jobType">
                          Combination
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* No of days in a week */}
                  {job.jobType == "Hybrid" && (
                    <div className="details w-50" style={{ maxWidth: "300px" }}>
                      <div htmlFor="city" className="sub-title">
                        No. of in-office days in a week:
                      </div>
                      <Select
                        className="select-tag"
                        required
                        value={
                          job.dayOfWeek != ""
                            ? { label: job.dayOfWeek, value: job.dayOfWeek }
                            : { label: "Please Select", value: job.dayOfWeek }
                        }
                        onChange={(day) => {
                          setJob({
                            ...job,
                            dayOfWeek: day.value,
                          });
                        }}
                        options={days}
                      />
                    </div>
                  )}

                  {/* Partime/Fulltime */}
                  <div className="details">
                    <div className="sub-title mb-3">Full-time/Part-time</div>
                    <div className="d-flex gap-5 align-items-center">
                      <div className="d-flex gap-2 align-items-center justify-content-center">
                        <input
                          type="radio"
                          className="check-input"
                          name="isPartTimeAllowed"
                          value="No"
                          onChange={handleJobChange}
                          checked={job.isPartTimeAllowed === "No"}
                        />
                        <div
                          className="check-labels"
                          htmlFor="isPartTimeAllowed"
                        >
                          Full-time
                        </div>
                      </div>
                      <div className="d-flex gap-2 align-items-center  justify-content-center">
                        <input
                          type="radio"
                          className="check-input"
                          name="isPartTimeAllowed"
                          value="Yes"
                          onChange={handleJobChange}
                          checked={job.isPartTimeAllowed === "Yes"}
                        />
                        <div
                          className="check-labels"
                          htmlFor="isPartTimeAllowed"
                        >
                          Part-time
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* City Select */}
                  {(job.jobType == "Hybrid" || job.jobType == "Office") && (
                    <div className="details">
                      <div htmlFor="city" className="sub-title">
                        City/Cities
                      </div>
                      <Select
                        placeholder="e.g. Mumbai"
                        isMulti
                        // options={selectedCountry && citiesData[selectedCountry].map((city) => ({ value: city, label: city }))}
                        options={cityOptions}
                        value={selectedCity}
                        onChange={handleCityChange}
                        onInputChange={handleInputChange}
                        // noOptionsMessage={noOptionsMessage}
                        styles={customStyles}
                        menuIsOpen={isTyping1}
                      />
                    </div>
                  )}

                  {/* No of Openings */}
                  <div className="details">
                    <div className="sub-title">
                      Number of freelancers needed
                    </div>
                    <input
                      type="number"
                      placeholder="e.g. 10"
                      min="0"
                      className="input-text"
                      value={job.numberOfOpeining}
                      onChange={handleJobChange}
                      name="numberOfOpeining"
                      required
                    />
                  </div>

                  {/* Intership Start Date */}
                  {/* {job.opportunityType !== "Job" && (
                    <>
                      <div className="details">
                        <div className="sub-title mb-3">
                          Internship start date
                        </div>
                        <div className="d-flex gap-5 align-items-center">
                          <div className="d-flex gap-2 align-items-center  justify-content-center">
                            <input
                              type="radio"
                              className="check-input"
                              name="startDate"
                              value="Immediately (within next 30 days)"
                              onChange={handleJobChange}
                              checked={
                                job.startDate ===
                                "Immediately (within next 30 days)"
                              }
                            />
                            <div
                              className="check-labels"
                              htmlFor="isPartTimeAllowed"
                            >
                              Immediately (Within the next 30 days)
                            </div>
                          </div>
                          <div className="d-flex gap-2 align-items-center justify-content-center">
                            <input
                              type="radio"
                              className="check-input"
                              name="startDate"
                              value="Later"
                              onChange={handleJobChange}
                              checked={job.startDate === "Later"}
                            />
                            <div
                              className="check-labels"
                              htmlFor="isPartTimeAllowed"
                            >
                              Later
                            </div>
                          </div>
                        </div>
                      </div>

                      {job.startDate === "Later" && (
                        <div
                          className="d-flex flex-column gap-2"
                          style={{ width: "fit-content" }}
                        >
                          <div className="take-date">
                            <span>From</span>
                            <input
                              required={job.startDate === "Later"}
                              value={job.internshipFrom}
                              name="internshipFrom"
                              onChange={handleJobChange}
                              type="date"
                              min={new Date().toISOString().split("T")[0]}
                            />
                            <div></div>
                          </div>

                          <div className="take-date">
                            <span>To</span>
                            <input
                              required={job.startDate === "Later"}
                              value={job.internshipTo}
                              name="internshipTo"
                              onChange={handleJobChange}
                              type="date"
                              min={job.internshipFrom}
                            />
                            <div></div>
                          </div>
                        </div>
                      )}
                    </>
                  )} */}

                  {/* Durtaion of Intenship */}
                  <div className="details">
                    <label htmlFor="duration" className="sub-title">
                      Project duration
                    </label>
                    <span style={{ color: "#bbb", fontSize: "14px" }}>
                      The longer the duration, the more applications we receive.
                    </span>
                    <div className="d-flex align-items-center justify-content-center gap-3">
                      <Select
                        placeholder="Choose duration"
                        value={
                          job.duration && {
                            label: job.duration,
                            value: job.duration,
                          }
                        }
                        onChange={(month) => {
                          if (month.value > 1) {
                            setDurationTypeList([
                              { value: "months", label: "months" },
                              { value: "weeks", label: "weeks" },
                            ]);
                            setJob({
                              ...job,
                              duration: month.value,
                              durationType: "months",
                            });
                            return;
                          }
                          setDurationTypeList([
                            { value: "month", label: "month" },
                            { value: "week", label: "week" },
                          ]);
                          setJob({
                            ...job,
                            duration: month.value,
                            durationType: "month",
                          });
                        }}
                        options={durationList}
                        className="w-75"
                      />
                      <Select
                        className="selectcomponentresponsive "
                        placeholder="Duration Type"
                        value={
                          job.duration && {
                            label: job.durationType,
                            value: job.durationType,
                          }
                        }
                        onChange={(type) => {
                          setJob({
                            ...job,
                            durationType: type.value,
                          });
                        }}
                        options={job.duration ? durationTypeList : []}
                        noOptionsMessage={() => {
                          return "First choose duration";
                        }}
                      />
                    </div>
                  </div>

                  {/* job description and responsibilities */}
                  <div className="details">
                    <label htmlFor="jobDescription" className="sub-title">
                      {/* {job.opportunityType == "Job"
                        ? "Job Description"
                        : "Intern’s responsibilities"} */}
                      Project description
                    </label>
                    <textarea
                      placeholder="Key Responsibilities                   
1.
2.
3."
                      type="text"
                      rows={5}
                      className="input-text"
                      style={{
                        borderColor:
                          job.jobDescription.length > 5000 ? "red" : "",
                      }}
                      value={job.jobDescription}
                      onChange={handleJobChange}
                      name="jobDescription"
                      ref={textareaRef}
                      required
                    ></textarea>
                    {job.jobDescription.length > 5000 && (
                      <p
                        className="text-end"
                        style={{ fontSize: "13px", color: "red" }}
                      >
                        Please limit your input to 5000 characters or less
                      </p>
                    )}
                  </div>

                  {/* WHo can apply */}
                  {/* {job.opportunityType !== "Job" && (
                    <div className="details">
                      <div className="sub-title">
                        {" "}
                        Who can apply (prefilled as per earlier inputs):{" "}
                      </div>
                      <div
                        className="px-3 py-4"
                        style={{
                          backgroundColor: "#f8f8f8",
                          fontSize: "14px",
                          border: "1px solid #ddd",
                        }}
                      >
                        Only those candidates can apply who:
                        <br />
                        1. are available for{" "}
                        {job.isPartTimeAllowed == "No"
                          ? "full-time"
                          : "part-time"}{" "}
                        {job.jobType == "Remote"
                          ? "work from home"
                          : "In office"}{" "}
                        internship
                        <br />
                        2. have relevant skills and interests
                        <br />
                        {job.startDate === "Later" ? (
                          <span>
                            3. Can start the{" "}
                            {job.jobType === "Remote"
                              ? "work from home"
                              : "In office"}{" "}
                            internship between
                            {job.internshipFrom && job.internshipTo
                              ? ` ${formatDate2(
                                  job.internshipFrom
                                )} and ${formatDate2(job.internshipTo)}`
                              : ""}
                          </span>
                        ) : (
                          <span>
                            3 .Can start the{" "}
                            {job.jobType === "Remote"
                              ? "work from home"
                              : "In office"}{" "}
                            internship between {formattedCurrentDate} and{" "}
                            {formattedNext30Days}
                          </span>
                        )}
                        <br />
                        4. are available for duration of {job.duration}{" "}
                        {job.durationType}
                      </div>
                    </div>
                  )} */}

                  {/* Preferences */}
                  <div className="details">
                    <label htmlFor="preference" className="sub-title">
                      Additional candidate preference:
                    </label>
                    <textarea
                      placeholder={`1. e.g. ${
                        job.opportunityType !== "Hourly"
                          ? "Candidates pursuing Computer Science Engineering"
                          : "Computer Science Graduate Preferred"
                      }.
2.
3.`}
                      type="text"
                      style={{
                        borderColor: job.preference?.length > 3000 ? "red" : "",
                      }}
                      rows={4}
                      className="input-text"
                      value={job.preference}
                      onChange={handleJobChange}
                      name="preference"
                    ></textarea>
                    {job.preference.length > 3000 && (
                      <p
                        className="text-end"
                        style={{ fontSize: "13px", color: "red" }}
                      >
                        Please limit your input to 3000 characters or less
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="sub-section">
                <div className="title">
                  {job.opportunityType == "Hourly"
                    ? "Hourly Rate"
                    : "Fixed Cost"}
                </div>

                <div className="container fields">
                  {job.opportunityType != "Hourly" ? (
                    <div className="details">
                      <div className="sub-title mb-3">
                        <div className="sub-title mb-3">BUDGET</div>
                      </div>
                      <div className="d-flex flex-column flex-lg-row gap-2 gap-lg-5 align-items-start align-align-items-lg-center ">
                        <div className="d-flex gap-2 align-items-center  justify-content-center">
                          <input
                            type="radio"
                            className="check-input"
                            name="salaryProfile"
                            value="Fixed"
                            onChange={handleJobChange}
                            checked={job.salaryProfile === "Fixed"}
                          />
                          <div className="check-labels" htmlFor="salaryProfile">
                            Fixed
                          </div>
                        </div>
                        <div className="d-flex gap-2 align-items-center justify-content-center">
                          <input
                            type="radio"
                            className="check-input"
                            name="salaryProfile"
                            value="Negotiable"
                            onChange={handleJobChange}
                            checked={job.salaryProfile === "Negotiable"}
                          />
                          <div className="check-labels" htmlFor="salaryProfile">
                            Negotiable
                          </div>
                        </div>
                        <div className="d-flex gap-2 align-items-center justify-content-center">
                          <input
                            type="radio"
                            className="check-input"
                            name="salaryProfile"
                            value="Performance based"
                            onChange={handleJobChange}
                            checked={job.salaryProfile === "Performance based"}
                          />
                          <div className="check-labels" htmlFor="salaryProfile">
                            Peformance based
                          </div>
                        </div>

                        <div className="d-flex gap-2 align-items-center justify-content-center">
                          <input
                            type="radio"
                            className="check-input"
                            name="salaryProfile"
                            value="Unpaid"
                            onChange={handleJobChange}
                            checked={job.salaryProfile === "Unpaid"}
                          />
                          <div className="check-labels" htmlFor="salaryProfile">
                            Unpaid
                          </div>
                        </div>
                      </div>
                      <div>
                        {job.salaryProfile !== "Unpaid" &&
                        job.salaryProfile !== "Performance based" ? (
                          <div className="details">
                            {job?.opportunityType === "Hourly" && (
                              <div className="sub-title mb-3">BUDGET</div>
                            )}
                            <div
                              style={{ marginTop: "16px" }}
                              className="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-4"
                            >
                              <input
                                className="input-text"
                                name="currency"
                                style={{
                                  cursor: "pointer",
                                  maxWidth: "50px",
                                  alignContent: "center",
                                  alignItems: "center",
                                  paddingLeft: "3%",
                                }}
                                value="$"
                                readOnly
                              />
                              <div className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center flex-wrap gap-3">
                                <input
                                  type="number"
                                  min="0"
                                  className="input-text"
                                  placeholder="500"
                                  value={job.salary}
                                  onChange={handleJobChange}
                                  name="salary"
                                  required
                                  style={{ width: "160px" }}
                                />

                                {job.salaryProfile == "Negotiable" && (
                                  <>
                                    <span>to</span>
                                    <input
                                      className="input-text"
                                      name="currency"
                                      style={{
                                        cursor: "pointer",
                                        maxWidth: "50px",
                                        alignContent: "center",
                                        alignItems: "center",
                                        paddingLeft: "3%",
                                      }}
                                      value="$"
                                      readOnly
                                    />
                                    <input
                                      readOnly={!job.salary ? true : false}
                                      type="number"
                                      min={job.salary}
                                      className="input-text"
                                      value={job.salaryMax}
                                      onChange={handleJobChange}
                                      name="salaryMax"
                                      placeholder="1000"
                                      required
                                      style={{ width: "160px" }}
                                    />
                                  </>
                                )}
                                <div className="input-text">Fixed Cost</div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            {job.salaryProfile == "Performance based" ? (
                              <div style={{ marginTop: "16px" }}>
                                <div className="instruction">
                                  For output-driven, performance-based roles
                                  such as Business Development (Sales), etc. we
                                  insist on a minimum assured stipend of $25 per
                                  month for remote positions and $50 per month
                                  for in-office positions, with the potential
                                  for additional incentive-based earnings.
                                </div>
                                <div
                                  className="details"
                                  style={{ marginTop: "16px" }}
                                >
                                  Minimum Assured
                                  <div className="d-flex align-items-center flex-wrap gap-4">
                                    <input
                                      className="input-text"
                                      name="currency"
                                      style={{
                                        cursor: "pointer",
                                        maxWidth: "50px",
                                      }}
                                      value="$"
                                      readOnly
                                    />
                                    <input
                                      type="number"
                                      min="0"
                                      className="input-text"
                                      placeholder="500"
                                      value={job.salary}
                                      onChange={handleJobChange}
                                      name="salary"
                                      required
                                    />
                                    <div className="input-text">Fixed Cost</div>
                                  </div>
                                </div>
                                <div className="details">
                                  Incentive based
                                  <div className="d-flex align-items-center flex-wrap gap-4">
                                    <input
                                      className="input-text"
                                      name="currency"
                                      style={{
                                        cursor: "pointer",
                                        maxWidth: "50px",
                                      }}
                                      value="$"
                                      readOnly
                                    />
                                    <input
                                      type="number"
                                      min="0"
                                      className="input-text"
                                      placeholder="e.g. 750"
                                      value={job.incentive}
                                      onChange={handleJobChange}
                                      name="incentive"
                                      required
                                    />
                                    <span>per</span>
                                    <select
                                      style={{ cursor: "pointer" }}
                                      className="input-text"
                                      value={job.incentiveType}
                                      required
                                      onChange={handleJobChange}
                                      name="incentiveType"
                                    >
                                      <option value="sale">sale</option>
                                      <option value="design">design</option>
                                      <option value="write up">write up</option>
                                      <option value="video">video</option>
                                      <option value="data entry">
                                        data entry
                                      </option>
                                      <option value="others">others</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{ color: "#8A8A8A", marginTop: "16px" }}
                              >
                                We allow unpaid project only in the case of NGOs
                                or niche profiles
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="details">
                        <div className="sub-title mb-3">
                          <div className="sub-title mb-3"> BUDGET</div>
                        </div>
                        <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-4">
                          <input
                            className="input-text"
                            name="currency"
                            style={{
                              cursor: "pointer",
                              maxWidth: "50px",
                              alignContent: "center",
                              alignItems: "center",
                              paddingLeft: "3%",
                            }}
                            value="$"
                            readOnly
                          />
                          <div className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center flex-wrap gap-3">
                            <input
                              type="number"
                              min="0"
                              className="input-text"
                              placeholder="15"
                              value={job.salary}
                              onChange={handleJobChange}
                              name="salary"
                              required
                              style={{ width: "160px" }}
                            />

                            <>
                              <span>to</span>
                              <input
                                className="input-text"
                                name="currency"
                                style={{
                                  cursor: "pointer",
                                  maxWidth: "50px",
                                  alignContent: "center",
                                  alignItems: "center",
                                  paddingLeft: "3%",
                                }}
                                value="$"
                                readOnly
                              />
                              <input
                                readOnly={!job.salary ? true : false}
                                type="number"
                                min={job.salary}
                                className="input-text"
                                value={job.salaryMax}
                                onChange={handleJobChange}
                                name="salaryMax"
                                placeholder="50"
                                required
                                style={{ width: "160px" }}
                              />
                            </>
                            <span className="">Hourly</span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="details">
                    <div className="sub-title mb-3">
                      Perks{" "}
                      <span style={{ fontSize: "13px", color: "#8A8A8A" }}>
                        (Optional)
                      </span>
                    </div>
                    <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-3">
                      <div
                        className="d-flex gap-3 flex-column"
                        style={{ maxWidth: "100%", minWidth: "50%" }}
                      >
                        <div className="d-flex align-items-center gap-3">
                          <input
                            className="check-input"
                            type="checkbox"
                            value="Certificate"
                            onChange={() => handlePerksChange("Certificate")}
                            checked={job?.perks?.includes("Certificate")}
                          />
                          <div className="check-labels" htmlFor="perks">
                            Certificate
                          </div>
                        </div>
                        <div className="d-flex align-items-center gap-3">
                          <input
                            className="check-input"
                            type="checkbox"
                            value="Flexible Work hours"
                            onChange={() =>
                              handlePerksChange("Flexible Work hours")
                            }
                            checked={job?.perks?.includes(
                              "Flexible Work hours"
                            )}
                          />
                          <div className="check-labels" htmlFor="perks">
                            Work hours flexibility
                          </div>
                        </div>
                        <div className="d-flex align-items-center gap-3">
                          <input
                            className="check-input"
                            type="checkbox"
                            value="Informal dress code"
                            onChange={() =>
                              handlePerksChange("Informal dress code")
                            }
                            checked={job?.perks?.includes(
                              "Informal dress code"
                            )}
                          />
                          <div className="check-labels" htmlFor="perks">
                            Informal dress code
                          </div>
                        </div>
                      </div>
                      <div
                        className="d-flex gap-3 flex-column"
                        style={{ maxWidth: "100%", minWidth: "50%" }}
                      >
                        <div className="d-flex align-items-center gap-3">
                          <input
                            className="check-input"
                            type="checkbox"
                            value="Letter of recommendation"
                            onChange={() =>
                              handlePerksChange("Letter of recommendation")
                            }
                            checked={job?.perks?.includes(
                              "Letter of recommendation"
                            )}
                          />
                          <div className="check-labels" htmlFor="perks">
                            Letter of recommendation
                          </div>
                        </div>
                        <div className="d-flex align-items-center gap-3">
                          <input
                            className="check-input"
                            type="checkbox"
                            value="5 days of week"
                            onChange={() => handlePerksChange("5 days of week")}
                            checked={job?.perks?.includes("5 days of week")}
                          />
                          <div className="check-labels" htmlFor="perks">
                            5 days of week
                          </div>
                        </div>
                        <div className="d-flex align-items-center gap-3">
                          <input
                            className="check-input"
                            type="checkbox"
                            value="Free Snacks & beverages"
                            onChange={() =>
                              handlePerksChange("Free Snacks & beverages")
                            }
                            checked={job?.perks?.includes(
                              "Free Snacks & beverages"
                            )}
                          />
                          <div className="check-labels" htmlFor="perks">
                            Free Snacks & beverages
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="sub-section">
                <div className="title">
                  Cover letter, availability & assessment question
                </div>
                <div className="container fields" style={{ gap: "12px" }}>
                  <div className="mb-3" style={{ color: "#8A8A8A" }}>
                    Each applicant will be prompted to provide a cover letter
                    and respond to availability inquiries by default.
                    Additionally, you have the flexibility to include two
                    customized assessment questions, if preferred.
                  </div>
                  <div className="question">Cover letter</div>
                  <div className="answer">
                    Why should you be hired for this project?
                  </div>
                  <div className="question">Availability</div>
                  {/* <div className="answer">
                    Are you able to commit to a{" "}
                    {job.opportunityType == "Hourly"
                      ? month(job.duration) + "-" + job.durationType + ","
                      : ""}{" "}
                    {job.isPartTimeAllowed == "No" ? "full-time" : "part-time"},{" "}
                    {job.jobType == "Remote" ? "work from home" : "freelance"}{" "}
                    {job.opportunityType.toLowerCase()} starting {job.startDate}
                    ?<br></br>
                    If not, please specify the duration you are available for
                    and the earliest date you can commence this internship.
                  </div> */}
                  <div className="answer">
                    Are you able to commit to a{" "}
                    {job.isPartTimeAllowed !== "No" ? "Part time" : "Full time"}{" "}
                    freelancing {job.opportunityType.toLowerCase()} starting{" "}
                    {job.startDate}?<br></br>If not, please specify the duration
                    you are available for and the earliest date you can commence
                    this project.
                  </div>

                  <div
                    className={`d-flex flex-column ask gap-3 ${
                      showQuestion[0] ? "show-question" : "hide-question"
                    }`}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="question">Assessment question 1</div>
                      <div
                        onClick={() => handleRemoveQuestion(0)}
                        style={{ color: "#00a5ec", cursor: "pointer" }}
                      >
                        - Remove
                      </div>
                    </div>
                    <div className="instruction">
                      If applicants are required to upload a document as part of
                      an assessment question, please instruct them to upload it
                      to either Dropbox or Google Drive and share the link in
                      their response.
                    </div>
                    <textarea
                      className="input-text"
                      name="question.q1"
                      onChange={(e) =>
                        setJob((prev) => ({
                          ...prev,
                          question: { ...prev.question, q1: e.target.value },
                        }))
                      }
                      value={job?.question?.q1}
                      rows="5"
                      placeholder="Type your question here"
                    ></textarea>
                  </div>

                  <div
                    className={`d-flex flex-column ask gap-3 ${
                      showQuestion[1] ? "show-question" : "hide-question"
                    }`}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="question">Assessment question 2</div>
                      <div
                        onClick={() => handleRemoveQuestion(0)}
                        style={{ color: "#00a5ec", cursor: "pointer" }}
                      >
                        - Remove
                      </div>
                    </div>
                    <div className="instruction">
                      If applicants are required to upload a document as part of
                      an assessment question, please instruct them to upload it
                      to either Dropbox or Google Drive and share the link in
                      their response.
                    </div>
                    <textarea
                      className="input-text"
                      name="question.q2"
                      onChange={(e) =>
                        setJob((prev) => ({
                          ...prev,
                          question: { ...prev.question, q2: e.target.value },
                        }))
                      }
                      value={job?.question?.q2}
                      rows="5"
                      placeholder="Type your question here"
                    ></textarea>
                  </div>

                  {!(showQuestion[0] && showQuestion[1]) && (
                    <div
                      onClick={() => handleAddQuestion()}
                      style={{
                        color: "#00a5ec",
                        cursor: "pointer",
                        zIndex: "200",
                      }}
                    >
                      + Add assessment question
                    </div>
                  )}
                </div>
              </div>

              <div className="sub-section">
                <div className="fields" style={{ backgroundColor: "#f8f8f8" }}>
                  <div>Alternate mobile number for this listing</div>
                  <span style={{ color: "#8a8a8a" }}>
                    Our team will contact you at this number for any inquiries
                    specifically related to this listing. Please note that the
                    primary account number will not be altered.
                  </span>
                  <div className="d-flex gap-2 align-items-center">
                    <div className="input-text2-container w-25 align-items-center">
                      <p style={{ marginTop: "7px", backgroundColor: "white" }}>
                        +
                      </p>
                      <input
                        type="text"
                        className="input-text2"
                        placeholder="Enter country code "
                        value={countryCode}
                        onChange={(e) => {
                          if (/^\d*$/.test(e.target.value)) {
                            setCountryCode(e.target.value);
                          }
                        }}
                        maxLength={3}
                      />
                    </div>
                    {/* <CountrySelect /> */}
                    <input
                      type="text"
                      value={job.hrContact}
                      name="hrContact"
                      onChange={handleJobChange}
                      className="input-text w-75"
                      placeholder="Enter phone number "
                    />
                  </div>
                </div>
              </div>

              <div className="w-100 d-flex align-items-center justify-content-center justify-content-md-end">
                {loading ? (
                  <>
                    <button
                      style={{
                        backgroundColor: "#B3B3B3",
                        border: "1px solid #B3B3B3",
                        zIndex: "100",
                      }}
                      className="submit-btn px-4 py-2 text-white"
                    >
                      Post Project
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="submit"
                      className="submit-btn px-4 py-2 text-white"
                      style={{ zIndex: "100" }}
                    >
                      Post Project
                    </button>
                  </>
                )}
              </div>

              <Popup
                open={showSuccessPopup}
                closeOnDocumentClick
                onClose={closeDilog}
              >
                <div
                  className="overlay"
                  style={{ background: "rgba(0, 0, 0, 0.4)" }}
                >
                  <div className="popup-container">
                    <div className="close-button123" onClick={closeDilog}>
                      <RxCross1 />
                    </div>
                    {/* <div className="success-message mx-3"><span style={{color: "#029B2E"}}>Congratulations!</span> Your listing has been automatically approved and is now active. You can expect to start receiving applications shortly.</div>
                  <div className="success-message1 mx-3">In the meantime, you can begin inviting suitable candidates whose profiles match your listing requirements.</div> */}
                    <div className="success-message mx-3">
                      <span style={{ color: "#029B2E" }}>Congratulations!</span>{" "}
                      Your listing has been submitted and is now under review.
                      You can expect to start receiving applications shortly.
                    </div>
                    <div className="success-message1 mx-3">
                      In the meantime, you can begin inviting suitable
                      candidates whose profiles match your listing requirements.
                    </div>
                    <div
                      className="d-flex flex-column flex-md-row gap-5"
                      style={{ marginTop: "10px", marginBottom: "20px" }}
                    >
                      <button
                        type="button"
                        className="submit-btn123"
                        onClick={() => handleviepostedjobButton(justCreated)}
                      >
                        View posted job
                      </button>
                      <button
                        type="button"
                        className="submit-btn123"
                        onClick={handlepostnewButton}
                      >
                        Post a new job
                      </button>
                    </div>
                  </div>
                </div>
              </Popup>

              <Popup
                open={showErrorPopup}
                closeOnDocumentClick
                onClose={() => setShowErrorPopup(false)}
              >
                <div
                  className="overlay"
                  style={{ background: "rgba(0, 0, 0, 0.4)" }}
                >
                  <div className="popup-container">
                    <div
                      className="close-button123"
                      onClick={() => setShowErrorPopup(false)}
                    >
                      <RxCross1 />
                    </div>
                    <div
                      className="error-message"
                      style={{ marginTop: "10px" }}
                    >
                      An error occurred. Please try again.
                    </div>
                  </div>
                </div>
              </Popup>

              {/* <Popup open={showModal} closeOnDocumentClick onClose={() => setShowModal(false)}>
              <div className="overlay" style={{ background: "rgba(0, 0, 0, 0.4)" }}>
                <div className="popup-containernew">
                  <div className="close-button12345" onClick={() => setShowModal(false)}>
                    <RxCross1 />
                  </div>
                  <div className="success-message mx-3">Your Connect credit limit has been reached.</div>
                  <div className="success-message1 mx-3">To continue posting, kindly purchase more Connects.</div>
                  <div className="d-flex flex-column flex-md-row gap-5" style={{ marginTop: "10px", marginBottom: "20px" }}>
                    <button type="button" className="submit-btn12345" onClick={handleBuyConnects}>
                      Buy Connects
                    </button>
                  </div>
                </div>
              </div>
            </Popup> */}
            </form>
          </div>
        </div>
      </div>
      <ToastContainer position="top-center" autoClose={10000} />
    </>
  );
};

export default PostInternship;
