import React from 'react'
import { GoDotFill } from 'react-icons/go'

const Faqs = () => {
    return (
        <div className="terms-of-use" style={{ backgroundColor: "white" }}>
            <div style={{ maxWidth: "900px", marginLeft: "auto", marginRight: "auto", paddingTop: "50px", paddingBottom: "50px", textAlign: 'justify' }} className="about-us-mobile-responsive">
                <h1 style={{ fontSize: "32px" }}>FAQs</h1>
                <br />
                <br />
                <h1 style={{ fontSize: "32px", textAlign: 'center' }}>About Workcroft</h1>
                <br />
                <br/>
                <h1 style={{ fontSize: "16px", fontWeight: "bold" }}>What projects can be done on Workcroft?</h1>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    Anything you can do on a computer can be done through Workcroft.
                    <br />
                    <br />
                    Freelancers in our marketplace can tackle a wide range of projects — big or small, one-off or repeat, individual or team-based. Whether you need a content writer for an SEO-friendly blog post, or your own 24/7 software development team to build a mobile app or create a web portal, you’ll find talent on Workcroft ready to support your business.
                </p>
                <br />
                <h1 style={{ fontSize: "16px", fontWeight: "bold" }}>What’s the difference between finding clients online, versus locally?</h1>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    Technology is making online work similar to local work, with added speed, cost, and quality advantages.
                    <br />
                    <br />
                    To begin with, online work can happen wherever there’s a reliable Internet connection — an office, home, café, or rooftop. This also means you can choose who you work with, among a larger pool of people from around the globe.
                    <br />
                    <br />
                    There are some things that don't change when working freelance jobs with clients you find online. Once the project begins, you'll want to communicate openly and clearly, and work to create a successful project for all.
                </p>
                <br />
                <h1 style={{ fontSize: "16px", fontWeight: "bold" }}>How does Workcroft make money?</h1>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    Workcroft charges freelancers a from 20% to 5% service fee depending on the total amount they’ve billed with a client.
                </p>
                <br />
                <br />
                <h1 style={{ fontSize: "32px", textAlign: 'center' }}>About Hiring</h1>
                <br/>
                <br/>
                <h1 style={{ fontSize: "16px", fontWeight: "bold" }}>What should I do after I register?</h1>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    Define your project. Outline your goals, deliverables, the skills you're looking for, and your desired deadline.
                </p>
                <br />
                <h1 style={{ fontSize: "16px", fontWeight: "bold" }}>How do I make my project a success?</h1>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    Start by writing a clear and concise job post.Also, when evaluating candidates, focus on freelancers with proven work on similar projects (we'll highlight a short list of freelancers likely to succeed). Once you’ve selected your freelancer, communicate clearly about your needs.
                </p>
                <br />
                <h1 style={{ fontSize: "16px", fontWeight: "bold" }}>What if I’m not happy with the results?</h1>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    Most projects on Workcroft get completed on time and as expected, but if needed, we’ll step in to offer help.
                    <br />
                    <br />
                    If there’s a disagreement between you and your freelancer, we’ll provide dispute assistance and, if needed, connect you with arbitration.
                </p>
                <br />
                <h1 style={{ fontSize: "16px", fontWeight: "bold" }}>How can I ensure that my IP is safe?</h1>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    The Service Contract terms in our User Agreement state that you own any intellectual property that you pay for, and our Workcroft Terms of Service contain default Non-Disclosure Agreement (NDA) which many clients consider sufficient for this purpose.
                    <br />
                    <br />
                    You can also add additional terms to your job, and have your freelancer sign an additional NDA if needed.
                </p>
                <br />
                <h1 style={{ fontSize: "16px", fontWeight: "bold" }}>How do I know my freelancer is accurately billing for my project?</h1>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    You can evaluate your freelancer's activity through hourly, daily, or weekly reports.
                </p>
                <br />
                <h1 style={{ fontSize: "16px", fontWeight: "bold" }}>Who owns the legal rights to work product developed by a freelancer engaged through Workcroft?</h1>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    Once a freelancer receives payment for work completed on a project, our Terms of Service specify that ownership of that work transfers to the client.
                    <br />
                    <br />
                    If desired, freelancers and clients may also agree on different or additional terms regarding work product ownership.
                </p>
                <br />
                <h1 style={{ fontSize: "16px", fontWeight: "bold" }}>How do I pay my freelancer and what does it cost?</h1>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    You and your freelancer agree upon the price according to your project scope and budget. Choose to pay using MasterCard, Visa, American Express, or PayPal. Any payments are subject to a standard processing processing fee.
                </p>
                <br />
                <h1 style={{ fontSize: "16px", fontWeight: "bold" }}>How often will I be charged?</h1>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    Hourly projects use our automated billing system. Fixed-price projects are divided into agreed-upon milestones.
                    <br />
                    <br />
                    You have two options for working with your freelancer: hourly or fixed-price.
                </p>
                <br />
                <h1 style={{ fontSize: "16px", fontWeight: "bold" }}>Can I get an official invoice?</h1>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    Yes, all payments to freelancers have invoices that can be downloaded or printed.
                </p>
                <br />
                <h1 style={{ fontSize: "16px", fontWeight: "bold" }}>Do I have to file tax forms for my freelancer?</h1>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    You should not be responsible for
                </p>
                <br />
                <h1 style={{ fontSize: "16px", fontWeight: "bold" }}>What do I look for in a freelancer?</h1>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    Most clients look for freelancers who have 1. The skills to get your job done and 2. A track record of satisfying clients.
                    <br />
                    <br />
                    You can answer these questions by reviewing a candidate’s Workcroft profile, which each freelancer has. It can include work experience and samples, client feedback, skills test scores, and much more. You may also want to look for freelancers in our Top Rated and Rising Talent programs. Then, have a quick interview with your top candidates to answer questions related to your project.
                </p>
                <br />
                <h1 style={{ fontSize: "16px", fontWeight: "bold" }}>How do I hire on Workcroft?</h1>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    After you sign up, find a freelancer and click the “Hire” button to get started.
                </p>
                <br />
                <h1 style={{ fontSize: "16px", fontWeight: "bold" }}>Does Workcroft screen freelancers?</h1>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    We have several measures in place to ensure Workcroft is a fair and reliable marketplace.
                    <br />
                    <br />
                    We use multiple means to verify that freelancers are who they say they are. Information is also displayed that gives you a sense of each person’s skill level. In part, this includes:
                    <br />
                    <br />
                    <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                        <GoDotFill className='mr-3' style={{ fontSize: "14px", marginLeft: '25px' }} />
                        Authenticating email addresses
                    </p>
                    <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                        <GoDotFill className='mr-3' style={{ fontSize: "14px", marginLeft: '25px' }} />
                        Displaying each freelancer’s Job Success score and feedback on past projects
                    </p>
                    <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                        <GoDotFill className='mr-3' style={{ fontSize: "14px", marginLeft: '25px' }} />
                        Providing results of online skills tests
                    </p>
                    <br />
                    Ultimately, it’s your responsibility to screen prospective freelancers to make sure their skills and experience meet the needs of your project. Custom screening questions can be included in your job post, and Workcroft Messages provides chat and video conference tools for interviewing finalists.
                </p>
                <br />
                <h1 style={{ fontSize: "16px", fontWeight: "bold" }}>Can Workcroft help find freelancers for me?</h1>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    Yes.
                </p>
                <br />
                <br />

                <h1 style={{ fontSize: "32px", textAlign: 'center' }}>About Freelancing</h1>
                <br/>
                <br/>
                <h1 style={{ fontSize: "16px", fontWeight: "bold" }}>How do I get started freelancing through Workcroft?</h1>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    All you need to get started is a computer, Internet connection, and amazing skills.
                    <br />
                    <br />
                    Your next step is creating an outstanding freelancer profile to showcase your business. Think of it as an introduction, resume, and marketing brochure rolled into one, highlighting your:
                    <br />
                    <br />
                    <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                        <GoDotFill className='mr-3' style={{ fontSize: "14px", marginLeft: '25px' }} />
                        Professional skills, experience, and portfolio
                    </p>
                    <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                        <GoDotFill className='mr-3' style={{ fontSize: "14px", marginLeft: '25px' }} />
                        Education and accomplishments
                    </p>
                    <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                        <GoDotFill className='mr-3' style={{ fontSize: "14px", marginLeft: '25px' }} />
                        Online skills test results
                    </p>
                    <br />
                    <br />
                    The best profiles are complete, well-written, error-free, and feature a professional, friendly-looking picture. Once you have a strong profile, start looking for jobs. Search, submit proposals, interview, repeat. More activity means more job prospects.
                </p>
                <br />
                <h1 style={{ fontSize: "16px", fontWeight: "bold" }}>Who can find freelance opportunities online?</h1>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    Anyone with professional skills and a reliable Internet connection can use Workcroft to find opportunities and build a thriving business.
                    <br />
                    <br />
                    Thousands of jobs are posted daily, offering a variety of work opportunities for those with the right skills.
                </p>
                <br />
                <h1 style={{ fontSize: "16px", fontWeight: "bold" }}>What’s the best way to work with a client online?</h1>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    In many ways, working with a client online is just like working with any other client.
                    <br />
                    <br />
                    To be successful, you should choose work you're qualified for, communicate well with your clients and project team, and take the time to build a great working relationship. How you work with your clients is up to you, but here are a few tips from our marketplace’s most successful freelancers:
                </p>
                <br />
                <h1 style={{ fontSize: "16px", fontWeight: "bold" }}>Choose your projects carefully.</h1>
                <br />
                <br />
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px" }} />
                    When you interview for a freelance project, “interview” your client as well. Ask questions about the project and what result is expected.
                </p>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px" }} />
                    Be sure the needs of the job are in line with the desired deadline, and that you'll have the time to execute well.
                </p>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px" }} />
                    If you end up feeling that the client or project isn't right for you, turn it down and search for more jobs.
                </p>
                <br />

                <h1 style={{ fontSize: "16px", fontWeight: "bold" }}>Start the job strong.</h1>
                <br />
                <br />
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px" }} />
                    Once you're hired, have a conversation with your client to kick off the project. Decide how you will work together and your preferred mode of communication.
                </p>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px" }} />
                    Agree on project deadlines. Make sure not to over promise if you don't think a deadline is reasonable.
                </p>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px" }} />
                    Then, gather all the information you need to get started on the job.
                </p>
                <br />

                <h1 style={{ fontSize: "16px", fontWeight: "bold" }}>Communicate clearly.</h1>
                <br />
                <br />
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px" }} />
                    Be proactive. Even if they don't ask for it, consider communicating with your client at least once per week. Let them know what you're working on, and what your schedule will be for delivering work.
                </p>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px" }} />
                    Respect timelines. Agree on deadlines with your client and make sure you keep them. If you won't be able to meet a deadline, communicate with your client immediately to let them know when you'll be able to complete the work.
                </p>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px" }} />
                    When in doubt, ask. If you have a question, ask. This will keep your work on track and on time.
                </p>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px" }} />
                    Respond quickly. When your client reaches out, consider responding as quickly as you can. Clients may get concerned if they don't hear from a freelancer.
                </p>
                <br />

                <h1 style={{ fontSize: "16px", fontWeight: "bold" }}>Ensure client satisfaction.</h1>
                <br />
                <br />
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px" }} />
                    Consider submitting a small part of the project early on. Ask your client if the work product meets their needs.
                </p>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px" }} />
                    As you submit deliverables, make sure the client is happy with the results before you move on to the next step.
                </p>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px" }} />
                    At the end of the contract, make sure your client has everything they need.
                </p>
                <br />
                <h1 style={{ fontSize: "16px", fontWeight: "bold" }}>How can I find the right freelance projects?</h1>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    Workcroft’s matching and search capabilities help you pinpoint interesting projects.
                    <br />
                    <br />
                    To determine the type of jobs you want to search for, you can make a few decisions about how you want to work:
                    <br />
                    <br />
                    <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                        <GoDotFill className='mr-3' style={{ fontSize: "14px" }} />
                        Do you want a small project or a longer-term contract?
                    </p>
                    <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                        <GoDotFill className='mr-3' style={{ fontSize: "14px" }} />
                        How many hours per week are you available to work?
                    </p>
                    <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                        <GoDotFill className='mr-3' style={{ fontSize: "14px" }} />
                        Do you want to get paid fixed price or by the hour?
                    </p>
                    <br />
                    <br />
                    Now, search Workcroft for jobs that fit your skills and preferences. Read descriptions carefully to determine the ones you are most qualified for and if they meet your criteria.
                    <br />
                    <br />
                    Once you've found a job that's the right fit, send the client a well-written, error-free proposal to increase your chances of success. Customize your proposal to address the needs outlined in the description. Thousands of freelance jobs are posted each day. To stay on top of the ones that interest you, set up a job feed based on your saved searches.
                </p>
                <br />
                <h1 style={{ fontSize: "16px", fontWeight: "bold" }}>What kind of clients can I find on Workcroft?</h1>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    Clients using the Workcroft platform range from Fortune 500 companies to one-person startups.
                    <br />
                    <br />
                    Workcroft clients can be top tech companies building a huge product or small businesses needing a brochure design. They might even be a solopreneur who needs some legal assistance. Whatever your preference, you'll have the opportunity to work with companies you like on projects you love.
                </p>
                <br />
                <h1 style={{ fontSize: "16px", fontWeight: "bold" }}>Why should I freelance through Workcroft?</h1>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    Workcroft gives you the freedom to work anytime, from anywhere. You can quickly and easily grow your freelance business with ongoing opportunities.
                    <br />
                    <br />
                    Workcroft offers three advantages you won't find offline:
                    <br />
                    <br />
                    <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                        <GoDotFill className='mr-3' style={{ fontSize: "14px" }} />
                        Unparalleled opportunity: You'll find 100+ clients all in one place and 1,000+ projects posted each month.
                    </p>
                    <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                        <GoDotFill className='mr-3' style={{ fontSize: "14px" }} />
                        Reputation building: Our client feedback system helps you build your reputation in a way that is verifiable and leads to more business and higher earnings.
                    </p>
                </p>
                <br />
                <h1 style={{ fontSize: "16px", fontWeight: "bold" }}>How much can I earn?</h1>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    That’s up to you. On Workcroft, freelancers set and negotiate their own rates.
                    <br />
                    <br />
                    As with any business, your earning power is often tied to your experience and the demand for your skills. Once you build your reputation on Workcroft, you'll be able to command higher rates from clients who respect your proven skills. In fact, we have found that freelancers on Workcroft, on average, increase their hourly rate almost 60% in the first year and close to 190% over 3 years. With access to worldwide job opportunities, your earning potential is no longer limited by the rates in your local market — you can earn as much as your skills are worth to global clientele.
                </p>
                <br />
                <h1 style={{ fontSize: "16px", fontWeight: "bold" }}>How much does Workcroft cost?</h1>
                <p style={{ fontSize: '14px', lineHeight: "25px" }}>
                    It's free to join Workcroft. We charge 4  to 8 connects for submitting the proposal from freelancers.
                </p>
                <br />
                <br />
            </div>
        </div>
    )
}

export default Faqs