import React, { useState, useEffect } from "react";
import "./css/Support.scss";
import { MdOutlineArrowBack } from "react-icons/md";
import contactusLogo from "../../assets/images/contactus.png";
import mail from "../../assets/images/mail.png";
import chat from "../../assets/images/Vector.png";
import axios from "axios";
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from "../Layout/Spinner";
import { useNavigate, useLocation } from "react-router-dom";

function ContactUs() {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0)
  },[]);

 const { user } = useSelector((state) => state.user);
 const [loading, setLoading] = useState(false);
const [inputData, setInputData] = useState({
    name: "",
    email: "",
    message: "",
    senderId: user && user._id,
});

const navigate = useNavigate();
const handleInput = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value});
  };
  const handleSubmit = async () => {
    setLoading(true);
    if (inputData.name === "") {
      toast.error("Enter name");
      setLoading(false);
    } else if (inputData.email === "") {
      toast.error("Enter email");
      setLoading(false);
    } else if (inputData.message === "") {
      toast.error("Enter message");
      setLoading(false);
    } else {
      try {
        const { data } = await axios.post("/api/contact-us", {
          name: inputData.name,
          email: inputData.email,
          message: inputData.message,
          senderId: user && user._id,
        });
        toast.success(data.message);
        setInputData({
          name: "",
          email: "",
          message: "",
        });
        setLoading(false);
      } catch (err) {
        console.error("Error fetching data: " + err);
        toast.error("Internal server error");
        setLoading(false);
      }
    }
  };
  

    useEffect(() => {
        window.scrollTo(0, 0)
      },[]);
   return (<>
   <div className="py-5" style={{backgroundColor: "white"}}>
   {/*<MdOutlineArrowBack className="mx-5" style={{fontSize: "30px", cursor: "pointer"}}  onClick={() => navigate('/applications')} />*/}
   <h1 className="text-center " style={{fontSize: '30px', fontWeight: '500', color: "#4F4F4F", marginBottom: "-30px"}}>Contact Us</h1>


<div className="whiteScreen">
    <div className="row mx-auto" style={{maxWidth: "1300px"}}>
        <div className="col firstcol">
        <p style={{fontSize: '35px', fontWeight: '500', color: "#4F4F4F", marginTop: "80px"}}>Get in touch</p>
        <p style={{fontSize: '20px', fontWeight: '400', color: "#4F4F4F", marginTop: "10px"}}>Want to get in touch? We’d love to get in touch </p>
        <p style={{fontSize: '20px', fontWeight: '400', color: "#4F4F4F", marginTop: "-14px"}}>with you, Here’s how you can search us</p>
        </div>
        <div className="col secondcol">
         <img src={contactusLogo} alt=".." style={{marginTop: "-5px", height: "200px", width: "auto"}}/>
        </div>
    </div>

</div>
<hr style={{height: "0.1px", marginLeft: "auto", marginRight: "auto", maxWidth: "1300px", textAlign: "center"}}/>
<div className="mx-auto" style={{justifyContent: "center", paddingLeft: '40px', paddingRight: '40px', maxWidth: "1100px"}}>
<div className="row">
<div className="col">
    <p className="contactUsTitle"
    >Name</p>
    <input className="contactUsInput"  type="text"
        name = "name"
        value = {inputData.name}
        onChange={handleInput}
        style={{minWidth: "220px"}}
    />
</div>
<div className="col">
    <p className="contactUsTitle"
    >Your E-mail Address</p>
    <input className="contactUsInput"  type="text"
     name = "email"
     value = {inputData.email}
     onChange={handleInput}
     style={{minWidth: "220px"}}
    />
</div>
</div>
    <p className="contactUsTitle"
    >Tell us what you need help with</p>
    <textarea className="contactUsInput"  rows={4} style={{width: "100%", minWidth: "220px"}}
    name = "message"
    value = {inputData.message}
    onChange={handleInput}
    />


<div className="text-end">
{loading?     
<button className="contactUsBtn" ><Spinner/></button>:
<button className="contactUsBtn" onClick={handleSubmit}>Send Message</button>}
</div>
   
</div>


{/* <p className="supportNote" style={{marginTop: "20px", marginBottom: "-5px"}}><b className="supportNote3">Note:</b> <span className="supportNote4">Rest assured, our team is dedicated to promptly addressing your inquiry. We value your communication and understand its timeliness. Currently, our dedicated team is reviewing your request, and we will respond shortly. If you have any further questions or concerns, please don't hesitate to contact us. Thank you for your patience, and we look forward to assisting you soon!</span></p>
<h4 className="supportNotenew">Address:</h4>
  <div className="supportNotenew2">4/2, E-Extn, Shyam Vihar Phase 1, Najafgarh, Delhi - 110043, India</div>
  <h5 className="supportNotenew">Contact Number:</h5>
  <div className="supportNotenew2">India, Phone: (+91) 7428477468</div> */}

  <p className="supportNotenew2" style={{marginTop:'30px'}}>For any query, please write an email to hello@workcroft.com </p>
  <h4 className="supportNotenew">Information</h4>
  <p className="supportNotenew2">The Workcroft story begins over 6 years ago when a journalist from leading think tanks in North America realized her close friend in India would be perfect for a data science project. The team agreed she was the best choice, but were concerned about working with someone halfway around the globe.</p>
  <h4 className="supportNotenew" style={{display:'flex'}}>Address:<p style={{fontWeight:'400'}}>&nbsp; 4/2, E-Extn, Shyam Vihar Phase 1, Najafgarh, Delhi - 110043, India</p></h4> 
  <h5 className="supportNotenew" style={{display:'flex'}}>Phone Number:<p style={{fontWeight:'400'}}>&nbsp; +91 7428477468</p></h5>
  
  <h5 className="supportNotenew" style={{display:'flex'}}>Email id:<p style={{fontWeight:'400'}}>&nbsp; hello@workcroft.com</p></h5>
  <h5 className="supportNotenew" style={{display:'flex'}}>Website:<p style={{fontWeight:'400'}}><a href="https://workcroft.com">&nbsp; www.workcroft.com</a></p></h5>
   </div>
   <ToastContainer
      position="top-center"
      autoClose={10000}
      />
     </>)
}


export default ContactUs;