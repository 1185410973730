import { Button, Grid, MenuItem, Pagination as MuiPagination, TextField, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import filterLogo from "../../assets/images/Group.png";
import axios from "axios";
import ScaleLoader from "react-spinners/ScaleLoader";
import './css/MatchedJobs.scss';
import Select from 'react-select';
import { Degree } from "../data";
import { AVAILABILITY } from "../data";
import { IoChatboxOutline } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";



function CandidateDetailEmp() {

  const navigate = useNavigate();
  const userType = localStorage.getItem("userType");
  const { user } = useSelector((state) => state.user);
  
  const getData = async () => {
    setLoading(true);
    try {
      const id = user && user._id;
      const { data } = await axios.post(
        `/chats/all-emp/${id}`, 
        { search }, 
        {
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token'),
          },
        }
      );
    setTeamData(data);
    setLoading(false);

    } catch (err) {
      console.error('Error fetching data:' + err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [user]);


  const customStyles = {
    control: (provided, state) => ({
      ...provided, 
      borderRadius: "5px",
      paddingTop: "3px",
      paddingBottom: "3px",
      width: "20vw", 
      maxWidth: "275px"
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      display: 'none',
      borderLeft: "none",
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#ECFFFF' : 'white', // Change hover color here
      color: state.isFocused ? '#3498DB' : 'black', // Change hover color here
      '&:hover': {
        backgroundColor: '#ECFFFF', // Change hover color here
        color: '#3498DB',
      }
    }),
  }


  const customStylesOne = {
    control: (provided, state) => ({
      ...provided, 
      borderRadius: "5px",
      paddingTop: "3px",
      paddingBottom: "3px",
      minWidth: "200px",
      maxWidth: "275px",
      width: "100%",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      display: 'none',
      borderLeft: "none",
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#ECFFFF' : 'white', // Change hover color here
      color: state.isFocused ? '#3498DB' : 'black', // Change hover color here
      '&:hover': {
        backgroundColor: '#ECFFFF', // Change hover color here
        color: '#3498DB',
      }
    }),
  }


  const [profiles, setProfiles] = useState([]);
  const [pdskills, setPdskills] = useState([]);
  useEffect(() => {
    async function getAllProfiles() {
      const response = await axios.get("/api/data/profiles");
      const data = await response.data;
      if (data) setProfiles(data);
    }
    async function getAllSkills() {
      const response = await axios.get("/api/data/skills");
      const data = await response.data;
      if (data) setPdskills(data);
    }

    getAllSkills();
    getAllProfiles();
  }, []);

  const itemsPerPage = 15; // Set the number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const [teamData, setTeamData] = useState("");  
  const [search, setSearch] = useState({
    jobType: [],
    skills: [],
  });  
  const [loading, setLoading] = useState(false);  

  const handleFilter = (e) => {
    const { name, value, type, checked } = e.target;
  
    if (type === "checkbox") {
      // If the event target is a checkbox, handle its state
      const updatedJobType = [...search.jobType];
      if (checked) {
        updatedJobType.push(name);
  
      } else {
        const index = updatedJobType.indexOf(name);
        if (index > -1) {
          updatedJobType.splice(index, 1);
        }
      }
  
      setSearch((prevSearch) => ({
        ...prevSearch,
        jobType: updatedJobType,
      }));
    } else {
      // For other input types, handle normally
      setSearch({
        ...search,
        [name]: value,
      });
    }
  };

 const handleChange=(e)=>{
  setSearch({...search, [e.target.name]: e.target.value});
 } 

const [once, setOnce]=useState(false);

 const clearSearch = async()=>{
  setSearch({...search, 
    profile: "",
    availability: "",
    jobType: [],
    skills: [],
  })
  setOnce(true);
 }


 useEffect(() => {
  if(once){
 getData();
 setOnce(false);
  }
});

 const handleSearch=async()=>{
  await getData();
 }

/*const getData=async()=>{
  setLoading(true);
  try{
    const { data } = await axios.post("/api/user/getuserdata",{search});
    setTeamData(data);
    setLoading(false);
  }
  catch{
    console.log("Server is not running...");
    setLoading(false);
  }
}*/





  
  
    useEffect(() => {
      window.scrollTo(0, 0);
    },[]);


    useEffect(() => {
      getData();
      window.scrollTo(0, 0);
    },[currentPage]);


  // Calculate the total number of pages
  const pageCount = Math.ceil(teamData.length / itemsPerPage);

  // Get the current page data
  const currentData = teamData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page change
  const handlePageChange = (value) => {
    setCurrentPage(value);
  };

 

  return (
    <>

<div className="py-4"  style={{backgroundColor: "rgba(224, 220, 243, 0.212)"}}>
      <Grid item container md={12} xs={12} lg={12} justifyContent="center" alignItems="center"
        mt={5} mb={5}
      >

        <h2 className='borderremove' style={{backgroundColor: "white",  borderRadius: "10px", paddingLeft: '10%', paddingRight: '10%', paddingTop: '1%', paddingBottom: '1%' }}>Applicant Details</h2>
      </Grid>
      <div className='borderremovePc' style={{backgroundColor: "white", borderRadius: "10px", height: 'auto', marginLeft: 'auto', marginRight: 'auto', marginBottom: '5%', borderRadius: "10px", paddingLeft: "30px", maxWidth: '1200px', width: "90%" }}>
        <Grid item container direction='row' spacing={2} >

          <Grid item container xs={12} md={12} justifyContent="center" mt={2} >
           {/* <h1>Filters</h1>*/}
             <img src={filterLogo} alt="Account" style={{width: "25px", height: "25px"}}/>  <h2 style={{marginTop: "2px"}}>Filters</h2>
          </Grid>
          <Grid item container xs={6} md={3} justifyContent="center" >
            <Grid item container xs={12} md={12} justifyContent="start">
              <Typography variant="subtitle1" gutterBottom sx={{ fontSize: '16px', color: '#000' }}>
                Expertise In
              </Typography>

            </Grid>
              <Grid item container xs={12} md={12}>
                {/* <Form.Control onChange={handleChange} name="profile" value={search.profile} placeholder="Search profile" style={{ borderRadius: '5px', border: '1px solid #dddddd', textAlign: 'start', fontSize: "15px", marginTop:"0px", height: "45px" }} />*/}
                <Select
                 styles={customStyles}
                  options={profiles?.map((data) => ({ value: data, label: data }))}
                  placeholder={<div style={{ fontSize: '15px' }}>Search profile</div>}
                  onChange={(selectedOption) => {
                    setSearch({ ...search, profile: selectedOption?.value })
                  }}
                  value={search.profile ? { value: search.profile, label: search.profile } : null}
                />
              </Grid>
            </Grid>
        
          <Grid item container xs={6} md={3} justifyContent="center" >
            <Grid item container xs={12} md={12} justifyContent="start">
              <Typography variant="subtitle1" gutterBottom sx={{ fontSize: '16px', color: '#000' }}>
              Availability
              </Typography>

            </Grid>
            <Grid item container xs={12} md={12}>
           {/* <Form.Control onChange={handleChange} name="degree" value={search.degree} placeholder="Search degree" style={{ borderRadius: '5px', border: '1px solid #dddddd', textAlign: 'start', fontSize: "15px", marginTop:"0px", height: "45px" }} /> */}
           <Select
                 styles={customStyles}
                  options={AVAILABILITY?.map((data) => ({ value: data, label: data }))}
                  placeholder={<div style={{ fontSize: '15px' }}>Search availability</div>}
                  onChange={(selectedOption) => {
                    setSearch({ ...search, availability: selectedOption?.value })
                  }}
                  value={search.availability ? { value: search.availability, label: search.availability } : null}
                />
            </Grid>
          </Grid>


          <Grid item xs={6} md={3} container justifyContent="center">
          <Grid item container xs={12} md={12} justifyContent="start">
              <Typography variant="subtitle1" gutterBottom sx={{ fontSize: '16px', color: '#000' }}>
                Skills
              </Typography>

            </Grid>
              <Grid item container xs={12} md={12}>
                {/*<Form.Control onChange={handleChange} name="skills" value={search.skills} placeholder="Search skills" style={{ borderRadius: '5px', border: '1px solid #dddddd', textAlign: 'start', fontSize: "15px", marginTop:"0px", height: "45px" }} /> */}
                <Select
                  styles={customStyles}
                  options={pdskills?.map((data) => ({ value: data, label: data }))}
                  placeholder={<div style={{ fontSize: '15px' }}>Search skills</div>}
                  onChange={(selectedOptions) => {
                    setSearch({ ...search, skills: selectedOptions.map(option => option.value) })
                  }}
                  value={search.skills ? search.skills.map(skill => ({ value: skill, label: skill })) : null}
                  isMulti
                />

            </Grid>
          </Grid>
          <Grid item xs={6} md={3} container justifyContent="center">
            <Grid item xs={12} md={12} container justifyContent="start">
              <Typography variant="subtitle1" gutterBottom sx={{ fontSize: '16px', color: '#000', paddingLeft: '25px' }}>
                Profile
              </Typography>
            </Grid>
            <div className="row my-2">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div class="form-check">
                  <input class="form-check-input mt-1" type="checkbox" id="Experience" name="Experience" onChange={handleFilter} checked={search.jobType["Experience"]} />
                  <label class="form-check-label " htmlFor="Experience">
                    Experience
                  </label>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div class="form-check">
                  <input class="form-check-input mt-1" type="checkbox" id="Fresher" name="Fresher" onChange={handleFilter} checked={search.jobType["Fresher"]} />
                  <label class="form-check-label "  htmlFor="Fresher">
                    Fresher
                  </label>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item container xs={12} md={12} justifyContent="end" sx={{ paddingRight: '3%', paddingBottom: '2%', paddingTop: '1%' }}>
          <Button onClick={clearSearch} variant='contained' sx={{
              textTransform: 'none', fontSize: '16px', textDecoration: 'none',background: 'none', color: '#4B92C8', '&:hover': { background: '#4B92C8', color: "white" },
            }}>Clear</Button>
            <Button onClick={handleSearch} variant='contained' sx={{
              textTransform: 'none',marginLeft: "20px", fontSize: '16px', textDecoration: 'none', background: '#4B92C8', '&:hover': { background: 'none', color: "#4B92C8" },
            }}>Apply</Button>
          </Grid>
        </Grid>
      </div>



{/*MOBILE FILTER BOX*/}

<div className='borderremoveMob' style={{backgroundColor: "white", borderRadius: "10px", height: 'auto', marginLeft: 'auto', marginRight: 'auto', marginBottom: '5%', borderRadius: "10px", paddingLeft: "30px", maxWidth: '1200px', width: "90%" }}>
        <Grid item container direction='row' spacing={2} >

          <Grid item container xs={12} md={12} justifyContent="center" mt={2} >
           {/* <h1>Filters</h1>*/}
             <img src={filterLogo} alt="Account" style={{width: "25px", height: "25px"}}/>  <h2 style={{marginTop: "2px"}}>Filters</h2>
          </Grid>
          <div className="row my-4 px-4" style={{justifyContent: "center"}}>
          <div className = "col-3 my-4" style={{minWidth: "270px"}}>
            <Grid item container xs={12} md={12} justifyContent="start">
              <Typography variant="subtitle1" gutterBottom sx={{ fontSize: '16px', color: '#000' }}>
                Expertise In
              </Typography>

            </Grid>
              <Grid item container xs={12} md={12}>
                {/* <Form.Control onChange={handleChange} name="profile" value={search.profile} placeholder="Search profile" style={{ borderRadius: '5px', border: '1px solid #dddddd', textAlign: 'start', fontSize: "15px", marginTop:"0px", height: "45px" }} />*/}
                <Select
                 styles={customStylesOne}
                  options={profiles?.map((data) => ({ value: data, label: data }))}
                  placeholder={<div style={{ fontSize: '15px' }}>Search profile</div>}
                  onChange={(selectedOption) => {
                    setSearch({ ...search, profile: selectedOption?.value })
                  }}
                  value={search.profile ? { value: search.profile, label: search.profile } : null}
                />
              </Grid>
            </div>
        
            <div className = "col-3 my-4" style={{minWidth: "270px"}}>
            <Grid item container xs={12} md={12} justifyContent="start">
              <Typography variant="subtitle1" gutterBottom sx={{ fontSize: '16px', color: '#000' }}>
              Availability
              </Typography>

            </Grid>
            <Grid item container xs={12} md={12}>
           {/* <Form.Control onChange={handleChange} name="degree" value={search.degree} placeholder="Search degree" style={{ borderRadius: '5px', border: '1px solid #dddddd', textAlign: 'start', fontSize: "15px", marginTop:"0px", height: "45px" }} /> */}
           <Select
                 styles={customStylesOne}
                  options={AVAILABILITY?.map((data) => ({ value: data, label: data }))}
                  placeholder={<div style={{ fontSize: '15px' }}>Search availability</div>}
                  onChange={(selectedOption) => {
                    setSearch({ ...search, availability: selectedOption?.value })
                  }}
                  value={search.availability ? { value: search.availability, label: search.availability } : null}
                />
            </Grid>
          </div>


          <div className = "col-3 my-4" style={{minWidth: "270px"}}>
          <Grid item container xs={12} md={12} justifyContent="start">
              <Typography variant="subtitle1" gutterBottom sx={{ fontSize: '16px', color: '#000' }}>
                Skills
              </Typography>

            </Grid>
              <Grid item container xs={12} md={12}>
                {/*<Form.Control onChange={handleChange} name="skills" value={search.skills} placeholder="Search skills" style={{ borderRadius: '5px', border: '1px solid #dddddd', textAlign: 'start', fontSize: "15px", marginTop:"0px", height: "45px" }} /> */}
                <Select
                  styles={customStylesOne}
                  options={pdskills?.map((data) => ({ value: data, label: data }))}
                  placeholder={<div style={{ fontSize: '15px' }}>Search skills</div>}
                  onChange={(selectedOptions) => {
                    setSearch({ ...search, skills: selectedOptions.map(option => option.value) })
                  }}
                  value={search.skills ? search.skills.map(skill => ({ value: skill, label: skill })) : null}
                  isMulti
                />

            </Grid>
          </div>
          <div className = "col-3  pl-5 my-4" style={{minWidth: "270px"}}>
            <Grid item xs={12} md={12} container justifyContent="start">
              <Typography variant="subtitle1" gutterBottom sx={{ fontSize: '16px', color: '#000'}}>
                Profile
              </Typography>
            </Grid>
            <div className="row my-2">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div class="form-check">
                  <input class="form-check-input mt-2" type="checkbox" id="Experience" name="Experience" onChange={handleFilter} checked={search.jobType["Experience"]} />
                  <label class="form-check-label " style={{ marginLeft: "-5px" }} htmlFor="Experience">
                    Experience
                  </label>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div class="form-check">
                  <input class="form-check-input mt-2" type="checkbox" id="Fresher" name="Fresher" onChange={handleFilter} checked={search.jobType["Fresher"]} />
                  <label class="form-check-label " style={{ marginLeft: "-5px" }} htmlFor="Fresher">
                    Fresher
                  </label>
                </div>
              </div>
            </div>
          </div>
          </div>
          <Grid item container xs={12} md={12} justifyContent="end" sx={{ paddingRight: '3%', paddingBottom: '2%', paddingTop: '1%', marginBottom: "10px" }}>
          <Button onClick={clearSearch} variant='contained' sx={{
              textTransform: 'none', fontSize: '16px', textDecoration: 'none',background: 'none', color: '#4B92C8', '&:hover': { background: '#4B92C8', color: "white" },
            }}>Clear</Button>
            <Button onClick={handleSearch} variant='contained' sx={{
              textTransform: 'none',marginLeft: "20px", fontSize: '16px', textDecoration: 'none', background: '#4B92C8', '&:hover': { background: 'none', color: "#4B92C8" },
            }}>Apply</Button>
          </Grid>
        </Grid>
      </div>




      <div className='borderremove' style={{backgroundColor: "white", borderRadius: "10px",  marginLeft: 'auto', marginRight: 'auto', marginBottom: "50px", maxWidth: '1200px', width: "90%", overflow: "auto" }}>
        <div style={{width: "100%", minWidth: "900px"}}>
        <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ borderRadius: "10px 10px  0  0 ", fontSize: { md: '17px', sm: '12px', xs: '8px' }, fontWeight: '500', color: '#FFF', paddingTop: '1%', background: '#4B92C8', paddingLeft: "4%" }}>
          <Grid item xs={2} md={2} container justifyContent="start" alignItems="start">
            <p >Name</p>
          </Grid>
          <Grid item xs={2} md={2} container justifyContent="start" alignItems="start">
            <p>Profile</p>
          </Grid>
          {/*<Grid item xs={1} md={1} container justifyContent="start" alignItems="start">
          <p>Grad. Year</p>
        </Grid>
        <Grid item xs={1} md={1} container justifyContent="start" alignItems="start">
          <p>Experience</p>
            </Grid>*/}
          <Grid item xs={2} md={2} container justifyContent="start" alignItems="start">
            <p>Availability</p>
          </Grid>
          <Grid item xs={2} md={2} container justifyContent="start" alignItems="start">
            <p>Skills</p>
          </Grid>
          <Grid item xs={2} md={2} container justifyContent="start" alignItems="start">
            <p>Email</p>
          </Grid>
          <Grid item xs={2} md={2} container justifyContent="start" alignItems="start">
            <p>Contact Number</p>
          </Grid>
        </Grid>

        {/* <Grid container  direction="row"  justifyContent="center" alignItems="center"   sx={{ fontSize: { md: '16px', xs: '12px' }, fontWeight: '500', color: '#000', paddingBottom: '1%', paddingTop: '2%' }}>
      <Grid item xs={2} md={2} container justifyContent='center' alignItems='center'>
        <Form.Control placeholder="Search" style={{ width: '60%', height: '40px', borderRadius: '5px', border: '1px solid #dddddd', textAlign: 'center', fontSize: "15px" }} />
       
      </Grid>
      <Grid item xs={1} md={1} container justifyContent='center' alignItems='center'>
      <Form.Control placeholder="Search" style={{ width: '60%', height: '40px', borderRadius: '5px', border: '1px solid #dddddd', textAlign: 'center', fontSize: "15px" }} />
      </Grid>
      <Grid item xs={1} md={1} container justifyContent='center' alignItems='center'>
       <Form.Control placeholder="Search" style={{ width: '60%', height: '40px', borderRadius: '5px', border: '1px solid #dddddd', textAlign: 'center', fontSize: "15px" }} />
      </Grid>
      <Grid item xs={1} md={1} container justifyContent='center' alignItems='center'>
       <Form.Control placeholder="Search" style={{ width: '60%', height: '40px', borderRadius: '5px', border: '1px solid #dddddd', textAlign: 'center', fontSize: "15px" }} />
      </Grid>
      <Grid item xs={1} md={1} container justifyContent='center' alignItems='center'>
      <Form.Control placeholder="Search" style={{ width: '60%', height: '40px', borderRadius: '5px', border: '1px solid #dddddd', textAlign: 'center', fontSize: "15px" }} />
      </Grid>
      <Grid item xs={2} md={2} container justifyContent='center' alignItems='center'>
       <Form.Control placeholder="Search" style={{ width: '60%', height: '40px', borderRadius: '5px', border: '1px solid #dddddd', textAlign: 'center', fontSize: "15px" }} />
      </Grid>
      <Grid item xs={2} md={2} container justifyContent='center' alignItems='center'>
       <Form.Control placeholder="Search" style={{ width: '60%', height: '40px', borderRadius: '5px', border: '1px solid #dddddd', textAlign: 'center', fontSize: "15px" }} />
      </Grid>
      <Grid item xs={2} md={2} container justifyContent='center' alignItems='center'>
        <Form.Control placeholder="Search" style={{ width: '60%', height: '40px', borderRadius: '5px', border: '1px solid #dddddd', textAlign: 'center', fontSize: "15px" }} />
      </Grid>    
    </Grid> 
      <Grid item xs={12} md={12} >
            <hr style={{ border: '1px solid rgba(175, 175, 175, 0.9)', width: '100%', height: '1px', position: 'relative', bottom: '7px' }} />
        </Grid>
*/}
        {/* Data rows */}
        {loading? 
            <>
          

              <Grid item xs={12} md={12} container justifyContent="start" alignItems="center" style={{ paddingLeft: "5%" }} >
                <div className=" mx-auto" style={{ marginBottom: "20px", marginTop: "20px" }}>
                  <ScaleLoader
                    color={'#4B92C8'}
                    loading={true}
                    size={15}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>
              </Grid>
            </> :
            <>
        <div style={{ marginTop: "10px" }}></div>
        {currentData && currentData.length>0?<>
        {currentData.map((item, index) => (
          <div key={index} >
            <Grid container justifyContent="center" alignItems="center" direction="row" sx={{ fontSize: { md: '15px', xs: '10px' }, fontWeight: '400', color: '#000', cursor: 'pointer', paddingLeft: "4%" }}>
            <Grid item xs={2} md={2} container justifyContent="start" alignItems="start" style={{ overflowWrap: "anywhere", wordBreak: "break-all", paddingRight: "20px" }}>
                <p>{item.participants[1].name}</p>
              </Grid>
              <Grid item xs={2} md={2} container justifyContent="start" alignItems="start" style={{ overflowWrap: "anywhere", wordBreak: "break-all", paddingRight: "20px" }}>
              <p>{item.jobId.profile}</p>  
              </Grid>
              {/* <Grid item xs={1} md={1}  container justifyContent="start" alignItems="start">
                <p>{item.year}</p>
              </Grid>
              <Grid item xs={1} md={1}  container justifyContent="start" alignItems="start">
                <p>{item.experience}</p>
             </Grid>*/}
             <Grid item xs={2} md={2} container justifyContent="start" alignItems="start" style={{ overflowWrap: "anywhere", wordBreak: "break-all", paddingRight: "20px" }}>
                <p>{item.participants[1].availability? item.participants[1].availability : ""}</p>
              </Grid>
              <Grid item xs={2} md={2} container justifyContent="start" alignItems="start" style={{ overflowWrap: "anywhere", wordBreak: "break-all", paddingRight: "20px" }}>
                <p>{item.appliedJobId.resume.Skills && item.appliedJobId.resume.Skills.length>0? <>
                {item.appliedJobId.resume.Skills.map((data)=>(
                  data.name+", "
                ))}
                </> : <>Not available</>}</p>
               
              </Grid>
              <Grid item xs={2} md={2} container justifyContent="start" alignItems="start" style={{ overflowWrap: "anywhere", wordBreak: "break-all", paddingRight: "20px" }}>
               {/*
                {<p>{item.participants[1].email && item.participants[1].email.includes('@') ? item.participants[1].email[0]+item.participants[1].email[1] + '*****'+ item.participants[1].email.slice(item.participants[1].email.indexOf('@')) : ''}</p>}
                */}
                <p>{item.participants[1].email}</p>
                </Grid>


              <Grid item xs={2} md={2} container justifyContent="start" alignItems="start" style={{ overflowWrap: "anywhere", wordBreak: "break-all", paddingRight: "20px" }}>
                <div className="row">
                  <div className="col" style= {{minWidth: "125px"}}>
                {/*
                  <p>{item.participants[1].phone? "*****"+(item.participants[1].phone.slice(6,10)): "Not available"}</p>
                  */}
                  <p>{item.participants[1].phone? item.participants[1].phone: "Not available"}</p>
                  </div>
                    <div className="col">
                    <IoChatboxOutline
                      className="chatIconOfemp"
                      style={{ fontSize: "22px" }}
                      onClick={() => navigate("/clientchat", { state: item })}
                    />
                  </div>
                </div>
                  
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} >
              <hr style={{ border: '1px solid #dadada', width: '100%', height: '1px', }} />
            </Grid>

          </div>
      ))}</>:
      <></>}


     
        {/* Pagination component */}
        <Grid item xs={12} md={12} container justifyContent="end" alignItems="end" sx={{ marginBottom: '1%', paddingRight: '5%' }}>
        {pageCount > 1?
        <>
         {/* 
          <MuiPagination
            count={pageCount}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            size="large"
            //   shape="rounded"
            variant="outlined"
            className="mb-4"
          />
          */}
          </>
          :<>
          <div style={{marginTop: "30px"}}></div>
          </>
      }

        </Grid>
        </>}
      </div>
        </div>
        {pageCount > 1?
        <>
                       <div className="pagination" style={{ marginTop: "-20px" }}>
                        <p onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                          <IoIosArrowBack className={currentPage === 1 ? "paginationBtndisabled" : "paginationBtn" } style={{ marginTop: "6px" }} />
                        </p>
                        <span style={{ fontSize: "16px" }}>
                          {currentPage} / {pageCount}
                        </span>
                        <p onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === pageCount}>
                          <IoIosArrowForward className= {currentPage === pageCount ? "paginationBtndisabled" : "paginationBtn" } style={{ marginTop: "6px" }} />
                        </p>
                      </div>
          </>
          :<>
          </>
      }

      </div>
    </>
  )

}

export default CandidateDetailEmp;