import React, { useState, useEffect } from "react";
import axios from "axios";
import "./AppliedJobReview.scss";
import vector1 from "../../assets/images/Vector.png";
import { Navigate, useLocation } from "react-router-dom";
import { RxDotFilled, RxPencil1 } from "react-icons/rx";
import { MONTH_DETAILS } from "../data";
import { HiOutlinePencil } from "react-icons/hi";
import MonthCalculator from "./MonthCalculator";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import SimpleDialogNew from "../EmployerNew/Employertemplates/ChatDialogNew";
import { addEllipsis, timeAgo } from "../../utils/helper";
import SeeMoreLess from "./SeeMoreLess";
import { MdContentCopy } from "react-icons/md";
import CloseButton from "./CloseButton";
import PropagateLoader from "react-spinners/PropagateLoader";
import PreviewModel from "../Modal/PreviewModel";
import Pagination from "./Pagination";
import { Select } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import { FaFileDownload } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ViewPortfolioprojects from "../Modal/ViewPortfolioprojects";
import { IoArrowRedoCircleOutline } from "react-icons/io5";

const AppliedJobReview = () => {
  const role = localStorage.getItem("userType");
  const [state2, setState2] = useState(null); // Assuming state2 is your state variable
  const [openPopup, setOpenPopup] = useState(false);
  const { appliedJobId } = useParams();
  const [isExpanded, setIsExpanded] = useState(false);
  const [hoverText, setHoverText] = useState("Copy to clipboard!");
  const [linkCopied, setLinkCopied] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [hoveredProject, setHoveredProject] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [bid, setbid] = useState(0);
  const [bidchange, setbidchange] = useState(false);
  useEffect(() => {
    if (state2?.userBid) {
      setbid(state2.userBid);
    }
  }, [state2]);
  const handleUpdateStatus = async (status) => {
    var reqBody = {
      function: status,
      data: [state2._id],
    };

    console.log(reqBody);
    try {
      await axios.post(
        "/api/employer/changeStatusAppliedJob",
        { reqBody },
        {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        }
      );

      toast.success("Status changed successfully");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      toast.error("Something went wrong!!");
      console.log(error);
    }
  };

  const handleupdatebid = async () => {
    try {
      const res = await axios.put(
        `/api/user/updatebid/${state2._id}`,
        {
          userBid: bid,
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        }
      );
      if (res.error) {
        console.log(res.error);
      }
      if (res.status >= 200 && res.status < 300) {
        toast.success("Bid updated successfully");
        setbidchange(false);
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (e) {
      toast.error("Something went wrong!!");
      console.log(e);
    }
  };

  const handleDownload2 = async (fileId, fileName) => {
    try {
      const response = await axios({
        url: `/api/user/getDownloadFile/${fileId}?fileName=${fileName}`, // Your backend endpoint
        method: "GET",
        responseType: "blob", // Important: this ensures the response is processed as a blob (binary data)
      });

      // Create a link element, set its URL to the blob, and trigger the download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      // Cleanup
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
      alert("Failed to download the file.");
    }
  };

  const projectsPerPage = 3;
  const totalPages = Math.ceil(
    state2?.profilePortfolio?.length / projectsPerPage
  );

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(
      state2?.resume?.PersonalDetails?.videoIntroduction
    );
    setLinkCopied(true);
    setHoverText("Copied!");
    setShowTooltip(true);
    setTimeout(() => {
      setHoverText("Copy to clipboard!");
      setLinkCopied(false);
      setShowTooltip(false);
    }, 800);
  };

  const tooltipStyles = {
    position: "absolute",
    top: "100%",
    left: "80%",
    transform: "translateX(-50%)",
    marginBottom: "8px",
    padding: "5px 10px",
    background: "#555",
    color: "#fff",
    borderRadius: "4px",
    fontSize: "12px",
    whiteSpace: "nowrap",
    zIndex: 10,
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Optional: adds shadow to tooltip
  };
  const handleProjectClick = async (project) => {
    //console.log(project, "jfkdk");
    setLoading(true);
    setSelectedProject(null);
    await new Promise((resolve) => setTimeout(resolve, 500));
    setSelectedProject(project);
    setLoading(false);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.post("/api/user/reviewdetails", {
          Id: appliedJobId,
        });
        setState2(data);
        console.log("STATE2", data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [appliedJobId]);

  useEffect(() => {
    console.log(state2);
  }, [state2]);

  const openPreviewPageInNewTab = (state) => {
    const data = state;
    window.open(
      `/preview/${data.profile.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${
        data.opportunityType
      }-at-${data.organisationName.replace(/ /g, "-")}_${
        data._id
      }`.toLowerCase()
    );
  };

  const calculateTotalExperience = (internships, jobs) => {
    let totalMonths = 0;

    // Function to calculate the difference in months between two dates
    function monthDiff(d1, d2) {
      let months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    }

    // Calculate total months for internships
    internships.forEach((internship) => {
      const startDate = new Date(internship.startDate);
      const endDate = new Date(internship.endDate);
      totalMonths += monthDiff(startDate, endDate);
    });

    // Calculate total months for jobs
    jobs.forEach((job) => {
      const startDate = new Date(job.startDate);
      const endDate = new Date(job.endDate);
      totalMonths += monthDiff(startDate, endDate);
    });

    // Calculate years and remaining months
    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;

    // Construct the result string
    let result = "(";
    if (years === 1) {
      result += years + " YEAR ";
    } else if (years > 1) {
      result += years + " YEARS ";
    }

    if (months === 1) {
      result += months + " MONTH";
    } else if (months > 1) {
      result += months + " MONTHS";
    }
    result += ")";

    return result;
  };

  const navigate = useNavigate();

  const calculateDaysDifference = (startDate) => {
    const start = new Date(startDate);
    const end = new Date();

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = end - start;

    // Convert the difference to days
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    // Set the state with the calculated difference
    return differenceInDays;
  };

  const handleChatClick = async (
    userId,
    employerId,
    appliedJobId,
    mainId,
    navigate
  ) => {
    try {
      const response = await axios.post(
        "/api/employer/sendMessagebyclickingchaticon",
        {
          sender: employerId,
          receiver: [[mainId, userId]],
          appliedJobId: appliedJobId,
          // message: '' // You can customize the message content
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        }
      );

      console.log(response.data.data, "wff");
      if (response.status === 200) {
        const roomId = response.data.data;
        navigate("/clientchat", { state: roomId });
      }
    } catch (error) {
      console.error("Error creating chat room:", error);
      // Handle error (e.g., show a notification to the user)
    }
  };

  const getYouTubeEmbedUrl = (url) => {
    try {
      const urlObj = new URL(url);
      let videoId;

      if (urlObj.hostname === "youtu.be") {
        // Shortened YouTube URL (e.g., https://youtu.be/K0pbfQM1huY)
        videoId = urlObj.pathname.substring(1);
      } else if (urlObj.pathname.includes("/shorts/")) {
        // YouTube Shorts URL (e.g., https://youtube.com/shorts/6WKzH7jVhgs)
        videoId = urlObj.pathname.split("/shorts/")[1];
      } else {
        // Standard YouTube URL (e.g., https://www.youtube.com/watch?v=K0pbfQM1huY)
        videoId = urlObj.searchParams.get("v");
      }

      return `https://www.youtube.com/embed/${videoId}`;
    } catch (e) {
      console.error("Invalid URL", e);
      return null;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div
        className="modal fade"
        id="previewmodalresume"
        tabIndex="-1"
        aria-labelledby="previewModalresumeLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog custom-modal-dialog unique-modal-dialog modal-dialog-centered width-settingofmodal">
          <div
            className="modal-content px-3 py-3"
            style={{
              borderRadius: "15px",
              // // marginTop: "10px",
              height: "95vh",
            }}
          >
            <div className="preview-header">
              <h2 style={{ fontSize: "28px" }}>
                {selectedProject?.projectTitle}
              </h2>

              <CloseButton className="btnclosepreview" />
            </div>
            <div
              className="modal-body"
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                overflowY: "auto",
                paddingLeft: "50px",
                paddingRight: "50px",
              }}
            >
              {loading ? (
                <div
                  className=""
                  style={{ textAlign: "center", paddingBottom: "220px" }}
                >
                  <p
                    style={{
                      color: "#4B92C8",
                      fontWeight: "500",
                      fontSize: "20px",
                      marginLeft: "43px",
                      marginBottom: "5px",
                      marginTop: "220px",
                    }}
                  >
                    Loading..
                  </p>
                  <PropagateLoader
                    color={"#4B92C8"}
                    loading={true}
                    size={15}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    style={{ marginLeft: "27px" }}
                  />
                </div>
              ) : (
                selectedProject && (
                  <div className={`fade-in ${!loading ? "show" : ""}`}>
                    <div className="mb-3 flex-container-portfolio">
                      <div
                        className="leftsectionpreviewport"
                        style={{
                          flex: 1,
                          marginRight: "20px",
                          position: "sticky",
                          top: "0",
                          alignSelf: "flex-start",
                        }}
                      >
                        {/* <div
                        style={{ marginTop: "15px", marginBottom: "40px" }}
                      >

                      modfhdjhfujdyfydyfu
                        <h2 style={{ fontSize: "28px" }}>
                          {selectedProject.projectTitle}
                        </h2>
                      </div> */}
                        {selectedProject.yourRole !== "" && (
                          <div style={{ marginBottom: "25px" }}>
                            <p style={{ fontSize: "16px" }}>
                              <b>My role:</b> {selectedProject.yourRole}
                            </p>
                          </div>
                        )}
                        {selectedProject.projectDescription !== "" && (
                          <div style={{ marginBottom: "25px" }}>
                            <p style={{ fontSize: "16px" }}>
                              <b>Project Description:</b>{" "}
                              <span
                                dangerouslySetInnerHTML={{
                                  __html:
                                    selectedProject.projectDescription.replace(
                                      /\n/g,
                                      "<br />"
                                    ),
                                }}
                              />
                              {/* {selectedProject.projectDescription} */}
                            </p>
                          </div>
                        )}
                        <div style={{ marginBottom: "25px" }}>
                          <h4>Skills and deliverables</h4>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                            }}
                          >
                            {selectedProject.skills.map((skill) => (
                              <span
                                key={skill.value}
                                className="post1234forresume mr-4 mt-3"
                              >
                                {skill.label}
                              </span>
                            ))}
                          </div>
                        </div>
                        <hr
                          //className="dropdown-divider1 mb-4 mt-3"
                          style={{
                            height: "0.1px",
                            color: "rgb(185, 185, 185)",
                          }}
                        />
                      </div>
                      <div
                        className="mb-3 rightsectionpreviewport"
                        style={{
                          flex: 2,
                          minHeight: "240px",
                          flexDirection: "column",
                        }}
                      >
                        <PreviewModel portfolioDetails={selectedProject} />

                        <hr
                          className="dropdown-divider1 mb-4 mt-3"
                          style={{
                            height: "0.05px",
                            color: "rgb(185, 185, 185)",
                          }}
                        />
                        <div className="mb-3" style={{ marginTop: "35px" }}>
                          {/* <p style={{ fontSize: "18px", marginBottom: '15px' }}>More by <span style={{ color: "#2092c7" }}>{resumeData.PersonalDetails.firstName} {resumeData.PersonalDetails.lastName}</span></p> */}
                          <p style={{ fontSize: "18px", marginBottom: "15px" }}>
                            More by{" "}
                            <a href={"#"} style={{ color: "#2092c7" }}>
                              {state2?.resume?.PersonalDetails.firstName}{" "}
                              {state2?.resume?.PersonalDetails.lastName}
                            </a>
                          </p>
                          <div className="row">
                            {state2?.profilePortfolio
                              ?.filter(
                                (project) => project._id !== selectedProject._id
                              ) // Exclude selected project
                              .slice(0, 3) // Get next 3 projects
                              .map((project) => (
                                <div
                                  className="col-md-4"
                                  key={project._id}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleProjectClick(project)}
                                >
                                  <div className="card">
                                    <img
                                      className="card-img-top"
                                      src={project.selectedThumbnail.url}
                                      alt={`Project ${project._id}`}
                                    />
                                    <div className="card-body">
                                      <p
                                        className="card-text"
                                        style={{ color: "#2092c7" }}
                                      >
                                        {project.projectTitle}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="reviewpage container-fluid px-5 py-5">
        {state2 ? (
          <>
            <div className="r1 container">
              <div className="section1 mt-4 mb-3 flex items-center">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    className="float-right "
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div
                      className=""
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        {role &&
                          role !== "User" &&
                          state2.status !== "Hired" &&
                          state2.status !== "Shortlisted" && (
                            <>
                              <button
                                className=" shortcut-newappliedjob"
                                style={{ color: "#1177d6", marginLeft: "0px" }}
                                onClick={() =>
                                  handleUpdateStatus("Shortlisted")
                                }
                              >
                                <>
                                  {state2.status !== "Hired" &&
                                    state2.status !== "Shortlisted" && (
                                      <>Shortlist</>
                                    )}
                                </>
                              </button>
                            </>
                          )}
                        {role &&
                          role !== "User" &&
                          state2.status !== "Hired" && (
                            <>
                              <button
                                className=" shortcut-newappliedjob"
                                style={{ color: "#1177d6" }}
                                onClick={() => handleUpdateStatus("Hired")}
                              >
                                <>{state2.status !== "Hired" && <>Hire</>}</>
                              </button>
                            </>
                          )}

                        {role && role !== "User" ? (
                          <button
                            className="shortcut-newappliedjob"
                            // onClick={() => handleChatClick(state2?.user?._id, state2?.employer, state2?.job?._id, state2?._id , navigate)}
                            onClick={() => setOpenPopup(!openPopup)}
                            style={{ color: "#1177d6" }}
                          >
                            Message
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <h3 className="title123 pt-2 " style={{ display: "flex" }}>
                    <div className="" style={{ width: "100%" }}>
                      {role && role == "User" ? (
                        <>
                          {state2.job.profile}
                          <span className="icon mx-4">
                            <IoArrowRedoCircleOutline
                              color="blue"
                              style={{ cursor: "pointer", color: "blue" }}
                              onClick={() =>
                                openPreviewPageInNewTab(state2.job)
                              }
                            />
                          </span>
                        </>
                      ) : (
                        <>
                          <div
                            className=""
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {state2?.resume?.PersonalDetails?.photo?.url !==
                              "" && (
                              <img
                                src={
                                  state2?.resume?.PersonalDetails?.photo?.url
                                }
                                alt="Profile"
                                // style={{ width: '131px',height:'137px', objectFit: 'cover', borderRadius:'50%', border:'2px solid gray'}}
                                className="phototodisplayindesktop"
                                style={{
                                  boxShadow: "0 0 5px rgba(0,0,0,0.2)",
                                  border: "4px solid white",
                                  marginRight: "1rem",
                                }}
                              />
                            )}
                            <div>
                              <a
                                href={`/previewCandidate/${state2?.user?._id}`}
                                target="_blank"
                              >
                                <div style={{ marginBottom: "0.7rem" }}>
                                  {state2.user.name}
                                </div>
                              </a>
                              <p
                                style={{
                                  // marginTop: "-13px",
                                  fontSize: "18px",
                                  color: "black",
                                }}
                              >
                                <b>
                                  {
                                    state2?.resume?.PersonalDetails
                                      ?.professionalTitle
                                  }
                                </b>
                              </p>
                              {state2?.resume?.PersonalDetails?.minimumRate && (
                                <p
                                  style={{
                                    marginTop: "-8px",
                                    fontSize: "17px",
                                    color: "black",
                                  }}
                                >
                                  <b>$</b>
                                  {/* <b>{resumeData.PersonalDetails.minimumRate}</b> */}
                                  <b>
                                    {
                                      state2?.resume?.PersonalDetails
                                        ?.minimumRate
                                    }
                                    {/* {
                                  // Check if the minimumRate is a number and whether it includes a decimal point
                                  resumeData.PersonalDetails.minimumRate %
                                    1 !==
                                  0
                                    ? resumeData.PersonalDetails.minimumRate
                                    : `${resumeData.PersonalDetails.minimumRate}.00`
                                } */}
                                  </b>
                                  <b>/hour</b>
                                </p>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </h3>
                </div>
                {/* {role && role == "User"?  
          <h4 className="companyname22 mt-4 pt-3">{state2.job.organisationName}</h4>:            
          <h4 className="companyname22 mt-4 pt-3">{state2.user.email}</h4> 
                          } */}
                <div>
                  <button className=" btntouch223" style={{ color: "#1177d6" }}>
                    {state2 && state2.status === "Application_Sent" ? (
                      <>Applied</>
                    ) : (
                      <>
                        {state2.status === "NotIntrested" ? (
                          <>Not Intrested</>
                        ) : (
                          <>
                            {state2.status === "Assignment" ? (
                              <>Assignment Sent</>
                            ) : (
                              <>
                                {state2.status === "Interviews" ? (
                                  <>Interview Scheduled</>
                                ) : (
                                  <>{state2.status}</>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </button>
                  {role && role !== "User" ? (
                    <button
                      className="shortcut-newappliedjob1"
                      //  onClick={() => handleChatClick(state2?.user?._id, state2?.employer, state2?.job?._id, state2?._id , navigate)}
                      onClick={() => setOpenPopup(!openPopup)}
                    >
                      Message
                    </button>
                  ) : null}
                </div>
                <div className="">
                  {state2?.resume?.PersonalDetails?.videoIntroduction && (
                    <>
                      <p
                        style={{
                          fontWeight: "500",
                          marginTop: "20px",
                          fontSize: "22px",
                          color: "black",
                        }}
                      >
                        Video Introduction
                      </p>
                      {/* <p style={{ marginTop: '-6px', fontSize: "17px",}}><a href={resumeData.PersonalDetails.videoIntroduction} target='__blank' style={{textDecoration:'none', display:'flex', gap:'10px',alignItems:'center'}}>
                {'Your Video Introduction'} <FaLink />
                </a></p> */}

                      <p
                        style={{
                          marginTop: "-6px",
                          fontSize: "17px",
                          display: "flex",
                        }}
                      >
                        <a
                          href={
                            state2?.resume?.PersonalDetails?.videoIntroduction
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            textDecoration: "none",
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          {"Your Video Introduction"}
                        </a>
                        <span
                          onClick={handleCopyToClipboard}
                          onMouseEnter={() => {
                            if (!linkCopied) setHoverText("Copy to clipboard!");
                            setShowTooltip(true); // Show tooltip on hover
                          }}
                          onMouseLeave={() => setShowTooltip(false)} // Hide tooltip when mouse leaves
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                            marginLeft: "10px",
                            position: "relative",
                            color: "#2092c7",
                          }}
                        >
                          <MdContentCopy />
                          {showTooltip && (
                            <div style={tooltipStyles}>{hoverText}</div>
                          )}
                        </span>
                      </p>
                      <div className="video-wrapperresumevideo">
                        <iframe
                          // width="560"
                          // height="315"
                          src={getYouTubeEmbedUrl(
                            state2?.resume?.PersonalDetails?.videoIntroduction
                          )}
                          title="Video Introduction"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </>
                  )}
                  {state2?.resume?.PersonalDetails?.profileCategories && (
                    <p
                      style={{
                        fontWeight: "500",
                        marginTop: "18px",
                        fontSize: "22px",
                        color: "black",
                      }}
                    >
                      Profile Category
                    </p>
                  )}
                  {state2?.resume?.PersonalDetails?.profileCategories.map(
                    (category, index) => (
                      <div key={category.id} style={{ marginTop: "10px" }}>
                        <p
                          style={{
                            fontSize: "17px",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        >
                          {category?.profile}
                        </p>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          {category?.specializations?.map((spec, specIndex) => (
                            <span
                              key={specIndex}
                              className="post1234forresume mr-4 mt-3"
                              style={{ marginBottom: "10px" }}
                            >
                              {spec}
                            </span>
                          ))}
                        </div>
                      </div>
                    )
                  )}
                  {state2?.resume?.PersonalDetails?.profileSummary && (
                    <div
                      style={{
                        marginTop: "10px",
                        fontSize: "15px",
                        color: "black",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "500",
                          marginTop: "18px",
                          fontSize: "22px",
                          color: "black",
                        }}
                      >
                        Profile Summary
                      </p>
                      <p
                        style={{
                          marginTop: "-6px",
                          fontSize: "17px",
                          color: "black",
                        }}
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: isExpanded
                              ? state2?.resume?.PersonalDetails?.profileSummary?.replace(
                                  /\n/g,
                                  "<br />"
                                )
                              : state2?.resume?.PersonalDetails?.profileSummary
                                  .slice(0, 440)
                                  .replace(/\n/g, "<br />"),
                          }}
                        />
                        {/* {isExpanded ? resumeData.PersonalDetails.profileSummary : resumeData.PersonalDetails.profileSummary.slice(0, 600)} */}
                        {state2?.resume?.PersonalDetails?.profileSummary
                          .length > 440 && (
                          <>
                            {/* <span
        onClick={() => setIsExpanded(!isExpanded)}
        style={{ color: '#2092c7', cursor: 'pointer', marginLeft: '5px' ,textDecoration:'underline'}}
      >
        {isExpanded ? 'less' : '...more'}
      </span> */}
                            {!isExpanded && <span>...</span>}
                            <span
                              onClick={() => setIsExpanded(!isExpanded)}
                              style={{
                                color: "#2092c7",
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                            >
                              <br />
                              {isExpanded ? "less" : "more"}
                            </span>
                          </>
                        )}
                      </p>
                    </div>
                  )}
                  {state2?.resume?.PersonalDetails?.availability && (
                    <div
                      style={{
                        marginTop: "10px",
                        fontSize: "15px",
                        color: "black",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "500",
                          marginTop: "18px",
                          fontSize: "22px",
                          color: "black",
                        }}
                      >
                        Availability
                      </p>
                      <p
                        style={{
                          marginTop: "-6px",
                          fontSize: "17px",
                          color: "black",
                        }}
                      >
                        {state2?.resume?.PersonalDetails?.availability}
                      </p>
                    </div>
                  )}
                </div>

                <hr
                  className=" mb-4 mt-5"
                  style={{ height: "3.05px", color: "#000" }}
                />
                <div className="">
                  <div className="" style={{ minWidth: "150px" }}>
                    <p
                      style={{
                        color: "black",
                        fontSize: "32px",
                        fontWeight: "500",
                      }}
                    >
                      Portfolio
                    </p>
                  </div>
                  <div id="published" className="tab-pane active">
                    <div className="row">
                      {state2?.profilePortfolio?.map((project) => (
                        <div
                          className="col-md-6"
                          key={project?.id}
                          style={{
                            cursor: "pointer",
                            positon: "relative",
                          }}
                          onMouseEnter={() => setHoveredProject(project?._id)}
                          onMouseLeave={() => setHoveredProject(null)}
                        >
                          <div className="card portcard">
                            {/* <img className="card-img-top"  src={project.selectedThumbnail.url} alt={`Project ${project._id}`} /> */}
                            <img
                              className={`card-img-top project-image ${
                                hoveredProject === project?._id
                                  ? "dim-image"
                                  : ""
                              }`}
                              src={project?.selectedThumbnail?.url}
                              alt={`Project ${project._id}`}
                            />
                            <div className="card-body">
                              <p
                                className="card-text project-title"
                                style={{ color: "#2092c7" }}
                                data-bs-toggle="modal"
                                data-bs-target="#previewmodalresume"
                                onClick={() => {
                                  setSelectedProject(project);
                                  console.log(project, "ppp");
                                  // let prev = generateDataInSequence(
                                  //   project?.mediaSequence,
                                  //   project
                                  // );
                                  // setPortfolioDetailsData(
                                  //   project?.mediaSequence
                                  // );
                                  // console.log(prev, "preview data");
                                }}
                              >
                                {project?.projectTitle}
                              </p>
                            </div>
                            {console.log("sdfs", project)}

                            {/* {hoveredProject === project._id && (
                                          <RiDeleteBinLine
                                            className="delete-project-draft"
                                            style={{ position: 'absolute', top: '50px', right: '80px' }}
                                            onClick={() => {
                                              setSelectedProject(project);
                                              setOpenPopupDelete(true);
                                            }}
                                          />
                                        )} */}
                          </div>
                        </div>
                      ))}
                    </div>
                    {/* custom paginATION */}
                    {/* <div>
                      {totalPages !== 0 && (
                        <Pagination
                          totalPages={totalPages}
                          currentPage={activePage}
                          onPageChange={setActivePage}
                        />
                      )}
                    </div> */}
                    {/* <nav aria-label="Page navigation">
                            <ul className="pagination pagination-navigation">
                              <li
                                className={`page-item ${
                                  activePage === 1 ? "disabled" : ""
                                }`}
                              >
                                <div
                                  className="page-link"
                                  onClick={() =>
                                    setActivePage((prev) =>
                                      Math.max(prev - 1, 1)
                                    )
                                  }
                                  style={{
                                    borderRadius: "50%",
                                    color:
                                      activePage === 1 ? "gray" : "#2092c7",
                                    pointerEvents:
                                      activePage === 1 ? "none" : "auto",
                                    cursor:
                                      activePage === 1
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                >
                                  <IoIosArrowBack />
                                </div>
                              </li>
                              <li
                                className={`page-item ${
                                  activePage === 1 ? "disabled" : ""
                                }`}
                              >
                                <div
                                  className="page-link"
                                  onClick={() =>
                                    setActivePage((prev) =>
                                      Math.max(prev - 1, 1)
                                    )
                                  }
                                  style={{
                                    borderRadius: "50%",
                                    color:
                                      activePage === 1 ? "gray" : "#2092c7",
                                    pointerEvents:
                                      activePage === 1 ? "none" : "auto",
                                    cursor:
                                      activePage === 1
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                >
                                  Previous
                                </div>
                              </li>
                              
                              {[...Array(totalPages)].map((_, page) => (
                                <li
                                  key={page}
                                  className="page-item"
                                  onClick={() => setActivePage(page + 1)}
                                >
                                  <div
                                    className="page-link"
                                    style={{
                                      backgroundColor:
                                        activePage === page + 1
                                          ? "#2092c7"
                                          : "transparent",
                                      color:
                                        activePage === page + 1
                                          ? "white"
                                          : "#2092c7",
                                      borderRadius: "50%",
                                      height: "25px",
                                      width: "25px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {page + 1}
                                  </div>
                                </li>
                              ))}
                              <li
                                className={`page-item ${
                                  activePage === totalPages
                                    ? "disabled"
                                    : ""
                                }`}
                              >
                                <div
                                  className="page-link"
                                  onClick={() =>
                                    setActivePage((next) =>
                                      Math.min(next + 1, totalPages)
                                    )
                                  }
                                  style={{
                                    borderRadius: "50%",
                                    color:
                                      activePage === totalPages
                                        ? "gray"
                                        : "#2092c7",
                                    pointerEvents:
                                      activePage === totalPages
                                        ? "none"
                                        : "auto",
                                    cursor:
                                      activePage === totalPages
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                >
                                  Next
                                </div>
                              </li>
                              <li
                                className={`page-item ${
                                  activePage === totalPages
                                    ? "disabled"
                                    : ""
                                }`}
                              >
                                <div
                                  className="page-link"
                                  onClick={() =>
                                    setActivePage((next) =>
                                      Math.min(next + 1, totalPages)
                                    )
                                  }
                                  style={{
                                    borderRadius: "50%",
                                    color:
                                      activePage === totalPages
                                        ? "gray"
                                        : "#2092c7",
                                    pointerEvents:
                                      activePage === totalPages
                                        ? "none"
                                        : "auto",
                                    cursor:
                                      activePage === totalPages
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                >
                                  <IoIosArrowForward />
                                </div>
                              </li>
                            </ul>
                          </nav> */}
                  </div>
                </div>
              </div>

              <div className="section3 my-4 mt-5">
                <div className="heading  heeding0001">
                  <h3
                    className="title pt-2"
                    style={{ fontSize: "25px", letterSpacing: "0.5px" }}
                  >
                    Application
                  </h3>

                  <h3
                    className="pcTittitle pt-2 ml-auto float-right"
                    style={{ letterSpacing: "0.5px" }}
                  >
                    <span
                      style={{ fontSize: "14px", marginTop: "-5px" }}
                      className="aplied12 "
                    >
                      {" "}
                      {"Applied "}
                      <span>
                        {calculateDaysDifference(state2.appliedDate).toFixed(
                          0
                        ) == 0 ? (
                          "Today"
                        ) : (
                          <>{timeAgo(state2.appliedDate)}</>
                        )}
                      </span>{" "}
                    </span>
                  </h3>
                </div>
                <div className=" py-3 px-3 mx-4 ">
                  <h3 className=" my-3">Cover letter</h3>
                  <p className=" mt-4 mb-2" style={{ fontWeight: "500" }}>
                    Why should you be hired for this project?
                  </p>
                  <p className="mt-2 mb-3" style={{ whiteSpace: "pre-wrap" }}>
                    {state2.coverLetter}
                  </p>

                  <h3 className=" mb-3 mt-5">
                    {role && role == "User" ? "Client" : "Your"} Budget
                  </h3>
                  <p className=" mt-2 mb-3">
                    ${state2?.job.salary}
                    {state2?.job.salaryMax !== null &&
                      ` - $${state2.job.salaryMax} `}{" "}
                    {state2?.job?.opportunityType !== "Hourly"
                      ? `Fixed Cost`
                      : `per hour`}
                  </p>
                  <h3 className=" mb-3 mt-5">Proposed Bid </h3>
                  <div
                    className=""
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <p
                      className=" mt-2 mb-3"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      $
                      {bidchange && role === "User" ? (
                        <div className="" style={{ display: "flex" }}>
                          &nbsp;
                          <input
                            type="number"
                            value={bid}
                            disabled={!bidchange}
                            style={{ paddingLeft: "10px" }}
                            onChange={(e) => setbid(e.target.value)}
                          />
                          {bidchange && role === "User" && (
                            <div className="">
                              <button
                                style={{
                                  background: "dodgerblue",
                                  color: "white",
                                  border: "none",
                                  padding: "5px",
                                }}
                                onClick={handleupdatebid}
                              >
                                Update
                              </button>
                            </div>
                          )}
                          &nbsp;
                        </div>
                      ) : (
                        <>{bid}</>
                      )}
                      {state2?.job?.opportunityType !== "Hourly"
                        ? ` Fixed Cost`
                        : ` per hour`}
                    </p>
                    {role === "User" && (
                      // <HiOutlinePencil
                      //   style={{
                      //     fontSize: "18px",
                      //     marginTop: "-2px",
                      //     cursor: "pointer",
                      //   }}
                      //   onClick={() => setbidchange(!bidchange)}
                      // />
                      <RxPencil1
                        className="mx-2 resumePeronalDetailpencilicon"
                        style={{
                          color: "#2092c7",
                          cursor: "pointer",
                          fontSize: "25px",
                          marginBottom: "1rem",
                        }}
                        onClick={() => setbidchange(!bidchange)}
                      />
                    )}
                  </div>
                  <h3 className=" mb-3 mt-5">Project Category</h3>
                  <p className=" mt-2 mb-3">{state2?.job.projectCategory}</p>

                  <h3 className=" mb-3 mt-5">Availability</h3>
                  <p className=" my-3">
                    {state2?.availability == true ? (
                      <>
                        {" "}
                        Yes, I am available for {state2?.job?.duration}{" "}
                        {state2?.job?.durationType} starting immediately for a{" "}
                        {state2?.job?.isPartTimeAllowed === "yes" ||
                        state2?.job?.isPartTimeAllowed === "Yes" ||
                        state2?.job?.isPartTimeAllowed === "YES" ? (
                          <>part-time</>
                        ) : (
                          <>full-time</>
                        )}{" "}
                        {state2?.job?.opportunityType.toLowerCase()}
                      </>
                    ) : (
                      <> No, {state2?.specifyAvailability}</>
                    )}
                  </p>

                  {state2.assessment && state2.assessment.length > 0 ? (
                    <>
                      <h3 className=" mb-4 mt-5">Assessment</h3>

                      {state2.assessment.map((data, index) => (
                        <>
                          <p
                            className=" mt-4 mb-2"
                            style={{ fontWeight: "500" }}
                          >
                            Q{index + 1}. {data.Question}
                          </p>
                          <p className=" mb-3 mt-2">{data.Answer}</p>
                        </>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              {state2.resume.PersonalDetails ? (
                <>
                  <div
                    className="section3 my-4 mt-5 "
                    style={{ letterSpacing: "0.7px" }}
                  >
                    <div
                      className="heading"
                      style={{ display: "flex", flexWrap: "wrap" }}
                    >
                      <h3
                        className="title pt-2"
                        style={{
                          fontSize: "25px",
                          letterSpacing: "0.5px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        Resume
                      </h3>
                      {/* <h3
                        className="title pt-2 ml-auto float-right"
                        style={{
                          fontSize: "25px",
                          letterSpacing: "0.5px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        onClick={() => navigate("/addResume")}
                      >
                        {role && role == "User" ? (
                          <>
                            <span
                              style={{ fontSize: "15px" }}
                              className="aplied212 float-right"
                            >
                              <HiOutlinePencil
                                style={{ fontSize: "18px", marginTop: "-2px" }}
                              />{" "}
                              Edit
                            </span>
                          </>
                        ) : (
                          <></>
                        )}
                      </h3> */}
                    </div>
                    <div className=" py-5 px-3">
                      {/*<div class="container">
                <h1>{state2.resume.PersonalDetails.firstName} {state2.resume.PersonalDetails.lastName}</h1>
                <div style={{ color: 'gray' }}>
                  <p>{state2.resume.PersonalDetails.email}</p>
                  <p style={{ marginTop: '-10px' }}>+ 91 {state2.resume.PersonalDetails.phone}</p>
                  <p style={{ marginTop: '-10px' }}>{state2.resume.PersonalDetails.address}</p>
                </div>
          </div>
        <hr class="dropdown-divider my-5 " />*/}

                      {state2.resume.Education.length != 0 ? (
                        <>
                          <div class="container">
                            <div class="row">
                              <div class="col-3" style={{ minWidth: "150px" }}>
                                <p
                                  style={{
                                    color: "#6D6D6D",
                                    fontSize: "13px",
                                    letterSpacing: "0.5px",
                                    fontWeight: "500",
                                  }}
                                >
                                  EDUCATION
                                </p>
                              </div>
                              <div class="col-9">
                                {state2.resume.Education.map((data, index) => {
                                  return (
                                    <>
                                      {" "}
                                      <div
                                        key={index}
                                        style={{
                                          marginBottom: "30px",
                                          color: "#4A4E50",
                                        }}
                                      >
                                        <p
                                          style={{
                                            fontSize: "15px",
                                            color: "#404040",
                                          }}
                                        >
                                          <b>{data.degree}</b>
                                        </p>
                                        <div
                                          style={{
                                            color: "#6E6E6E",
                                            fontSize: "15px",
                                            marginTop: "-8px",
                                          }}
                                        >
                                          <p>{data.school}</p>
                                          {data.specialization ? (
                                            <p style={{ marginTop: "-8px" }}>
                                              ({data.specialization})
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                          <p style={{ marginTop: "-8px" }}>
                                            {data.startDate.slice(0, 4)} -{" "}
                                            {data.pursuing
                                              ? "Present"
                                              : data.endDate.slice(0, 4)}
                                          </p>
                                          <p style={{ marginTop: "-8px" }}>
                                            {data.performanceScale}:{" "}
                                            {data.performance}%
                                          </p>
                                        </div>
                                      </div>{" "}
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          <hr class="dropdown-divider mb-5 mt-3" />
                        </>
                      ) : (
                        <></>
                      )}

                      {state2.resume.Job.length != 0 ? (
                        <>
                          <div class="container">
                            <div class="row">
                              <div class="col-3" style={{ minWidth: "150px" }}>
                                <p
                                  style={{
                                    color: "#6D6D6D",
                                    fontSize: "13px",
                                    letterSpacing: "0.5px",
                                    fontWeight: "500",
                                    lineHeight: "16px",
                                  }}
                                >
                                  WORK EXPERIENCE{" "}
                                  {calculateTotalExperience(
                                    state2.resume.Job,
                                    state2.resume.Internship
                                  )}
                                </p>
                              </div>
                              <div class="col-9">
                                {state2.resume.Job.map((data, index) => {
                                  return (
                                    <>
                                      {" "}
                                      <div
                                        key={index}
                                        style={{ marginBottom: "30px" }}
                                      >
                                        <p
                                          style={{
                                            fontSize: "15px",
                                            color: "#404040",
                                          }}
                                        >
                                          <b>{data.profile} </b>
                                        </p>
                                        <div
                                          style={{
                                            color: "#6E6E6E",
                                            fontSize: "15px",
                                            marginTop: "-8px",
                                          }}
                                        >
                                          <p style={{ marginTop: "-8px" }}>
                                            {data.organization}, {data.location}
                                          </p>
                                          <p style={{ marginTop: "-8px" }}>
                                            Job{" "}
                                            <RxDotFilled
                                              style={{ color: "#464545" }}
                                            />
                                            {MONTH_DETAILS[
                                              parseInt(
                                                data.startDate.slice(5, 7),
                                                10
                                              )
                                            ] +
                                              " " +
                                              data.startDate.slice(0, 4)}{" "}
                                            -{" "}
                                            {MONTH_DETAILS[
                                              parseInt(
                                                data.endDate.slice(5, 7),
                                                10
                                              )
                                            ] +
                                              " " +
                                              data.endDate.slice(0, 4)}{" "}
                                            {
                                              <MonthCalculator
                                                startDate={data.startDate}
                                                endDate={data.endDate}
                                              />
                                            }
                                          </p>
                                          <p style={{ marginTop: "-8px" }}>
                                            <SeeMoreLess
                                              text={data?.description}
                                              maxLength={350}
                                            />
                                          </p>
                                        </div>
                                      </div>{" "}
                                    </>
                                  );
                                })}
                                {state2.resume.Internship.map((data) => {
                                  return (
                                    <>
                                      <div
                                        style={{
                                          marginBottom: "30px",
                                          color: "#2C2C2C",
                                        }}
                                      >
                                        <p
                                          style={{
                                            fontSize: "15px",
                                            color: "#404040",
                                          }}
                                        >
                                          <b>{data.profile} </b>
                                        </p>
                                        <div
                                          style={{
                                            color: "#6E6E6E",
                                            fontSize: "15px",
                                            marginTop: "-8px",
                                          }}
                                        >
                                          <p style={{ marginTop: "-8px" }}>
                                            {data.organization}, {data.location}
                                          </p>
                                          <p style={{ marginTop: "-8px" }}>
                                            Internship{" "}
                                            <RxDotFilled
                                              style={{ color: "#464545" }}
                                            />{" "}
                                            {MONTH_DETAILS[
                                              parseInt(
                                                data.startDate.slice(5, 7),
                                                10
                                              )
                                            ] +
                                              " " +
                                              data.startDate.slice(0, 4)}{" "}
                                            -{" "}
                                            {MONTH_DETAILS[
                                              parseInt(
                                                data.endDate.slice(5, 7),
                                                10
                                              )
                                            ] +
                                              " " +
                                              data.endDate.slice(0, 4)}{" "}
                                            {
                                              <MonthCalculator
                                                startDate={data.startDate}
                                                endDate={data.endDate}
                                              />
                                            }
                                          </p>
                                          <p style={{ marginTop: "-8px" }}>
                                            <SeeMoreLess
                                              text={data?.description}
                                              maxLength={350}
                                            />
                                          </p>
                                        </div>
                                      </div>{" "}
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          <hr class="dropdown-divider mb-5 mt-3" />
                        </>
                      ) : (
                        <></>
                      )}
                      {/* 
                      {state2.resume.Training.length != 0 ? (
                        <>
                          <div class="container">
                            <div class="row">
                              <div class="col-3" style={{ minWidth: "150px" }}>
                                <p
                                  style={{
                                    color: "#6D6D6D",
                                    fontSize: "13px",
                                    letterSpacing: "0.5px",
                                    fontWeight: "500",
                                    lineHeight: "16px",
                                  }}
                                >
                                  TRAININGS/
                                  <br />
                                  COURSES
                                </p>
                              </div>
                              <div class="col-9">
                                {state2.resume.Training.map((data) => {
                                  return (
                                    <>
                                      {" "}
                                      <div style={{ marginBottom: "30px" }}>
                                        <p
                                          style={{
                                            fontSize: "15px",
                                            color: "#404040",
                                          }}
                                        >
                                          <b>{data.program}</b>
                                        </p>
                                        <div
                                          style={{
                                            color: "#6E6E6E",
                                            fontSize: "15px",
                                            marginTop: "-8px",
                                          }}
                                        >
                                          <p style={{ marginTop: "-8px" }}>
                                            {data.organization}, {data.location}
                                          </p>
                                          <p style={{ marginTop: "-8px" }}>
                                            {MONTH_DETAILS[
                                              parseInt(
                                                data.startDate.slice(5, 7),
                                                10
                                              )
                                            ] +
                                              " " +
                                              data.startDate.slice(0, 4)}{" "}
                                            -{" "}
                                            {MONTH_DETAILS[
                                              parseInt(
                                                data.endDate.slice(5, 7),
                                                10
                                              )
                                            ] +
                                              " " +
                                              data.endDate.slice(0, 4)}
                                          </p>
                                          <p style={{ marginTop: "-8px" }}>
                                            <SeeMoreLess
                                              text={data?.description}
                                              maxLength={350}
                                            />
                                          </p>
                                        </div>
                                      </div>{" "}
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          <hr class="dropdown-divider mb-5 mt-3" />
                        </>
                      ) : (
                        <></>
                      )}

                      {state2.resume.Academics.length != 0 ? (
                        <>
                          <div class="container">
                            <div class="row">
                              <div class="col-3" style={{ minWidth: "150px" }}>
                                <p
                                  style={{
                                    color: "#6D6D6D",
                                    fontSize: "13px",
                                    letterSpacing: "0.5px",
                                    fontWeight: "500",
                                  }}
                                >
                                  ACADEMICS/
                                  <br /> PERSONAL
                                  <br /> PROJECTS
                                </p>
                              </div>
                              <div class="col-9">
                                {state2.resume.Academics.map((data) => {
                                  return (
                                    <>
                                      {" "}
                                      <div
                                        style={{
                                          marginBottom: "30px",
                                          color: "#2C2C2C",
                                        }}
                                      >
                                        <p
                                          style={{
                                            fontSize: "15px",
                                            color: "#404040",
                                          }}
                                        >
                                          <b>{data.title}</b>
                                        </p>
                                        <div
                                          style={{
                                            color: "#6E6E6E",
                                            fontSize: "15px",
                                            marginTop: "-8px",
                                          }}
                                        >
                                          <p style={{ marginTop: "-8px" }}>
                                            {MONTH_DETAILS[
                                              parseInt(
                                                data.startDate.slice(5, 7),
                                                10
                                              )
                                            ] +
                                              " " +
                                              data.startDate.slice(0, 4)}{" "}
                                            -{" "}
                                            {MONTH_DETAILS[
                                              parseInt(
                                                data.endDate.slice(5, 7),
                                                10
                                              )
                                            ] +
                                              " " +
                                              data.endDate.slice(0, 4)}
                                          </p>
                                          <a
                                            target="_gray"
                                            href={data.link}
                                            style={{
                                              color: "#0885B7",
                                              display: "inline-block",
                                              maxWidth: "100%",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              textDecoration: "none",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            {data.link}
                                          </a>

                                          <p style={{ marginTop: "10px" }}>
                                            <SeeMoreLess
                                              text={data?.description}
                                              maxLength={350}
                                            />
                                          </p>
                                        </div>
                                      </div>{" "}
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          <hr class="dropdown-divider mb-5 mt-3" />
                        </>
                      ) : (
                        <></>
                      )} */}

                      {state2.resume.Skills.length != 0 ? (
                        <>
                          <div class="container">
                            <div class="row">
                              <div class="col-3" style={{ minWidth: "200px" }}>
                                <p
                                  style={{
                                    color: "#6D6D6D",
                                    fontSize: "13px",
                                    letterSpacing: "0.5px",
                                    fontWeight: "500",
                                  }}
                                >
                                  SKILLS
                                </p>
                              </div>
                              <div class="col-6">
                                <div className="row">
                                  {state2.resume.Skills.map((data) => {
                                    return (
                                      <>
                                        <div
                                          className="col-6"
                                          style={{ minWidth: "200px" }}
                                        >
                                          <div
                                            style={{
                                              marginBottom: "20px",
                                              fontSize: "17px",
                                            }}
                                          >
                                            <p
                                              style={{
                                                fontSize: "15px",
                                                color: "#404040",
                                              }}
                                            >
                                              <b>{data.name}</b>
                                            </p>
                                            <div
                                              style={{
                                                color: "black",
                                                marginTop: "-8px",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  color: "#6E6E6E",
                                                  fontSize: "15px",
                                                  marginTop: "-8px",
                                                }}
                                              >
                                                <p
                                                  style={{ marginTop: "10px" }}
                                                >
                                                  {data.level
                                                    ? data.level
                                                    : "Beginner"}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                              <div class="col-3"></div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {/* <hr class="dropdown-divider mb-5 mt-3" />

                      {state2.resume.PersonalDetails ? (
                        <>
                          <div class="container">
                            <div class="row">
                              <div class="col-3" style={{ minWidth: "150px" }}>
                                <p
                                  style={{
                                    color: "#6D6D6D",
                                    fontSize: "13px",
                                    letterSpacing: "0.5px",
                                    fontWeight: "500",
                                  }}
                                >
                                  CONTACT DETAILS
                                </p>
                              </div>
                              
                              <div class="col-md-9 col-12 d-flex justify-content-between align-items-start">
                                <div>
                                  <div
                                    style={{
                                      color: "#6E6E6E",
                                      fontSize: "15px",
                                      marginTop: "-8px",
                                    }}
                                  >
                                    <p style={{ marginTop: "10px" }}>
                                      <span
                                        style={{
                                          color: "#2C2C2C",
                                          fontWeight: "bold",
                                          color: "#404040",
                                        }}
                                      >
                                        Email:{" "}
                                      </span>
                                      {state2.resume.PersonalDetails.email}
                                    </p>
                                    <p style={{ marginTop: "10px" }}>
                                      <span
                                        style={{
                                          color: "#2C2C2C",
                                          fontWeight: "bold",
                                          color: "#404040",
                                        }}
                                      >
                                        Phone:{" "}
                                      </span>
                                      +91 {state2.resume.PersonalDetails.phone}
                                    </p>
                                  </div>
                                </div>
                                
                              </div>
                              
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )} */}

                      {/* <hr class="dropdown-divider mb-5 mt-3" />

                      <div className="container" style={{ padding: "25px" }}>
                        {state2?.resume?.PersonalDetails && (
                          <div>
                            <div
                              className=""
                              style={{
                                minWidth: "150px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <p
                                style={{
                                  color: "black",
                                  fontSize: "32px",
                                  fontWeight: "500",
                                  marginLeft: "-8px",
                                }}
                              >
                                Address
                              </p>
                            </div>
                            <div
                              className="mobile-column-to-increase-width"
                              style={{ marginLeft: "-24px" }}
                            >
                              <form>
                                <div className="form-group formgroupaddress">
                                  <div className="col-12 col-md-8 formgroupaddress2 ">
                                    <div class="form-group my-2">
                                      <label
                                        for="state"
                                        style={{ fontSize: "15px" }}
                                      >
                                        Address
                                      </label>
                                      <input
                                        style={{
                                          fontSize: "15px",
                                          paddingTop: "18px",
                                          paddingBottom: "18px",
                                        }}
                                        type="text"
                                        className="ModelInput form-control"
                                        id="address"
                                        name="address"
                                        value={
                                          state2?.resume?.AddressDetail?.address
                                        }
                                        readOnly
                                        placeholder="B-141/1, Street 18, Shyam Vihar Phase 1, Najafgarh"
                                      />
                                    </div>
                                  </div>

                                  <div className="col-12 col-md-4 formgroupaddress2">
                                    <div class="form-group my-2">
                                      <label
                                        for="state"
                                        style={{ fontSize: "15px" }}
                                      >
                                        Address 2 (Apartment, suite, etc)
                                      </label>
                                      <input
                                        style={{
                                          fontSize: "15px",
                                          paddingTop: "18px",
                                          paddingBottom: "18px",
                                        }}
                                        type="text"
                                        class="ModelInput form-control "
                                        id="address2"
                                        name="address2"
                                        placeholder="Apt/Suite"
                                        value={
                                          state2?.resume?.AddressDetail
                                            ?.address2
                                        }
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group formgroupaddress">
                                  <div className="col-12 col-md-8 formgroupaddress2">
                                    <div class="form-group my-2">
                                      <label
                                        for="state"
                                        style={{ fontSize: "15px" }}
                                      >
                                        City
                                      </label>
                                      <input
                                        style={{
                                          fontSize: "15px",
                                          paddingTop: "18px",
                                          paddingBottom: "18px",
                                        }}
                                        type="text"
                                        class="ModelInput form-control "
                                        id="address2"
                                        name="address2"
                                        placeholder="Apt/Suite"
                                        value={
                                          state2?.resume?.AddressDetail?.city
                                        }
                                        readOnly
                                      />
                                    </div>
                                  </div>

                                  <div className="col-12 col-md-4 formgroupaddress2">
                                    <div class="form-group my-2">
                                      <label
                                        for="state"
                                        style={{ fontSize: "15px" }}
                                      >
                                        State/Province
                                      </label>
                                      <input
                                        style={{
                                          fontSize: "15px",
                                          paddingTop: "18px",
                                          paddingBottom: "18px",
                                        }}
                                        type="text"
                                        class="ModelInput form-control "
                                        placeholder="DL"
                                        id="state"
                                        name="state"
                                        value={
                                          state2?.resume?.AddressDetail?.state
                                        }
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 col-md-6 formgroupaddress2">
                                  <div class="form-group my-2">
                                    <label
                                      for="code"
                                      style={{ fontSize: "15px" }}
                                    >
                                      ZIP/Postal Code
                                    </label>
                                    <input
                                      style={{
                                        fontSize: "15px",
                                        paddingTop: "18px",
                                        paddingBottom: "18px",
                                      }}
                                      type="text"
                                      class="ModelInput form-control "
                                      placeholder="110043"
                                      id="code"
                                      name="code"
                                      value={
                                        state2?.resume?.AddressDetail?.code
                                      }
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div className="col formgroupaddress2">
                                  <div class="form-group my-2">
                                    <label
                                      for="zip"
                                      style={{ fontSize: "15px" }}
                                    >
                                      Phone
                                    </label>
                                    <PhoneInput
                                      country={"in"}
                                      value={
                                        state2?.resume?.AddressDetail?.phone
                                      }
                                      disabled
                                      inputStyle={{
                                        fontSize: "15px",
                                        paddingTop: "18px",
                                        paddingBottom: "18px",
                                        width: "100%",
                                      }}
                                    />
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        )}
                      </div> */}

                      {/* <hr class="dropdown-divider mb-5 mt-3" />

                      <div className="container" style={{ padding: "25px" }}>
                        <div
                          className=""
                          style={{
                            minWidth: "150px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              color: "black",
                              fontSize: "32px",
                              fontWeight: "500",
                              marginLeft: "-8px",
                            }}
                          >
                            Identity
                          </p>
                        </div>
                        <div className="mobile-column-to-increase-width">
                          <div
                            style={{
                              height: "max-content",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "17px",
                                fontWeight: "500",
                                marginBottom: "10px",
                                textAlign: "center",
                                width: "100%",
                              }}
                            >
                              Front Photo
                            </p>
                            <div
                              style={{
                                textAlign: "center",
                                marginBottom: "20px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <img
                                src={
                                  state2?.resume?.IdentityDetails?.frontPhoto
                                    .url
                                }
                                alt="Front"
                                className="identityphotoclass"
                                style={{ height: "250px", cursor: "pointer" }}
                              />
                            </div>
                          </div>

                          <div style={{ height: "max-content" }}>
                            <p
                              style={{
                                fontSize: "17px",
                                fontWeight: "500",
                                marginBottom: "10px",
                                textAlign: "center",
                                width: "100%",
                              }}
                            >
                              Back Photo
                            </p>
                            <div
                              style={{
                                textAlign: "center",
                                marginBottom: "20px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <img
                                src={
                                  state2?.resume?.IdentityDetails?.backPhoto.url
                                }
                                alt="Back"
                                className="identityphotoclass"
                                style={{ height: "250px", cursor: "pointer" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div> */}

                      {/* <hr class="dropdown-divider mb-5 mt-3" />

                      <div className="container" style={{ padding: "25px" }}>
                        <div
                          className=""
                          style={{
                            minWidth: "150px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              color: "black",
                              fontSize: "32px",
                              fontWeight: "500",
                              marginLeft: "-8px",
                            }}
                          >
                            Billing Statement
                          </p>
                        </div>
                        <div className="mobile-column-to-increase-width">
                          <div style={{ height: "max-content" }}>
                            <p
                              style={{
                                fontSize: "17px",
                                fontWeight: "500",
                                marginBottom: "10px",
                              }}
                            >
                              Document
                            </p>
                            <div
                              style={{
                                textAlign: "center",
                                marginBottom: "20px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              
                              {state2?.resume?.DocDetails.map((doc, index) => (
                                <div
                                  key={index}
                                  style={{
                                    marginBottom: "10px",
                                    width: "auto",
                                    height: "max-content",
                                    padding: "20px",
                                    backgroundColor: "#f9f9f9",
                                    borderRadius: "10px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  <p style={{ marginBottom: "5px" }}>
                                    {doc.fileName}
                                  </p>
                                  <div style={{ display: "flex", gap: "10px" }}>
                                    <button
                                      onClick={() =>
                                        handleDownload2(
                                          doc.document,
                                          doc.fileName
                                        )
                                      }
                                      style={{
                                        color: "#0196C5",
                                        cursor: "pointer",
                                        background: "none",
                                        border: "none",
                                        padding: 0,
                                      }}
                                    >
                                      Download
                                    </button>
                                    <FaFileDownload
                                      style={{
                                        fontSize: "22px",
                                        color: "#0196C5",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleDownload2(
                                          doc.document,
                                          doc.fileName
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      maxWidth: "940px",
                      margin: "auto",
                    }}
                  >
                    <div>
                      {role &&
                        role !== "User" &&
                        state2.status !== "Hired" &&
                        state2.status !== "Shortlisted" && (
                          <>
                            <button
                              className=" shortcut-newappliedjob"
                              style={{ color: "#1177d6" }}
                              onClick={() => handleUpdateStatus("Shortlisted")}
                            >
                              <>
                                {state2.status !== "Hired" &&
                                  state2.status !== "Shortlisted" && (
                                    <>Shortlist</>
                                  )}
                              </>
                            </button>
                          </>
                        )}
                      {role && role !== "User" && state2.status !== "Hired" && (
                        <>
                          <button
                            className=" shortcut-newappliedjob"
                            style={{ color: "#1177d6" }}
                            onClick={() => handleUpdateStatus("Hired")}
                          >
                            <>{state2.status !== "Hired" && <>Hire</>}</>
                          </button>
                        </>
                      )}

                      {role && role !== "User" ? (
                        <button
                          className="shortcut-newappliedjob"
                          // onClick={() => handleChatClick(state2?.user?._id, state2?.employer, state2?.job?._id, state2?._id , navigate)}
                          onClick={() => setOpenPopup(!openPopup)}
                        >
                          Message
                        </button>
                      ) : null}
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <>
            <div style={{ height: "100vh" }}></div>
          </>
        )}
      </div>
      <SimpleDialogNew
        candidates={state2?.user?._id} //userid
        appliedJobId={state2?.job?._id} //jobid
        employerId={state2?.employer}
        mainId={state2?._id}
        open={openPopup}
        onClose={() => setOpenPopup(false)}
      />
      <ToastContainer position="top-center" autoClose={10000} />
    </>
  );
};

export default AppliedJobReview;
